<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        v-if="visible"
      >
      <a-form-model-item style="width:800px"
            label="驾校充值分成金额"
            prop="profitAmt"
            ref="profitAmt"
        >
        <a-input-number  :max="999999" :min="0"
            placeholder="请输入驾校充值分成金额" :precision="2"
            v-model="formData.profitAmt"/>
      </a-form-model-item>

       <a-form-model-item style="width:800px"
            label="学员充值分成金额" 
            prop="traineeRecProfitAmt"
            ref="traineeRecProfitAmt"
        >
        <a-input-number  :max="999999" :min="1"
            placeholder="请输入学员充值分成金额" :precision="2"
            v-model="formData.traineeRecProfitAmt"/>
      </a-form-model-item>

      <a-form-model-item style="width:800px"
            label="驾校分成比例"
            prop="schoolProfitRatio"
            ref="schoolProfitRatio"
        >
        <a-input-number  :max="100" :min="0" :precision="2"
            placeholder="请输入驾校分成比例"
            v-model="formData.schoolProfitRatio"/>%
      </a-form-model-item>

      <a-form-model-item style="width:800px"
            label="代理分成比例"
            prop="agentProfitRatio"
            ref="agentProfitRatio"
        >
        <a-input-number  :max="100" :min="0" :precision="2"
            placeholder="请输入代理分成比例"
            v-model="formData.agentProfitRatio"/>%
      </a-form-model-item>

      <a-form-model-item style="width:800px"
            label="公司分成比例"
            prop="companyProfitRatio"
            ref="companyProfitRatio"
        >
        <a-input-number  :max="100" :min="0" :precision="2"
            placeholder="请输入公司分成比例"
            v-model="formData.companyProfitRatio"/>%
      </a-form-model-item>

      <a-form-model-item style="width:800px"
            label="生效起始日期"
            prop="beginTime"
            ref="beginTime"
        >
        <a-date-picker v-model="formData.beginTime" 
        :disabled-date="disabledDate"
        valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
import moment from 'moment';
export default {
  components: {
  },
  data() {
    
    return {
      titleInfo: "录入分成信息",
      queryObj:{},
      visible: false,
      loading: false,
      flag:'',
      rules: {
        beginTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        profitAmt: [
          { required: true, message: "请输入驾校充值分成金额", trigger: "change" },
        ],
        traineeRecProfitAmt:[
          { required: true, message: "请输入学员充值分成金额", trigger: "change" },
        ],
         schoolProfitRatio: [
          { required: true, message: "请输入驾校分成比例", trigger: "change" },
        ],
         agentProfitRatio: [
          { required: true, message: "请输入代理分成比例", trigger: "change" },
        ],
        companyProfitRatio: [
        { required: true, message: "请输入公司分成比例", trigger: "change" },
        ],
      },
      formData: {
        beginTime: null,
        profitAmt:"",
        traineeRecProfitAmt:"",
        schoolProfitRatio:"",
        agentProfitRatio:"",
        companyProfitRatio:""
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v,isExistValid) {
      this.visible = true;
      this.flag=v;
      this.titleInfo="录入分成信息";
      this.resetForm();
    },
    disabledDate(current) {
        //没有配置，则从当天开始
        var end = moment().add('days', -1);
        return current<end;
        //return current && current <= moment().endOf('day');
    },
    resetForm() {
      this.formData = {
        beginTime: null,
        profitAmt:"",
        traineeRecProfitAmt:"",
        schoolProfitRatio:"",
        agentProfitRatio:"",
        companyProfitRatio:""
      };
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        //分成比例：公司如果为100，则公司充值金额为0，学院充值不为0
        if(this.formData.schoolProfitRatio =="100"){
          if(this.formData.agentProfitRatio!="0"
             ||this.formData.companyProfitRatio!="0"||this.formData.profitAmt!="0"){
            this.$message.error("驾校买断，驾校分成比例需为100，充值金额为0");
            return;
          }
        }
        //
        if (this.formData.beginTime == "") {// ||this.formData.agentProfitRatio==""||this.formData.companyProfitRatio==""
          this.$message.error("栏位存在空值，请检查");
          //当代理以及公司端为0，则表示驾校独家处理
          return;
        }
        if(this.formData.schoolProfitRatio
                +this.formData.agentProfitRatio
                +this.formData.companyProfitRatio!=100){
            this.$message.error("分成比例合计不为1，请检查");
            return;
        }
       
        if (valid) {
          //console.log(this.formData.schoolProfitRatio);
          //保存分成信息
          ApiConfig.saveProfit({
              driverSchoolNo: this.queryObj.driverSchoolNo,
              profitAmt: this.formData.profitAmt,
              traineeRecProfitAmt: this.formData.traineeRecProfitAmt,
              schoolProfitRatio:this.formData.schoolProfitRatio,
              agentProfitRatio:this.formData.agentProfitRatio,
              companyProfitRatio:this.formData.companyProfitRatio,
              beginTime: this.formData.beginTime,
            })
              .then((res) => {
                if (res.data.code == 200) {
                  this.visible = false;
                  this.$message.success(res.data.msg);
                  this.$emit("backInit");
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((error) => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    

    handleCancel(e) {
      this.visible = false;
    },
  },
  created() {
      this.queryObj=this.$route.query;
  },
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>