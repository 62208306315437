<template>
  <a-config-provider :locale="locale">
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive">
        <router-view></router-view>
    </keep-alive>
    
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
// import moment from 'moment'
// import 'moment/locale/zh-cn'
// moment.locale('zh-cn')
export default {
  name: 'App',
  data(){
    return {
        locale: zhCN
    }
  },
  components: {
 
  }
}
</script>

<style>
*{
   padding: 0px;
  margin: 0px;
}
body,html{
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow: hidden;
 
   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,li{
  list-style: none;
}

#app {
  width: 100%;
  overflow: hidden;
   color: #000;
   font-size: 14px;
}
.paginationBox {
  margin-top: 20px;
  text-align: center;
}
.clear {
  width: 100%;
  overflow: hidden;
}
.paddingCon {
  padding:20px
}
.marginTop20{
  margin-top: 20px;
}


</style>
