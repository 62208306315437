<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
   
      <a-form-model-item label="驾校名称" prop="driverSchoolName" ref="driverSchoolName">
          <a-input
            :maxLength="50"
            placeholder="请输入驾校名称"
            v-model="formData.driverSchoolName"
          />
        </a-form-model-item>

      <a-form-model-item label="所在城市" prop="cityLinkInfo" ref="cityLinkInfo">
          <a-cascader
            :options="optionsCityList"
            :field-names="{
              label: 'name',
              value: 'code',
              children: 'children',
            }"
            v-model="formData.cityLinkInfo"
            placeholder="请选择所在城市"
            @change="handleSelectChange"
          />
        </a-form-model-item>

      <a-form-model-item label="详细地址" prop="addr" ref="addr">
          <a-input
            :maxLength="50"
            placeholder="请输入详细地址"
            v-model="formData.addr"
          />
        </a-form-model-item>
  
        <a-form-model-item label="所属代理" prop="agentNo" ref="agentNo">
          <a-select 
          v-model="formData.agentNo" placeholder="请选择代理">
            <a-select-option
              :value="item.agentNo"
              v-for="(item, index) in optionsAgentList"
              :key="index"
              >{{ item.agentName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="纬度" prop="latitude" ref="latitude">
          <a-input-number
            :precision="6" :maxLength="15"
            placeholder="请输入纬度"
            v-model="formData.latitude" style="width:200px"
          />
        </a-form-model-item>
        <a-form-model-item label="经度" prop="longitude" ref="longitude">
          <a-input-number
            :precision="6"
            placeholder="请输入经度" :maxLength="15"
            v-model="formData.longitude" style="width:200px" @blur="setTudeValue"
          />
        </a-form-model-item>
       
       <a-form-model-item label="经纬度参考">
          获取相关经纬度<a href="https://lbs.qq.com/getPoint/" target="_blank">腾讯拾取坐标</a>
        </a-form-model-item>
        <a-form-model-item label="注意">
          联系管理员，添加设备信息
        </a-form-model-item>
    </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";

export default {
  components: {
  },
  data() {
    
    return {
      titleInfo: "",
      visible: false,
      loading: false,
      optionsCityList:[],
      optionsAgentList:[],
      driverSchoolNo:'',
      flag:'',
      rules: {
        driverSchoolName: [
          { required: true, message: "请输入驾校名称", trigger: "change" },
        ],
        cityLinkInfo: [
          { required: true, message: "请选择城市等信息", trigger: "change" },
        ],
        addr: [
          { required: true, message: "请输入地址信息", trigger: "change" },
        ],
        longitude: [
          { required: true, message: "请输入经度", trigger: "change" },
        ],
        latitude: [
          { required: true, message: "请输入纬度", trigger: "change" },
        ],
      },
      formData: {
        driverSchoolName: "",
        addr:"",
        longitude:"",
        latitude:""
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v,obj) {
      this.resetForm();
      this.visible = true;
      this.flag=v;
      this.titleInfo="编辑驾校信息";
      if(v=="add"){
          this.titleInfo="新增驾校信息";
          this.driverSchoolNo="";
      };
      this.getProvinceAndCityList();//三级对应的省份城市等
      this.getAgentList();//代理列表
      if(v!="add"){
          this.driverSchoolNo=obj.driverSchoolNo;
          this.getSchoolDetail(obj);
      }
    },
    getProvinceAndCityList(){
        ApiConfig.getProvinceAndCityList({})
        .then((res) => {
          if (res.data.code == 200) {
            this.optionsCityList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getAgentList(){
        ApiConfig.getAgentList({})
        .then((res) => {
          if (res.data.code == 200) {
            this.optionsAgentList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getSchoolDetail(obj){
        ApiConfig.getSchoolDetail({
            driverSchoolNo:obj.driverSchoolNo
        })
        .then((res) => {
          if (res.data.code == 200) {
            
            var objSchoolInfo = res.data.data;
            this.formData = {
              driverSchoolName: objSchoolInfo.driverSchoolName,
              addr: objSchoolInfo.addr,
              cityLinkInfo:[objSchoolInfo.proNo,
                  objSchoolInfo.cityNo,
                  objSchoolInfo.districtNo
                  ],
              agentNo:objSchoolInfo.agentNo,
              longitude:objSchoolInfo.longitude,
              latitude:objSchoolInfo.latitude
            };
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    resetForm() {
      this.formData = {
        driverSchoolName: "",
        addr:"",
        longitude:"",
        latitude:"" 
      };
    },
 
    handleSelectChange(value) {
      console.log(value);
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (this.formData.driverSchoolName == "") {
          return;
        }
        if(this.formData.schoolProfitAmt<=0&&this.formData.companyProfitAmt<=0
        &&this.formData.agentProfitAmt<=0){
            this.$message.error("分成信息不能全部为0");
            return;
        }
       
        if (valid) {
           //保存
           if(this.flag=="add"){
              var info={
                driverSchoolNo:"",
                driverSchoolName: this.formData.driverSchoolName,
                proNo: this.formData.cityLinkInfo[0],
                cityNo: this.formData.cityLinkInfo[1],
                districtNo: this.formData.cityLinkInfo[2],
                addr: this.formData.addr,
                agentNo: this.formData.agentNo,
                longitude:this.formData.longitude,
                latitude:this.formData.latitude 
              };
              ApiConfig.driverSchoolSave({
                driverSchoolDto:JSON.stringify(info)
              })
                .then((res) => {
                  if (res.data.code == 200) {
                    this.visible = false;
                    this.$emit("backInit");
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch((error) => {
                  this.$message.error(this.CommonConst.errorMsg);
                });
           }else if(this.flag=="edit"){
             var info={
                driverSchoolNo:this.driverSchoolNo,
                driverSchoolName: this.formData.driverSchoolName,
                proNo: this.formData.cityLinkInfo[0],
                cityNo: this.formData.cityLinkInfo[1],
                districtNo: this.formData.cityLinkInfo[2],
                addr: this.formData.addr,
                agentNo: this.formData.agentNo,
                longitude:this.formData.longitude,
                latitude:this.formData.latitude 
              };
             ApiConfig.driverSchoolSave({
                driverSchoolDto:JSON.stringify(info) 
              })
                .then((res) => {
                  if (res.data.code == 200) {
                    this.visible = false;
                    this.$emit("backInit");
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch((error) => {
                  this.$message.error(this.CommonConst.errorMsg);
                });
           }
           
           
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    setTudeValue(){
      if(this.formData.longitude.indexOf(",")>0){
        this.formData.longitude=this.formData.longitude.split(",")[0];
        this.formData.latitude=this.formData.longitude.split(",")[1];
      }
    },

    handleCancel(e) {
      this.visible = false;
    },
  },
  created() {},
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>