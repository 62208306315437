<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
          ref="ruleForm"
          :model="formData"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          v-if="visible"
        >
          <a-form-model-item label="姓名" prop="name" ref="name">
            <a-input  v-model="formData.name" :maxLength="20"/>
          </a-form-model-item>
          <a-form-model-item ref="account" label="账号" prop="account">
            <a-input
              style="width: 100%"
              disabled
              v-model="formData.account"
            />
          </a-form-model-item>

          <a-form-model-item
            ref="roleArray"
            label="权限"
            prop="roleArray"
            class="roleCheckouttype"
          >
            <a-checkbox-group v-model="formData.roleArray">
              <a-checkbox
                :value="item.roleKey"
                name="type"
                v-for="(item, index) in ruleArr"
                :key="index"
              >
                {{ item.roleDesc }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password" ref="password">
            <a-input-password
            v-model="formData.password" :maxLength="10"/>
          </a-form-model-item>
          <a-form-model-item label="确认密码" prop="password2" ref="password2">
            <a-input-password 
            v-model="formData.password2" :maxLength="10"/>
          </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
 
 
export default {
  name: "staffmanagement",
  data() {
    return {
      visible: false,
      loading: false,
      searchData: {
        key: "",
      },
      flag: "",//新增或修改
      titleInfo:"",
      formData: {
        name: "",
        account: "",
        password:"",
        password2:"",//确认密码
        roleArray: [],
      },
      ruleArr: [],
      rules: {
        name: [
          { required: true, message: "请输入员工姓名", trigger: "blur" },
        ],
        roleArray: [
          {
            type: "array",
            required: true,
            message: "请选择权限",
            trigger: "change",
          },
        ],
      },
    };
  },
  components: {},
  methods: {
     init(v,obj) {
      this.resetForm();
      this.visible = true;
      this.flag=v;
      this.titleInfo="员工管理";
      this.getRoleList();
      if(v!="add"){
         this.getUserDetail(obj); 
      }
    },
    resetForm() {
      this.formData.name = "";
      this.formData.account = "";
      this.formData.password2="";
      this.formData.password="";
      this.formData.roleArray = [];
    },
    getRoleList(){
      //获取公司对应角色列表
      ApiConfig.companyStaffRoleList({
          roleType: Helper.get("loginInfo").userType
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.ruleArr = res.data.data;
            //this.formData.roleArray = [];
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getUserDetail(record){
        ApiConfig.userdetail({
          account: record.account,
        }).then((res) => {
          if (res.data.code == 200) {
            var roleArray = [];
            var arr = res.data.data.roles;
            if (arr != null) {
              for (let i = 0; i < arr.length; i++) {
                roleArray.push(arr[i].roleKey);
              }
            }
            this.formData.name = res.data.data.name;
            this.formData.account = res.data.data.account;
            this.formData.roleArray = roleArray;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.formData.password!=""){
            if(this.formData.password!=this.formData.password2){
                this.$message.error("密码不一致");
                return;
            }
          }
          if (this.flag == "add") {
            if(this.formData.password==""){
                this.$message.error("密码不能为空");
                return;
            }
            ApiConfig.companyStaffSaveByRole({
              name: this.formData.name,
              account: this.formData.account,
              roleArray: this.formData.roleArray,
              password:this.formData.password,
              userType:'2' //公司员工
            })
              .then((res) => {
                if (res.data.code == 200) {
                  this.visible = false;
                  this.$message.success(res.data.msg);
                  this.$emit("backInit");
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((error) => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }
          //调用同一个方法，后端根据账号以及密码栏位去判断
          if (this.flag == "edit") {
            let curAccount=this.formData.account;
            ApiConfig.companyStaffSaveByRole({
              name: this.formData.name,
              account: this.formData.account,
              roleArray: this.formData.roleArray,
              password:this.formData.password,
              userType:'2' //公司员工
            })
              .then((res) => {
                if (res.data.code == 200) {
                  this.visible = false;
                  this.$message.success(res.data.msg);    
                  //判断
                  if(Helper.get("loginInfo")!=null){
                    if(curAccount==Helper.get("loginInfo").account){
                      this.$message.success("你的信息已修改，将重新登录");
                      //跳转到登录页面
                      this.$router.push({
                        path: "/login",
                        query: {
                        },
                      });
                    }else{
                      this.$emit("backInit");
                    }
                  }else{
                    this.$emit("backInit");
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((error) => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  mounted() {
    //this.getList();
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.teachermanagement {
  .view {
    float: left;
    color: #0e9a68;
    cursor: pointer;
    padding: 3px;
  }

  .pagbox {
    text-align: center;
    margin-top: 140px;
  }
  .searchHeader {
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 9px;

    .header-1,
    .header-2,
    .header-3 {
      float: left;
    }

    .header-1 {
      // width: 240px;
      height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      float: left;

      p {
        // width: 40px;
        height: 32px;
        float: left;
        box-sizing: border-box;
        text-align: center;
        line-height: 32px;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        padding-right: 5px;
      }
      p {
        float: left;
        line-height: 32px;
      }

      .ant-input {
        width: 198px;
        height: 32px;
        float: left;
        padding-left: 7px;
        margin-left: 5px;
      }
      .ant-input-affix-wrapper {
        width: 198px;
        height: 32px;
        float: left;
        // padding-left:7px;
      }

      .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-left: 0px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
    }

    .header-2 {
      width: 240px;
      height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      float: left;
      p {
        float: left;
        text-align: center;
        line-height: 32px;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        margin-right: 7px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
      margin-left: 15px;
    }

    .header-3 {
      float: left;
      button {
        width: 60px;
        height: 31px;
        text-align: center;
        line-height: 31px;
        border-radius: 2px;
        // font-size:14px;
        outline: none;
        cursor: pointer;
        border: none;
      }

      .fgh {
        margin-left: 8px;
        margin-right: 8px;
      }
      .hgf {
        border: 1px solid #ccc;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    .apply {
      font-size: 14px;
      padding: 0px 10px;
      float: right;
    }
  }

  .management-content {
    .big-box {
      overflow: hidden;
      .small-box1 {
        width: 100%;
        height: 56px;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        border-bottom: 1px solid #e8e8e8;
        li {
          list-style: none;
          float: left;
          line-height: 56px;
        }

        li:nth-child(1) {
          width: 5%;
          height: 55px;
          text-align: center;
        }
        li:nth-child(2) {
          width: 50%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
        li:nth-child(3) {
          width: 25%;
          height: 55px;
          text-align: center;
          text-indent: 10px;
        }
        li:nth-child(4) {
          width: 20%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
      }

      .small-box2 {
        width: 100%;
        height: 56px;
        margin: 0;
        padding: 0;
        //  background-color:rgba($color: #c968d6, $alpha:0.05);
        li {
          list-style: none;
          float: left;
          line-height: 56px;
          border-bottom: 1px solid #e8e8e8;
          a {
            float: left;
            color: #0e9a68;
          }
          p {
            float: left;
            color: #0e9a68;
          }
        }

        li:nth-child(1) {
          width: 5%;
          height: 55px;
          text-align: center;
        }
        li:nth-child(2) {
          width: 50%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
        li:nth-child(3) {
          width: 25%;
          height: 55px;
          text-align: center;
          text-indent: 10px;
        }
        li:nth-child(4) {
          width: 20%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
      }
    }
  }
}
</style>