import Vue from 'vue'
import Antd from 'ant-design-vue';
import { ConfigProvider } from 'ant-design-vue'
import App from './App';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'ant-design-vue/dist/antd.less';
import Helper from '@/utils/helper'
import 'swiper/dist/css/swiper.css'
import './assets/style.less'
import md5 from 'js-md5';
//全局引用放大图片的控件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 
  'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 
  'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true,
  'keyboard': true, 'url': 'data-source' 
  }
})
/////////
Vue.use(Antd)
Vue.use(ConfigProvider)
Vue.prototype.$md5 = md5;
import router from './router'
Vue.config.productionTip = false
import { CustomFilter } from '@/utils/CustomFilter'
import CommonConst from '@/utils/CommonConst.js'
Vue.prototype.CommonConst = CommonConst;
Vue.use(VueAwesomeSwiper, /* { default global options } */ )
    // Object.keys(CustomFilter).forEach(key => Vue.filter(key, CustomFilter[key])) //过滤器循环
const whiteList = ['/login', '/sendpwd']; // 不重定向白名单
router.beforeEach((to, from, next) => {
    // document.title = to.meta.title;
    let userFlage = Object.keys(Helper.get("loginInfo")).length == 0 ? false : true;
    // console.log("userFlage=================", userFlage)
    if (to.matched.some(record => record.meta.requireAuth)) { //路由字段标示需要登录
        // console.log("1111111111")
        if (!userFlage) { //用户信息不存在
            if (whiteList.indexOf(to.path) !== -1) {
                // console.log("22222222222")
                next()
            } else {
                // console.log("33333333333")
                next({ path: "/login", query: { redirect: to.fullPath } })
            }
        } else { //用户信息存在
            // console.log("444444444444")
            next()
        }
    } else { //路由字段标示不需要登录
        // console.log("55555555555")
        if (!userFlage) { //用户信息不存在
            // console.log("6666666666666")
            next()
        } else { //用户信息存在
            // console.log("7777777777777")
            next()
                // next({ path: "/" })
        }
    }
});
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')