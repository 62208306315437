<template>
  <div class="libraryDetail paddingCon">
    <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
    <a-row :gutter="24">
        <a-col :span="15">
          <a-form-model-item
            ref="fengmiantu"
            label="封面图片"
            class="fengmian has-error"
          >
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :custom-request="function () {}"
              :before-upload="beforeUpload"
              @change="handleUploadChange"
            >
              <img
                v-if="formData.img"
                :src="configUrl + formData.img"
                alt="avatar"
                style="width: 104px; height: 104px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <div class="tips">支持png、jpg、jpeg格式的图片，大小3M以内</div>
            <div class="ant-form-explain" v-if="isShowImgTips">
              封面图片不得为空
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      
      <a-row :gutter="24">
        <a-col :span="15">
          <a-form-model-item
            ref="workTime"
            label="驾校工作时间"
            prop="workTime"
          >
            <a-input v-model="formData.workTime" :maxLength="20" />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="15">
          <a-form-model-item
            ref="contactPhone"
            label="驾校电话"
            prop="contactPhone"
          >
            <a-input v-model="formData.contactPhone" :maxLength="32" />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="15">
          <a-form-model-item
            ref="deviceCount"
            label="VR设备数量"
            prop="deviceCount"
          >
            <a-input-number v-model="formData.deviceCount" :maxLength="5" :min="0"/>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row :gutter="24"> 
        <a-col :span="15">
          <a-form-model-item
            ref="shiyanjietu"
            label="简介图片"
            class="fengmian has-error"
          >
            <a-upload
              :custom-request="function () {}"
              list-type="picture-card"
              :file-list="formData.descImgUrls"
              :before-upload="beforeUpload"
              @change="handleChangeList"
            >
              <div v-if="formData.descImgUrls.length < 8">
                <a-icon :type="loadListing ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <div class="tips">支持png、jpg、jpeg格式的图片，大小3M以内</div>
            <div class="ant-form-explain" v-if="isShowListImgTips">
              实验截图不得为空
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>

 

       <a-row :gutter="24">
        <a-col :span="15">
          <a-form-model-item
            ref="averageSpend"
            label="平均消费"
            prop="averageSpend"
          >
            <a-input
              style="width: 100%"
              maxLength="5"
              v-model="formData.averageSpend"
            />
          </a-form-model-item>
        </a-col>    
      </a-row>

      <a-row :gutter="24">
        <a-col :span="15">
          <a-form-model-item
            ref="concurrencyNum"
            label="简介文字"
            prop="concurrencyNum"
          >
            <a-input
              style="width: 100%"
              maxLength="250"
              v-model="formData.introduce"
            />
          </a-form-model-item>
        </a-col>    
      </a-row>
  
    </a-form-model>

    <div class="footerCon">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk"> 确定 </a-button>
    </div>

    <Croppermodal
      @ok="handleBackOK"
      :autoCropWidth="600"
      :autoCropHeight="600"
      ref="cropperModal"
    />
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js";
import Croppermodal from "@/components/cropper-modal";
import config from "@/config";
import Helper from "@/utils/helper";
//import "../../../static/UE/lang/zh-cn/zh-cn";
export default {
  name: "schoolDetail",
  data() {
    return {
      flag: "",
      isEdit: false,
      tmpImgName: "",
      configBaseUrl: config.serverURI,//
      configUrl: config.imgURL,//图片IP
      isShowImgTips: false,
      isShowListImgTips: false,
      tempList: [],
      formData: {
        img: "",//封面图片
        workTime: "",
        contactPhone: "",
        deviceCount: "",
        introduce: "",
        coverImgUrl: "",
        descImgUrls: [],//简介图片
      },
      loading: false,
      loadListing: false,
      rules: {
        workTime: [
          { required: true, message: "请输入上下班时间", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入驾校电话", trigger: "blur" },
        ],
        deviceCount: [
          { required: true, message: "请输入设备数量", trigger: "blur" },
        ],
        averageSpend:[
          { required: true, message: "请输入人均消费", trigger: "blur" },
        ]
      },
    };
  },
  components: {
    Croppermodal,
  },
  mounted() {},
  created() {
    this.queryObj = this.$route.query;
    this.flag = this.queryObj.flag;
    if (this.flag == "edit") {
      this.getDetail();
    }
    // this.$nextTick(() => {
    //   this.setUeditor();
    // });
  },
  destroyed() {
    // this.experDescEditor.destroy();
    // this.experPricipleEditor.destroy();
    // this.experRequesteEditor.destroy();
    // this.experFAQeEditor.destroy();
    // this.experSequenceeEditor.destroy();
  },
  methods: {
    getDetail() {
      ApiConfig.driverSchoolInfo({ driverSchoolNo: this.queryObj.driverSchoolNo })
        .then((res) => {
          if (res.data.code == 200) {
            var obj = res.data.data;
            console.log(obj.imgs.length);
            // this.formData = res.data.data
            var objImg = {};
            var list = [];
            //this.formData.imgUrls.push(obj.imgs);//多张简介图片
            if( obj.imgs!=null){
                for (let i = 0; i < obj.imgs.length; i++) {
                    var objImg = {
                        uid: obj.imgs[i].imgUrl+"-"+i,
                        name: obj.imgs[i].imgUrl,
                        status: "done",
                        url: this.configUrl + obj.imgs[i].imgUrl,
                        imgUrl: obj.imgs[i].imgUrl,
                    };
                    list.push(objImg);
                }
            }

            this.formData = {
              workTime: obj.workTime,
              deviceCount: obj.deviceCount,
              contactPhone: obj.contactPhone,
              introduce: obj.introduce,
              averageSpend: obj.averageSpend,
              img: obj.img,
              descImgUrls: list,
            };
             
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    handleBackOK(data, type) {
      console.log("type==========", type);
      //上传封面图片
      if (type == "A") {
        this.loading = true;
        let file = this.dataURLtoFile(data, this.tmpImgName);
        // ....省略api请求 2种格式的对象都拿到了 怎么请求不用说了吧
        //驾校封面图片
        ApiConfig.driverSchoolUploadCoverImg({
          keyNo: "",
          base64Str: data,
        })
          .then((res) => {
            if (res.data.code == 200) {
              this.isShowImgTips = false;
              this.formData.img = res.data.data[0];
            } else {
              this.$message.error(res.data.msg);
            }
            this.loading = false;
          })
          .catch((error) => {
            this.$message.error(this.CommonConst.errorMsg);
          });
      }
      //上传简介图片
      if (type == "B") {
        this.loadListing = true;
        let file = this.dataURLtoFile(data, this.tmpImgName);
        // ....省略api请求 2种格式的对象都拿到了 怎么请求不用说了吧
        //简介图片
        ApiConfig.driverSchoolUploadDescImg({
          keyNo: "",
          base64Str: data,
        })
          .then((res) => {
            if (res.data.code == 200) {
              this.isShowListImgTips = false;
              var obj = {
                uid: res.data.data[0],
                name: res.data.data[0],
                status: "done",
                url: this.configUrl + res.data.data[0],
                imgUrl: res.data.data[0],
              };
              this.formData.descImgUrls.push(obj);
            //   console.log(
            //     "this.formData.descImgUrls====",
            //     this.formData.descImgUrls
            //   );
            } else {
              this.$message.error(res.data.msg);
            }
            this.loadListing = false;
          })
          .catch((error) => {
            this.$message.error(this.CommonConst.errorMsg);
          });
      }
    },
    // 将base64转换为文件 百度随便找的 看需求使用
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    handleUploadChange(info) {
      // 这个回调函数的参数是个File对象,所以要用FileReader将对象转换成 data-uri (base64) 对象,才能给vue-cropper
      var reader = new FileReader();
      const _this = this;
      // 当文件处理完成的回调函数
      reader.onload = function (e) {
        // e.target.result 该属性表示目标对象的DataURL
        // 然后调用cropperModal的edit方法使得对话框可见
        _this.$refs["cropperModal"].edit(e.target.result, "A");
      };
      // 记录原始的文件名字,用于DataURL(base64)转成file对象,不转就可以不用
      this.tmpImgName = info.file.name;
      // 文件处理 file=>base64
      reader.readAsDataURL(info.file.originFileObj);
    },
    handleChangeList(info) {
      // 这个回调函数的参数是个File对象,所以要用FileReader将对象转换成 data-uri (base64) 对象,才能给vue-cropper
      var reader = new FileReader();
      const _this = this;
      // 当文件处理完成的回调函数
      reader.onload = function (e) {
        // e.target.result 该属性表示目标对象的DataURL
        // 然后调用cropperModal的edit方法使得对话框可见
        _this.$refs["cropperModal"].edit(e.target.result, "B");
      };
      // 记录原始的文件名字,用于DataURL(base64)转成file对象,不转就可以不用
      this.tmpImgName = info.file.name;
      // 文件处理 file=>base64
      //reader.readAsDataURL(info.file.originFileObj);
      //针对删除弹出错误的验证
      if(info.file.originFileObj!=null&&info.file.originFileObj!=undefined){
        reader.readAsDataURL(info.file.originFileObj);
      }else{
          for(var i=0;i<this.formData.descImgUrls.length;i++){
            if (this.formData.descImgUrls[i].uid == info.file.uid){
                this.formData.descImgUrls.splice(i,1)
    　　    } 
          }
      }
    },

    beforeUpload(file) {
      const sizeOk = file.size < 1024 * 3000;
      const typeOk = file.type === "image/jpeg" || file.type === "image/png";
      return new Promise((resolve, reject) => {
        if (!typeOk) {
          this.$message.error("照片格式有误！");
          reject(file);
        } else if (!sizeOk) {
          this.$message.error("照片大小超过3M！");
          reject(file);
        } else {
          resolve(file);
        }
      });
    },
    //保存
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (this.formData.img == "") {
          this.isShowImgTips = true;
        }
        if (this.formData.descImgUrls==null||
            this.formData.descImgUrls.length == 0) {
          this.isShowListImgTips = true;
        }

        
        if (valid && !this.isShowImgTips && !this.isShowListImgTips) {
          var list = [];
          for (let i = 0; i < this.formData.descImgUrls.length; i++) {
            list.push(this.formData.descImgUrls[i].imgUrl);
          }
          //this.formData.descImgUrls = list.join(",");
          // delete this.formData.experShotTempImgUrl;
          //console.log("成功===", this.formData);

          if (this.flag == "add") {
            var info={ ...this.formData,
              listImgs:list
            };
            ApiConfig.driverSchoolInfoSave({
                driverSchoolDto:JSON.stringify(info)
            })
              .then((res) => {
                if (res.data.code == 200) {
                  this.$router.push({
                    path: "/driverschool/info",
                    query: {},
                  });
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((error) => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }

          if (this.flag == "edit") {
            this.formData.driverSchoolNo = this.queryObj.driverSchoolNo;
             var info={ ...this.formData,
              listImgs:list
            };
            ApiConfig.driverSchoolInfoSave({
               driverSchoolDto:JSON.stringify(info)
            })
              .then((res) => {
                if (res.data.code == 200) {
                  this.$router.push({
                    path: "/driverschool/info",
                    query: {},
                  });
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((error) => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel() {
      this.$router.push({
        path: "/driverschool/info",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.footerCon {
  margin: 20px auto;
  width: 100%;
  text-align: center;
  button {
    padding: 0px 30px;
    margin: 0px 10px;
  }
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.tips {
  color: #ccc;
  line-height: 20px;
}
</style>

