<template>
<div class="home">
  <div class="homeCon">
    <img src="../../assets/images/img1.png" alt="">
    <div class="right">
      <p>
欢迎使用<br>
虚拟驾校管理平台
</p>
    </div>
  </div>
</div>
</template>
<script>
export default {
    name:"home",
  data() {
    return {
      
    };
  },
  mounted(){
   
  },
  methods:{
    
  }
};
</script>

<style lang='scss' scoped>
    .home {
      width: 100%;
      height: 100%;
      background: #499F58;
      overflow: hidden;
      .homeCon {
        width: 60%;
        margin: 10% auto;
        img {
          display: block;
          float: left;
          width: 300px;
        }
        .right {
          float: left;
          margin-left: 20px;
          p{
            margin-top: 100px;
            font-size: 29px;
            color: #fff;
          }
        }
      }
    }
</style>





