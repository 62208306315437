<template>
  <div class="homeworkcorrecting paddingCon">
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="devicesBookTodayList"
        :pagination="false"
        class="table-thead-ma"
        :rowKey="(record, index) => index + 1"
      >
        <template slot="deviceStatus" slot-scope="text, record">
          <div class="viewTwo" v-if="record.deviceStatus == '0'" style="color:red">
            已停用
          </div>
          <div class="viewTwo" v-if="record.deviceStatus == '1'" style="color:blue">
            已启用
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="showmodal(record)">查看</div>
          <div class="view" @click="showChangeModal(record)">修改设备</div>
        </template>
      </a-table>
    </div>

    <!-- 查看单个设备当天预约情况 -->
    <!-- ref类似class命名,需要有 -->
    <ViewTodayBook ref="ref_view" name="viewTodayBook"> </ViewTodayBook>

    <!--更改设备-->
    <changedevice ref="ref_view_changedevice" name="changedevice" @backInit="backInit"> </changedevice>
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import ViewTodayBook from "./ViewTodayBook.vue";
import changedevice from './changedevice.vue';

export default {
  name: "devicemanagement",
  data() {
    return {
      visible: false,
      devicesBookTodayList: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 100
        },
        {
          title: "设备编号",
          dataIndex: "deviceNo",
          key: "deviceNo",
          width: 100
        },
        {
          title: "设备名称",
          dataIndex: "deviceName",
          key: "deviceName",
          width: 100
        },
        {
          title: "设备状态",
          dataIndex: "deviceStatus",
          key: "deviceStatus",
          scopedSlots: { customRender: "deviceStatus" },
          width: 60
        },
        {
          title: "当前用户手机号",
          dataIndex: "bookTraineeMobile",
          key: "bookTraineeMobile",
          width: 100
        },
        {
          title: "当前用户",
          dataIndex: "bookTraineeName",
          key: "bookTraineeName",
          width: 100
        },
        {
          title: "当前用户练习时间",
          dataIndex: "trainTimeZone",
          key: "trainTimeZone",
          width: 100
        },
        {
          title: "下一位用户手机号",
          dataIndex: "nextBookTraineeMobile",
          key: "nextBookTraineeMobile",
          width: 100
        },
        {
          title: "下一位用户",
          dataIndex: "nextBookTraineeName",
          key: "nextBookTraineeName",
          width: 100
        },
        {
          title: "下一位用户练习时间",
          dataIndex: "nextBookTimeZone",
          key: "nextBookTimeZone",
          width: 100
        },
        {
          title: "操作",
          dataIndex: "see",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 120
        }
      ],
      tistics: []
    };
  },
  components: {
    ViewTodayBook,
    changedevice
  },
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    oneDeviceBookTodayClick(record) {
      this.$router.push({
        path: "/device/listtodaybook",
        query: {
          deviceNo: record.deviceNo,
          driverSchoolNo: record.driverSchoolNo
        }
      });
    },
    reset() {
      this.getList();
    },
    backInit(){
      this.getList();
    },
    // search() {
    //   this.pageObject.pageNum = 1;
    //   this.getList();
    // },

    handleOk(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log("成功===", this.form);
          ApiConfig.experimentAdd({
            experNo: this.formData.experNo,
            teaNo: Helper.get("loginInfo").account
          })
            .then(res => {
              if (res.data.code == 200) {
                this.visible = false;
                this.search();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(error => {
              this.$message.error(this.CommonConst.errorMsg);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(item1) {
      this.$refs["ref_view"].init(item1); // this.$refs触发一个组件里的方法
    },
    showChangeModal(item1) {
      this.$refs["ref_view_changedevice"].init(item1); // this.$refs触发一个组件里的方法
    },
    getList() {
      ApiConfig.driverSchoolDeviceTodayUseList({
        driverSchoolNo: Helper.get("loginInfo").belongNo
      })
        .then(res => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.devicesBookTodayList = list;
            //this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.homeworkcorrecting {
  .view {
    float: left;
    color: #0e9a68;
    cursor: pointer;
    padding: 3px;
  }

  .pagbox {
    text-align: center;
    margin-top: 140px;
  }
  .searchHeader {
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 9px;

    .header-1,
    .header-2,
    .header-3 {
      float: left;
    }

    .header-1 {
      // width: 240px;
      height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      float: left;

      p {
        // width: 40px;
        height: 32px;
        float: left;
        box-sizing: border-box;
        text-align: center;
        line-height: 32px;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        padding-right: 5px;
      }
      p {
        float: left;
        line-height: 32px;
      }

      .ant-input {
        width: 198px;
        height: 32px;
        float: left;
        padding-left: 7px;
        margin-left: 5px;
      }
      .ant-input-affix-wrapper {
        width: 198px;
        height: 32px;
        float: left;
        // padding-left:7px;
      }

      .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-left: 0px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
    }

    .header-2 {
      width: 240px;
      height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      float: left;
      p {
        float: left;
        text-align: center;
        line-height: 32px;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        margin-right: 7px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
      margin-left: 15px;
    }

    .header-3 {
      float: left;
      button {
        width: 60px;
        height: 31px;
        text-align: center;
        line-height: 31px;
        border-radius: 2px;
        // font-size:14px;
        outline: none;
        cursor: pointer;
        border: none;
      }

      .fgh {
        margin-left: 8px;
        margin-right: 8px;
      }
      .hgf {
        border: 1px solid #ccc;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    .apply {
      font-size: 14px;
      padding: 0px 10px;
      float: right;
    }
  }

  .management-content {
    .big-box {
      overflow: hidden;
      .small-box1 {
        width: 100%;
        height: 56px;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        border-bottom: 1px solid #e8e8e8;
        li {
          list-style: none;
          float: left;
          line-height: 56px;
        }

        li:nth-child(1) {
          width: 5%;
          height: 55px;
          text-align: center;
        }
        li:nth-child(2) {
          width: 50%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
        li:nth-child(3) {
          width: 25%;
          height: 55px;
          text-align: center;
          text-indent: 10px;
        }
        li:nth-child(4) {
          width: 20%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
      }

      .small-box2 {
        width: 100%;
        height: 56px;
        margin: 0;
        padding: 0;
        //  background-color:rgba($color: #c968d6, $alpha:0.05);
        li {
          list-style: none;
          float: left;
          line-height: 56px;
          border-bottom: 1px solid #e8e8e8;
          a {
            float: left;
            color: #0e9a68;
          }
          p {
            float: left;
            color: #0e9a68;
          }
        }

        li:nth-child(1) {
          width: 5%;
          height: 55px;
          text-align: center;
        }
        li:nth-child(2) {
          width: 50%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
        li:nth-child(3) {
          width: 25%;
          height: 55px;
          text-align: center;
          text-indent: 10px;
        }
        li:nth-child(4) {
          width: 20%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
      }
    }
  }
}
</style>
