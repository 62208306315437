<template>
  <a-modal
    :visible="visible"
    title="请裁剪图片"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    width="60%"
    @cancel="handleCancel"
  >
    <div class="cropper-wrapper">
      <vue-cropper
        ref="cropper"
        :img="img"
        :info="true"
        :original="true"
        :autoCrop="options.autoCrop"
         
        autoCropWidth=400
        autoCropHeight=300
        :fixedBox="options.fixedBox"
      ></vue-cropper>
    </div>

     <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  components: {
    VueCropper,
  },
  props:{
      autoCropWidth:{
        type:Number,
        default:50
      },
      autoCropHeight:{
        type:Number,
        default:50
      },
  },
  data() {
    
    return {
      // ant-modal相关配置
      visible: false,
      confirmLoading: false,
      type:"",
      // vue-cropper相关配置 详细的可以去github上看文档
      img: null, //要裁剪的图像资源 可选项: url地址 || base64 || blob
      options: {
        autoCrop: true, //是否默认生成截图框
        fixedBox: true, //固定截图框大小 不允许改变
      },
    }
  },
  methods: {
    // 调用此方法需传入一个 [url地址 || base64 || blob]
    // 父组件调用: this.$refs['cropperModal'].edit(参数)
    edit(image,type) {
      this.confirmLoading = false
      this.img = image
      this.visible = true
      this.type = type
    },
    // 监听对话框的OK/Cancel按钮的点击事件
    handleOk() {
      const that = this
      that.confirmLoading = true
      // 获取截图的base64 数据
      // getCropBlob获取二进制数据
      this.$refs.cropper.getCropData((data) => {
        //将裁剪侯后的图片对象射给**父组件**,然后关闭对话框
        that.$emit('ok', data,this.type)
        that.handleCancel()
      })
    },
    handleCancel(e) {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.cropper-wrapper {
  width: 100%;
  height: 400px;
}
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}
</style>