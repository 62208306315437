<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>审核时间:</p>
      <a-range-picker
        :getCalendarContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body;
          }
        "
        :dropdownStyle="{ overflow: 'auto' }"
        v-model="searchData.beginEndAuditDateList"
        format="YYYY-MM-DD"
      />
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>身份证:</p>
      <a-input placeholder="请输入" v-model="searchData.idCardNo" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" />

      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="add"> 新增 </a-button>
      </a-config-provider>
    </div>
    <!--汇总数据-->
    <div class="refund">当前筛选条件下，获得退款{{ schoolRefundAmt }}元</div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="refundList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>

    <a-modal
      :visibleApply="visibleApply"
      v-model="visibleApply"
      title="退款申请"
    >
      <a-form-model
        :rules="rules"
        ref="applyData"
        :model="applyData"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="visibleApply"
      >
        <a-form-model-item
          style="width: 800px"
          label="练车券码"
          prop="couponNo"
          ref="couponNo"
        >
          <a-input
            placeholder="请输入"
            v-model="applyData.couponNo"
            style="width: 240px"
          />
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button type="primary" @click="handleApplyCancelOk">
            取消
          </a-button>
          <a-button type="primary" @click="handleApplyCloseOk"> 确定 </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal v-model="visibleDetail" title="退款申请">
      <a-form-model
        ref="detailForm"
        :rules="rules"
        :model="detailForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="visibleDetail"
      >
        <a-form-model-item
          style="width: 800px"
          label="学员"
          prop="traineeName"
          ref="traineeName"
        >
          {{ detailForm.traineeName }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="学员手机"
          prop="traineeMobile"
          ref="traineeMobile"
        >
          {{ detailForm.traineeMobile }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="购买时间"
          prop="couponTime"
          ref="couponTime"
        >
          {{ detailForm.couponTime }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="购买人"
          prop="buyChannel"
          ref="buyChannel"
        >
          {{ detailForm.buyChannel }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="预约练车日期"
          prop="bookTrainDate"
          ref="bookTrainDate"
        >
          {{ detailForm.bookTrainDate }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="预约练车时间段"
          prop="bookTimeZone"
          ref="bookTimeZone"
        >
          {{ detailForm.bookTimeZone }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="是否分成"
          prop="profitStatus"
          ref="profitStatus"
        >
          {{ detailForm.profitStatus }}
        </a-form-model-item>

        <a-form-model-item
          style="width: 800px"
          label="申请理由"
          prop="applyReason"
          ref="applyReason"
        >
          <a-input
            :disabled="flag == 'recharge'"
            placeholder="请输入审核理由"
            v-model="detailForm.applyReason"
            style="width: 200px"
          />
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button type="primary" @click="handleApplyResultCloseOk">
            确定
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import moment from "moment";
export default {
  name: "driverSchoolRefundApply", //驾校退款申请管理
  data() {
    return {
      schoolRefundAmt: 0,
      companyRefundAmt: 0,
      agentRefundAmt: 0,
      visibleApply: false,
      flag: "",
      rules: {
        applyReason: [
          { required: true, message: "请输入审核理由", trigger: "blur" },
        ],
      },
      detailForm: {
        applyReason: "",
      },
      queryObj: {},
      searchData: {
        beginEndAuditDateList: [],
        name: "",
        mobile: "",
        idCardNo: "",
      },
      visibleApply: false,
      visibleDetail: false,
      applyData: {
        couponNo: "",
      },

      refundList: [],
      auditBeginTime: "",
      auditEndTime: "",
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "券码",
          dataIndex: "couponNo",
          key: "couponNo",
          width: 100,
        },
        {
          title: "学员姓名",
          dataIndex: "traineeName",
          key: "traineeName",
          width: 100,
        },
        {
          title: "学员手机号",
          dataIndex: "traineeMobile",
          key: "traineeMobile",
          width: 120,
        },
        {
          title: "购买时间",
          dataIndex: "couponTime",
          key: "couponTime",
          width: 110,
        },
        {
          title: "购买渠道",
          dataIndex: "buyChannel",
          key: "buyChannel",
          width: 100,
        },
        {
          title: "预约练车日期",
          dataIndex: "bookTrainDate",
          key: "bookTrainDate",
          width: 130,
        },
        {
          title: "申请时间",
          dataIndex: "applyTime",
          key: "applyTime",
          width: 120,
        },
        {
          title: "审核时间",
          dataIndex: "auditTime",
          key: "auditTime",
          width: 120,
        },
        {
          title: "充值金额（元）",
          dataIndex: "couponAmt",
          key: "couponAmt",
          width: 140,
        },
        {
          title: "分成状态",
          dataIndex: "profitStatus",
          key: "profitStatus",
          width: 100,
        },
        {
          title: "已分成金额",
          dataIndex: "schoolHasProfitAmt",
          key: "schoolHasProfitAmt",
          width: 110,
        },
        {
          title: "申请理由",
          dataIndex: "applyReason",
          key: "applyReason",
          width: 100,
        },
        {
          title: "申请人",
          dataIndex: "applyPerson",
          key: "applyPerson",
          width: 100,
        },
        {
          title: "审核结果",
          dataIndex: "auditResult",
          key: "auditResult",
          width: 100,
        },
        {
          title: "驾校获得退款",
          dataIndex: "schoolProfitRefundAmt",
          key: "schoolProfitRefundAmt",
          width: 130,
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    handleApplyCancelOk() {
      this.visibleApply = false;
    },
    reset() {
      this.searchData.name = "";
      this.searchData.mobile = "";
      this.searchData.idCardNo = "";
      this.searchData.beginEndAuditDateList = [];
      this.auditBeginTime = "";
      this.auditEndTime = "";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      if (
        this.searchData.beginEndAuditDateList != null &&
        this.searchData.beginEndAuditDateList[0] != null
      ) {
        this.auditBeginTime = Helper.dateFormat(
          "YYYY-mm-dd",
          this.searchData.beginEndAuditDateList[0]._d
        );
        this.auditEndTime = Helper.dateFormat(
          "YYYY-mm-dd",
          this.searchData.beginEndAuditDateList[1]._d
        );
      } else {
        //      //设定一个默认值
        // var currentDate = new Date();
        // this.auditEndTime = Helper.dateFormat("YYYY-mm-dd", currentDate);
        // //
        // var currentCopyDate = new Date();
        // currentCopyDate.setMonth(currentCopyDate.getMonth() - 1);
        // this.auditBeginTime = Helper.dateFormat("YYYY-mm-dd", currentCopyDate);
        // //初始化没有日期限制，则设定默认值
        // this.searchData.beginEndAuditDateList = [
        //   moment(currentCopyDate, "YYYY-MM-DD"),
        //   moment(currentDate, "YYYY-MM-DD"),
        // ];
      }

      //获得退款
      ApiConfig.driverSchoolRefundList({
        auditBeginTime: this.auditBeginTime,
        auditEndTime: this.auditEndTime,
        traineeName: this.searchData.name,
        traineeMobile: this.searchData.mobile,
        idCardNo: this.searchData.idCardNo,
        driverSchoolNo: Helper.get("loginInfo").belongNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.list;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }
            this.refundList = list;
            //显示相关汇总数据
            var totalInfo = res.data.data.sumary;
            if (totalInfo != null) {
              this.schoolRefundAmt = totalInfo.school;
              this.companyRefundAmt = totalInfo.company;
              this.agentRefundAmt = totalInfo.agent;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    handleApplyResultCloseOk() {
      //提交退款申请

      this.$refs.detailForm.validate((valid) => {
        console.log(this.detailForm.applyReason);
        if (
          this.detailForm.applyReason == "" ||
          this.detailForm.applyReason == undefined
        ) {
          return;
        }
        if (valid) {
          ApiConfig.saveRefundApply({
            couponNo: this.detailForm.couponNo,
            applyReason: this.detailForm.applyReason,
            driverSchoolNo: Helper.get("loginInfo").belongNo,
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success("退款申请已提交，请等待审核");
                this.visibleDetail = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((error) => {
              this.$message.error(this.CommonConst.errorMsg);
            });
        }
      });
    },
    add() {
      this.visibleApply = !this.visibleApply;
      this.applyData.couponNo = "";
    },
    handleApplyCloseOk() {
      if (this.applyData.couponNo == "") {
        return;
      }
      //获取填写的券的信息
      console.log(this.applyData.couponNo);
      this.applyData.couponNo = this.applyData.couponNo.replace(
        /^(\s|\xA0)+|(\s|\xA0)+$/g,
        ""
      );
      //获得单个券所有信息
      ApiConfig.getCouponOrderMoreInfo({
        couponNo: this.applyData.couponNo,
        driverSchoolNo: Helper.get("loginInfo").belongNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var info = res.data.data;
            if (info != null) {
              console.log(info);
              this.visibleDetail = true;
              this.visibleApply = false;
              this.detailForm = info;
            } else {
              this.$message.error("券码不存在");
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.refund {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 16px;
}
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 150px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-calendar-picker {
      float: left;
      width: 200px;
      margin-left: 6px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 16px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>