<template>
  <div class="coursemanagement paddingCon">
    <!-- <div class="course-hea">
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" />
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div> -->
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="driverschoolList"
        :pagination="false"
        :rowKey="(record,index) => index+1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="traineeDetailClick(record)">查看</div>
        </template>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
// import DriverSchoolAdd from "./DriverSchoolAdd"

export default {
  name: "driverschoolmanagement",
  data() {
    return {
      
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
       searchData: {
        driverschoolList: [],
        name: "",
        mobile: ""
      },
      searchData: {
        driverschoolList: [],
        driverSchoolName: "",

      },
      driverschoolList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 10,
        },
        {
          title: "驾校名称",
          dataIndex: "driverSchoolName",
          key: "driverSchoolName",
          width: 50,
        },
        // { title: "省", dataIndex: "proName", key: "proName", width: 200 },
        // { title: "市", dataIndex: "cityName", key: "cityName", width: 200 },
        // { title: "县（区）", dataIndex: "districtName", key: "districtName", width: 200 },
        {
          title: "地址",
          dataIndex: "addr",
          key: "addr",
          width: 50,
        },
        // {
        //   title: "学员数量",
        //   dataIndex: "agentName",
        //   key: "agentName",
        //   width: 200,
        // },
        {
          title: "学员数量",
          dataIndex: "traineeCount",
          key: "traineeCount",
          width: 50,
        },
         {
          title: "VR设备数量",
          dataIndex: "deviceCount",
          key: "deviceCount",
          width: 50,
        },
        // {
        //   title: "状态",
        //   dataIndex: "statusDesc",
        //   key: "statusDesc",
        //   width: 150,
        // },
         {
          title: "驾校详情",
          key: "action",
          width: 50,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
  },
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    // reset() {
    //   this.searchData.mobile = "";
    //   this.searchData.name = "";
    //   this.searchData.driverschoolList = [];
    //   this.pageObject.pageNum = 1;
    //   this.getList();
    // },
    // search() {
    //   this.pageObject.pageNum = 1;
    //   this.getList();
    // },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      //取出登录者的所在省市区
      var belongNo=Helper.get("loginInfo").belongNo;
      var belongPro="";
      var belongCity="";
      var belongDis="";


      //判断登录人员管辖范围//310120 310000
      if (belongNo!=null&&belongNo!=""){
        if(belongNo.indexOf("0000")>=2){
          //省
          belongPro=belongNo;
        }else if(belongNo.indexOf("00")>=4){
          //市
          belongCity=belongNo;
        }else{
          //区
          belongDis=belongNo;
        }
      }

      
      //监管部门获取驾校列表
        ApiConfig.driverschoolListBySupervision({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        proNo: belongPro,
        cityNo:belongCity,
        districtNo:belongDis
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            for (let i = 0; i < list.length; i++) {
              var createTimeText = Helper.FormatToDate(list[i].createTime);
              list[i].createTimeText = createTimeText;
            }

            this.driverschoolList = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flage, item1) {
      this.$refs["ref_addform"].init(flage, item1); // this.$refs触发一个组件里的方法
    },
    // 学员详情
    traineeDetailClick(item) {
       this.$router.push({
         path: "/supervision/driverschoolmanagement/info",
         query: {
           driverSchoolNo: item.driverSchoolNo,
         },
       });
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 30px;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 14px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>