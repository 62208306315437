<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button
          type="primary"
          class="fgh"
          @click="importTo"
          v-if="totalFee == 0"
        >
          导入
        </a-button>
        <a-button
          type="primary"
          class="fgh"
          v-if="totalFee > 0"
          @click="showModel()"
        >
          支付
        </a-button>
      </a-config-provider>
    </div>

    <div class="rechargeText">
      <ul>
        <li>
          <span>批次号:</span>
          <span>{{ this.queryObj.batchNo }}</span>
        </li>
        <li>
          <span>订单号:</span>
          <span>{{ orderNo }}</span>
        </li>
        <li>
          <span>总金额:</span>
          <span>{{ totalFee }}元</span>
        </li>
      </ul>
    </div>

    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="traineePreAddList"
        :pagination="false"
        :rowKey="(record, index) => index + 1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
    <div>
      <a-modal 
      :visible="isShowQrCode" 
      @cancel="handleCancel"
      :mask="true"
      :maskClosable="false"
      >
        <!-- <a-form-model-item label="实付金额">
          {{ totalFee }}元
        </a-form-model-item> -->
        <div>实付金额:{{totalFee}}元</div>
        <template slot="footer">
          <div class="footerCon">
            <a-button @click="handleCancel" v-if="!isShowQrCode">取消</a-button>
            <a-button type="primary" @click="handleOk" v-if="!isShowQrCode">
              确定
            </a-button>
          </div>
        </template>
        <div
          id="qrcode"
          ref="qrcode"
          style="display: flex; justify-content: center"
        ></div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import axios from "axios";
import QRCode from "qrcodejs2";
export default {
  name: "traineepreaddmanagement",
  data() {
    return {
      isShowQrCode: false,
      timerCount: 0,
      queryObj: {},
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      totalFee: 0,
      orderNo: "",
      searchData: {
        traineePreAddList: [],
        name: "",
        mobile: "",
      },
      traineePreAddList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "新增批次",
          dataIndex: "batchNo",
          key: "batchNo",
          width: 200,
        },
        {
          title: "驾校编号",
          dataIndex: "driverSchoolNo",
          key: "driverSchoolNo",
          width: 200,
        },
        {
          title: "学员姓名",
          dataIndex: "traineeName",
          key: "traineeName",
          width: 200,
        },
        {
          title: "学员手机号",
          dataIndex: "traineeMobile",
          key: "traineeMobile",
          width: 200,
        },
        {
          title: "练车券",
          dataIndex: "couponCount",
          key: "couponCount",
          width: 200,
        },
        {
          title: "单价",
          dataIndex: "realOneAmt",
          key: "realOneAmt",
          width: 200,
        },
        {
          title: "录入时间",
          dataIndex: "createTime",
          key: "createTime",
          width: 200,
        },
        {
          title: "录入人",
          dataIndex: "createUser",
          key: "createUser",
          width: 150,
        },
      ],
    };
  },
  components: {
    QRCode,
  },
  mounted() {
    this.getList();
    this.getPayOrderInfo(); //获取订单信息
  },
  created() {
    this.queryObj = this.$route.query;
  },
  methods: {
    reset() {
      this.searchData.mobile = "";
      this.searchData.name = "";
      this.searchData.traineePreAddList = [];
      this.pageObject.pageNum = 1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    handleOk() {},
    showModel() {
      this.isShowQrCode = true;
      //弹出支付的二维码
      ApiConfig.getSchoolPayQRCodeInfo({
        orderNo: this.orderNo,
        amt: this.totalFee,
      })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data);
            //接口返回图片，或者给一个链接客户端生成二维码
            if (res.data.data.code_url != null) {
              this.qrcode = new QRCode(this.$refs.qrcode, {
                width: 100, // 二维码宽度
                height: 100, // 二维码高度
                text: res.data.data.code_url,
                correctLevel: QRCode.CorrectLevel.H,
              });
              this.timerCount=0;
              this.startTimer();
              //调用轮询，请求服务端（暂不用服务端推送）,超时时间2分钟，5s查询一次
              //res.data.data.rechargeNo
            } else {
              this.isShowQrCode = false;
              console.log(res.data);
              this.$message.success("请求微信支付失败");
              this.visible = false;
            }
          } else {
            this.isShowQrCode = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.isShowQrCode = false;
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //启动定时器
    startTimer() {
      let timer = setInterval(() => {
        this.execGetStatus(timer);
      }, 3000); //间隔请求时间
    },
    //定时器间隔执行的任务
    execGetStatus(timer) {
      setTimeout(() => {
        this.timerCount = this.timerCount + 1;
        // 这里ajax 请求的代码片段和判断是否停止定时器
        var result = this.getPayResultInfo(this.orderNo);
        // 如需要停止定时器，只需加入以下：
        if (result || !this.isShowQrCode || this.timerCount >= 90) {
          clearInterval(timer);
          this.timerCount=0;
          console.log("定时器清除");
          //跳转学员管理界面
          this.$router.push({
            path: "/driverschool/traineemanagement",
          });
        } else {
          console.log("定时器执行中");
        }
      }, 0);
    },
    //导入到正式
    importTo() {
      ApiConfig.traineePreAddImportByDS({
        driverSchoolNo: Helper.get("loginInfo").belongNo,
        batchNo: this.queryObj.batchNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            //跳转到驾校学员信息界面
            this.$router.push({
              path: "/driverschool/traineemanagement",
              query: {},
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    handleCancel(e) {
      this.visible = false;
      this.titleInfo = "单独录入学员";
      this.isShowQrCode = false;
      if (this.$refs.qrcode != undefined) {
        this.$refs.qrcode.innerHTML = "";
      }
    },
   
    getList() {
      //驾校获取学员预增列表
      ApiConfig.traineePreAddListByDS({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        driverSchoolNo: Helper.get("loginInfo").belongNo,
        batchNo: this.queryObj.batchNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }

            this.traineePreAddList = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getPayOrderInfo() {
      ApiConfig.traineePreAddOrderDS({
        driverSchoolNo: Helper.get("loginInfo").belongNo,
        batchNo: this.queryObj.batchNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var info = res.data.data;
            if (info != null && info.totalFee != null) {
              this.totalFee = info.totalFee;
              this.orderNo = info.orderNo;
            } else {
              this.totalFee = 0;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },

    showmodal(flag) {
      this.$refs["ref_addform"].init(flag); // this.$refs触发一个组件里的方法
    },
    getPayResultInfo(orderNo) {
      ApiConfig.traineePayResultInfo({
        orderNo: orderNo,
      })
        .then((res) => {
          console.log("请求订单信息===");
          console.log(res.data);
          if (res.data.code == 200) {
            //支付成功
            if (
              res.data.data.payStatus != null &&
              (res.data.data.payStatus == 1 || res.data.data.payStatus == "1")
            ) {
              console.log("订单已支付");
              this.isShowQrCode = false;
              return true;
            }
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
      return false;
    },

    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>