<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>付费日期范围:</p>
      <a-range-picker 
       :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.payBeginEndDateList" format="YYYY-MM-DD"/>
      <p>练车日期范围:</p>
      <a-range-picker 
       :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.exerBeginEndDateList" format="YYYY-MM-DD"/>

      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="traineeRechargeList"
        :pagination="false"
        :rowKey="(record,index) => index+1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="rechargeDetailClick(record)">查看</div>
        </template>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
export default {
  name: "ctraineerechargedetail",
  data() {
    return {
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        traineeRechargeList: [],
        payBeginEndDateList:[],
        exerBeginEndDateList:[]
        
      },
      traineeRechargeList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          width: 200,
        },
        { title: "手机号", dataIndex: "mobile", key: "mobile", width: 200 },
        { title: "专属驾校", dataIndex: "driverSchoolName", key: "driverSchoolName", width: 200 },
        { title: "付费日期", dataIndex: "payTime", key: "payTime", width: 200 },
        {
          title: "练车日期",
          dataIndex: "exerDate",
          key: "exerDate",
          width: 200,
        },
        {
          title: "驾校分成",
          dataIndex: "schoolProfitAmt",
          key: "schoolProfitAmt",
          width: 200,
        },
        {
          title: "代理分成",
          dataIndex: "agentProfitAmt",
          key: "agentProfitAmt",
          width: 200,
        },
        {
          title: "宙谷分成",
          dataIndex: "companyProfitAmt",
          key: "companyProfitAmt",
          width: 150,
        }
      ],
    };
  },
  components: {
  },
  mounted() {
    this.getList();
  },
  created() {
    this.queryObj = this.$route.query;
  },
  methods: {
    reset() {
      this.searchData.payBeginEndDateList = [];
      this.searchData.exerBeginEndDateList = [];
      this.searchData.traineeRechargeList = [];
      this.pageObject.pageNum = 1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      //学员充值明细
      ApiConfig.traineeRechargeDetail({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        payBeginDate: this.searchData.payBeginEndDateList[0],
        payEndDate:this.searchData.payBeginEndDateList[1],
        exerBeginDate: this.searchData.exerBeginEndDateList[0],
        exerEndDate:this.searchData.exerBeginEndDateList[1],
        driverSchoolNo: this.queryObj.driverSchoolNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].tradeTime);
            //   list[i].createTimeText = createTimeText;
            // }

            this.traineeRechargeList = list;
            //this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flage, item1) {
      this.$refs["ref_addform"].init(flage, item1); // this.$refs触发一个组件里的方法
    },
    //驾校充值明细
    rechargeDetailClick(item) {
      this.$router.push({
        path: "/company/traineerechargedetail",
        query: {
          driverSchoolNo: item.driverSchoolNo,
        },
      });
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-calendar-picker{
      float: left;
      padding: 0px 5px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>