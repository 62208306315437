<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item label="所属省份" prop="proNo" ref="proNo">
          <a-select
            v-model="formData.proNo"
            @change="bindSchoolListByPro"
            placeholder="请选择省份" :disabled="this.flag=='edit'"
          >
            <a-select-option
              :value="item.proNo"
              v-for="(item, index) in optionProList"
              :key="index"
              >{{ item.proName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="所属驾校"
          prop="driverSchoolNo"
          ref="driverSchoolNo">
          <a-select v-model="formData.driverSchoolNo" placeholder="请选择驾校" 
          :disabled="this.flag=='edit'"
          >
            <a-select-option
              :value="item.driverSchoolNo"
              v-for="(item, index) in optionsDriverSchoolList"
              :key="index"
              >{{ item.driverSchoolName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="设备号"
          prop="deviceNo"
          ref="deviceNo" v-if="this.flag=='edit'">{{formData.deviceNo}}
        </a-form-model-item>
        <a-form-model-item label="版本号" prop="versionNo" ref="versionNo">
          <a-input
            :maxLength="50"
            placeholder="请输入版本号"
            v-model="formData.versionNo"
          />
        </a-form-model-item>

        <a-form-model-item
          label="版本代码编号"
          prop="versionCodeNo"
          ref="versionCodeNo"
        >
          <a-input
            :maxLength="50"
            placeholder="请输入代码编号"
            v-model="formData.versionCodeNo"
          />
        </a-form-model-item>

        <a-form-model-item label="资源路径" prop="resUrl" ref="resUrl">
          <a-input
            :maxLength="500"
            placeholder="请输入资源地址"
            v-model="formData.resUrl"
          />
        </a-form-model-item>
        <a-form-model-item
          label="强制更新"
          prop="isForceUpdate"
          ref="isForceUpdate"
        >
          <a-select v-model="formData.isForceUpdate">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark" ref="remark">
          <a-input
            :maxLength="100"
            placeholder="请输入备注"
            v-model="formData.remark"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";

export default {
  components: {},
  data() {
    return {
      titleInfo: "",
      operateType:false,
      visible: false,
      loading: false,
      optionProList: [],
      optionsDriverSchoolList: [],
      driverSchoolNo: "",
      flag: "",
      rules: {
        versionNo: [
          { required: true, message: "请输入版本信息", trigger: "change" }
        ]
      },
      formData: {
        proNo: "",
        driverSchoolNo: "",
        versionNo: "",
        versionCodeNo: "",
        isForceUpdate: 1,
        resUrl: "",
        remark:""
      }
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v, obj) {
      this.resetForm();
      //获取省份列表
      this.getProList();
      this.visible = true;
      this.flag = v;
      this.titleInfo = "编辑驾校设备版本信息";
      if (v == "add") {
        this.titleInfo = "新增驾校设备版本信息";
        this.formData.proNo="";
        this.formData.driverSchoolNo="";
        this.operateType=true;
      }
      if (v != "add") {
        this.getDeviceVersion(obj.deviceNo);
      }
    },
    getProList() {
      ApiConfig.getProList({})
        .then(res => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.optionProList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getDeviceVersion(deviceNo){
      ApiConfig.getDeviceVersion({
        deviceNo: deviceNo
      })
        .then(res => {
          if (res.data.code == 200) {
            var info = res.data.data;
            if(info!=null){
              this.formData.proNo=info.proNo;
              this.flag="edit";
              this.formData.driverSchoolNo=info.driverSchoolNo;
              this.formData.versionNo = info.versionNo;
              this.formData.versionCodeNo= info.versionCodeNo;
              this.formData.resUrl=info.resUrl;
              this.formData.deviceNo = info.deviceNo;
              this.formData.isForceUpdate = info.isForceUpdate;
              this.formData.remark=info.remark;
              this.bindSchoolListByPro();
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    bindSchoolListByPro() {
      //根据选择的省份筛选出对应的驾校列表
      var proNo = this.formData.proNo;
      if (proNo == null || proNo == "") {
        return;
      }
      if(this.flag=="add"){
        this.formData.driverSchoolNo="";
      }
      
      ApiConfig.getDriverSchoolListByPro({
        proNo: proNo
      })
        .then(res => {
          if (res.data.code == 200) {
            this.optionsDriverSchoolList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });

    },

    resetForm() {
      this.formData = {
        proNo: "",
        driverSchoolNo: "",
        versionNo: "",
        versionCodeNo: "",
        isForceUpdate: 1,
        resUrl: ""
      };
    },

    handleSelectChange(value) {
      console.log(value);
    },
    handleOk(e) {
      this.$refs.ruleForm.validate(valid => {
        if (this.formData.versionNo == "") {
          return;
        }
        if (
          this.formData.proNo == "" ||
          this.formData.driverSchoolNo == "" ||
          this.formData.versionNo == ""
        ) {
          this.$message.error("信息不完成，请检查");
          return;
        }
        //
        if (this.formData.isForceUpdate == null ) {
           this.formData.isForceUpdate = 1;
        }

        if (valid) {
          //保存
          if (this.flag == "add") {
            ApiConfig.deviceVersionSave({
              proNo: this.formData.proNo,
              driverSchoolNo: this.formData.driverSchoolNo,
              versionNo: this.formData.versionNo,
              versionCodeNo: this.formData.versionCodeNo,
              resUrl: this.formData.resUrl,
              isForceUpdate: this.formData.isForceUpdate,
              remark: this.formData.remark
            })
              .then(res => {
                if (res.data.code == 200) {
                  this.visible = false;
                  this.$emit("backInit");
                } else {
                  this.$message.error("保存失败,请检查该驾校下是否有设备");
                }
              })
              .catch(() => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }else if(this.flag == "edit"){
            ApiConfig.deviceVersionUpdate({
              driverSchoolNo: this.formData.driverSchoolNo,
              deviceNo: this.formData.deviceNo,
              versionNo: this.formData.versionNo,
              versionCodeNo: this.formData.versionCodeNo,
              resUrl: this.formData.resUrl,
              isForceUpdate: this.formData.isForceUpdate,
              remark: this.formData.remark
            })
              .then(res => {
                if (res.data.code == 200) {
                  this.visible = false;
                  this.$emit("backInit");
                } else {
                  this.$message.error("保存失败");
                }
              })
              .catch(() => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        }
      });
    },

    handleCancel(e) {
      this.visible = false;
    }
  },
  created() {},
  activated() {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>
