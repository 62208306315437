import Vue from 'vue'
var storage = (function() {
    var isSupport = function(storageType) {
        var testKey = 'test'
        var storage = window[storageType]
        try {
            storage.setItem(testKey, '1')
            storage.removeItem(testKey)
            return storageType in window && window[storageType]
        } catch (e) {
            return false
        }
    }
    var storages = ['localStorage', 'sessionStorage']
    for (var i = 0, len = storages.length; i < len; i++) {
        if (isSupport(storages[i])) return window[storages[i]]
    }
    return null
})()

const _buffer = {
    nonMark: false,
    sessionData: {}
}

const helper = {
    loadingStatus_show: 0,
    loadingStatus_hide: 0,
    storage: storage,
    /* 判断 obj 是否为 null
     Usage:
     var obj = null
     helper.isNull(obj) // 输出 true
     */
    isNull: function(obj) {
        return obj === null || obj === undefined || obj === "" || obj !== obj;
    },

    /* 判断 obj 是否为 function
     Usage:
     var obj = 'abc'
     helper.isFunction(obj) // 输出 false
     */
    isFunction: isType('Function'),

    /* 判断 obj 是否为 object
     Usage:
     var obj = {}
     helper.isObject(obj) // 输出 true
     */
    isObject: isType('Object'),

    /* 判断 obj 是否为 array
     Usage:
     var obj = ['abc']
     helper.isArray(obj) // 输出 true
     */
    isArray: window.isArray || isType('Array'),

    /* 判断 obj 是否为 string
     Usage:
     var obj = 'abc'
     helper.isString(obj) // 输出 true
     */
    isString: isType('String'),

    /* 判断 obj 是否为 undefined
     Usage:
     var obj = 'abc'
     helper.isUndefined(obj) // 输出 false
     */
    isNumber: isType('Number'),
    /* 判断 obj 是否为 Number
     Usage:
     var obj = 123
     helper.isUndefined(obj) // 输出 true
     */
    isUndefined: isType('Undefined'),

    // 获取 Cookie
    getCookie: getCookie,
    // 设置 Cookie
    setCookie: setCookie,
    // 移除 Cookie
    removeCookie: removeCookie,
    // 获取 Session
    getSession: getSession,
    // 设置 Session
    setSession: setSession,

    timeout: null,

    /* 判断请求是否成功
     @param res `Object` 后台返回的response对象
     Usage:
     helper.isSuccess(res)
     */
    isSuccess: function(res) {
        return ((res.status == null || res.status && Number(res.status.code) === 0) || (res.status && Number(res.status.code) === 200)) && (res.data == undefined || res.data != null)
    },

    /* 获取浏览器存储里key为item的值
     @param item 要获取的数据的索引值
     Usage:
     helper.get('token')
     */
    get: function(item) {
        var value;
        if (this.storage) {
            value = this.storage.getItem(item)
        } else {
            value = getCookie(item)

        }
          
        
            var test = value && this.decrypt(value) !== undefined  ? JSON.parse(this.decrypt(value)) : ''
        
        return test
    },

    /* 将数据存储在浏览器存储里
     @param obj 要存储的数据对象
     Usage:
     j.set({ token: 'as23q1sdf212swsxx', uname: 'jacket' })
     */
    set: function(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                this.storage ? this.storage.setItem(key, this.encrypt(String(obj[key]))) : setCookie(key, this.encrypt(String(obj[key])), 1)
            }
        }
        return this
    },

    //清空缓存
    clear: function() {
        function clearCookie() {
            var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
            if (keys) {
                for (var i = keys.length; i--;)
                    document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
            }
        }

        if (this.storage) {
            this.storage.clear();
        } else {
            clearCookie();
        }
        return this;
    },

    /* 删除浏览器存储的数据
     @param itemArr 要删除的数据的key组成的数组
     Usage:
     j.remove(['token', 'uname']) // 将删除浏览器存储中，索引为token和uname的数据
     */
    remove: function(itemArr) {
        for (var i = 0, len = itemArr.length; i < len; i++) {
            if (this.storage) {
                this.storage.removeItem(itemArr[i])
            } else {
                removeCookie(itemArr[i])
            }
        }
        return this
    },

    /* 深层索引：通过标识符获取对象里面的值
     @param obj - 对象字面量
     @param identify - 索引标识符
     Usage:
     var obj = {
     a: {
     b: {
     c: 1
     }
     }
     }
     helper.get(obj, 'a.b.c')
     结果：取得obj['a']['b']['c']的值1
     */
    getObjItem: function(obj, identify) {
        if (!identify) return null;
        var arr = identify.split('.');
        arr.forEach(function(item) {
            obj = obj[item];
        });
        return obj;
    },

    // 获取search对象
    // 页面url为http://a.com?i=1&j=2&k=3, 将输出{i: 1, j: 2, k:3}
    getSearch: function() {
        var hash = window.location.hash,
            index = hash.indexOf('?');
        var search = window.location.search || (index !== -1 ? hash.substr(index) : ''),
            o = {},
            item;
        if (search) {
            search = search.substr(1).split('&');
            for (var i = 0, len = search.length; i < len; i++) {
                item = search[i].split('=');
                o[item[0]] = item[1];
            }
        }
        return o;
    },

    /* 获取search的某个值
     Usage:
     页面url为http://a.com?i=1&j=2&k=3, j.getSearchItem('k') 将输出'3'
     */
    getSearchItem: function(key) {
        var o = this.getSearch()
        return o[key] ? decodeURIComponent(o[key]) : null
    },

    /* 获取当前年月日
     @param seperator `String` 分隔符
     Usage:
     helper.getCurrentDate('/')
     输出
     2016/01/02
     */
    getCurrentDate: function(seperator) {
        seperator = seperator && typeof seperator === 'string' ? seperator : '-'
        var o = new Date()
        var y = o.getFullYear()
        var m = o.getMonth() + 1
        var d = o.getDate()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        return y + seperator + m + seperator + d
    },

    // 加密
    encrypt: function(value) {
        var encryptValue = ''
        var mask
        var maskValue
        for (var i = 0, len = value.length; i < len; i++) {
            mask = Math.round(Math.random() * 111) + 77
            maskValue = value.charCodeAt(i) + mask
            mask += i
            encryptValue += String.fromCharCode(maskValue, mask)
        }
        return encryptValue
    },

    // 解密
    decrypt: function(value) {
        var decryptValue = ''
        var k = 0
        var v
        var m
        var decrypt = function(v, m, i) {
            v = v.charCodeAt(0)
            m = m.charCodeAt(0)
            m -= i
            v -= m
            return String.fromCharCode(v)
        }
        for (var i = 0, len = value.length; i < len; i++) {
            if (!(i % 2)) {
                v = value[i]
            } else {
                m = value[i]
                decryptValue += decrypt(v, m, k)
                k++
            }
        }
 
            return decryptValue

        
    },

    /* 获取对象组成的数组中，key=value的对象在数组中的索引值
     @param arr 'Array' 数组
     @param key 'String|Number' 对象的key
     @param value 'String|Number|Boolean' 对象的value
     Usage:
     var arr = [ {a: 0}, {a: 1}, {b: 3}, {d: '8'} ]
     helper.getIndex(arr, 'a', 1)
     输出
     1
     */
    getIndex: function(arr, key, value) {
        for (var i = 0, len = arr.length; i < len; i++) {
            if (!this.isObject(arr[i])) return -1
            for (var k in arr[i]) {
                if (k === key && arr[i][k] === value) return i
            }
        }
        return -1
    },





    /* 获取对象组成的数组中，key=value的对象在数组中的名称
     @param arr 'Array' 数组
     @param key 'String|Number' 对象的key
     @param value 'String|Number|Boolean' 对象的value
     @param name 获取字典name值
     Usage:
     var arr = [ {a: 0, a1: '一'}, {b: 1, b1: '二'}, {c: 3, c1: '三'}, {d: '8', d1: '八'} ]
     helper.getName(arr, 'a', 1, 'a1')
     输出
     1
     */

    globalLoading_show: function(callBack) {
        var that = this;
        that.loadingStatus_show++;
        $("#global_loading").show();
        if (callBack) {
            callBack()
        }
    },
    globalLoading_hide: function(callBack) {
        var that = this;
        that.loadingStatus_hide++;
        // if (that.loadingStatus_show == that.loadingStatus_hide) {
        $("#global_loading").hide();
        if (callBack) {
            callBack()
        }
        that.loadingStatus_show = 0;
        that.loadingStatus_hide = 0;
        // }
    },
    getName: function(arr, key, value, name) {
        for (var i = 0, len = arr.length; i < len; i++) {
            if (!this.isObject(arr[i])) return -1
            for (var k in arr[i]) {
                if (k === key && arr[i][k] === value) return arr[i][name]
            }
        }
        return ""
    },

    /* 获取对象组成的数组中，key=value的对象在数组中的名称数组字符串
     @param arr 'Array' 数组
     @param key 'String|Number' 对象的key
     @param name 获取字典name值
     @param values value数组
     @param splitChar 间隔字符
     Usage:
     var arr = [ {a: 0, a1: '一'}, {b: 1, b1: '二'}, {c: 3, c1: '三'}, {d: '8', d1: '八'} ]
     var values = [0,1,2,3]
     helper.getArrText(arr, 'a', 'a1', values, ',')
     输出
     1
     */
    getArrText(arr, key, name, values, splitChar) {
        var txt = [];
        for (var k in values) {
            txt.push(this.getName(arr, key, values[k], name));
        }
        return txt.join(splitChar);
    },

    /**
     * 数组去重
     */
    getNewArray(arr) {
        var newArr = []; //新建一个数组
        for (var i = 0, len = arr.length; i < len; i++) {
            if (newArr.indexOf(arr[i]) == -1) { //若新数组中未包含该项则将其存入新数组
                newArr.push(arr[i]);
            }
        }
        return newArr;
    },
    //查找数组项的索引值index
    getArrayIndex(arr, val) {
        var index;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == val) {
                index = i
            }
        }
        return index;
    },
    //排序，小到大
    getArrayByAce(arr) {
        return arr.sort(function(a1, a2) {
            return a1 - a2
        });
    },
    //案场提后
    getArrayTargrtBack(arr) {
        var that = this;
        if (arr.indexOf(-1) > -1) {
            var index = that.getArrayIndex(arr, -1);
            arr.splice(index, 1);
            arr.push(-1);
        }
    },
    //图片base64 上传
    ImgUploadByBase64(file, successCall) {
        var data64, filename, file = file;
        var reader = new FileReader();
        reader.onload = function() {
            data64 = this.result;
            filename = file.name;
            if (typeof successCall == 'function') {
                successCall(data64, filename);
            }
        };
        reader.readAsDataURL(file);
    },
    // 2020-11-13T19:08:11  =>  2020-11-13 19:08:11
    FormatToDate(date, isShowTime = false) {
        if (!date) return
        var date = new Date(date);
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        var d = date.getDate()
        d = d < 10 ? '0' + d : d
        var h = date.getHours()
        h = h < 10 ? '0' + h : h
        var minute = date.getMinutes()
        minute = minute < 10 ? '0' + minute : minute
        var second = date.getSeconds()
        second = second < 10 ? '0' + second : second
        if (isShowTime) {
            return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
        } else {
            return y + '-' + m + '-' + d
        }

    },
    dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    },
    getCurYear() {
        var t = new Date()
        var curYear = t.getFullYear()
        return curYear;
    },
    getCurYearMonth() {
        var t = new Date()
        var curYear = t.getFullYear()
        var curMonth = t.getMonth() + 1;
        if (curMonth < 10) {
            curMonth = "0" + curMonth;
        }
        return curYear + '-' + curMonth;
    },
    getCurYearList() {
        var t = new Date()
        var curYear = t.getFullYear()
        var yearList = [
            { id: curYear + 1, name: curYear + 1, active: false },
            { id: curYear, name: curYear, active: true },
            { id: curYear - 1, name: curYear - 1, active: false }
        ];
        return yearList;
    },
    //深度克隆
    deepClone(obj) {
        var that = this;
        var result;
        var oClass = that.isClass(obj);
        //确定result的类型
        if (oClass === "Object") {
            result = {};
        } else if (oClass === "Array") {
            result = [];
        } else {
            return obj;
        }
        for (var key in obj) {
            var copy = obj[key];
            if (that.isClass(copy) == "Object") {
                result[key] = that.deepClone(copy); //递归调用
            } else if (that.isClass(copy) == "Array") {
                result[key] = that.deepClone(copy);
            } else {
                result[key] = obj[key];
            }
        }
        return result;
    },
    // 时间字符串比较函数，第一个比较大的话就返回true，否则false
    compareDate(DateOne, DateTwo) {
        DateOne += '';
        DateTwo += '';
        var OneMonth = DateOne.substring(5, DateOne.lastIndexOf("-"));
        var OneDay = DateOne.substring(DateOne.length, DateOne.lastIndexOf("-") + 1);
        var OneYear = DateOne.substring(0, DateOne.indexOf("-"));
        var TwoMonth = DateTwo.substring(5, DateTwo.lastIndexOf("-"));
        var TwoDay = DateTwo.substring(DateTwo.length, DateTwo.lastIndexOf("-") + 1);
        var TwoYear = DateTwo.substring(0, DateTwo.indexOf("-"));
        if (Date.parse(OneMonth + "/" + OneDay + "/" + OneYear) > Date.parse(TwoMonth + "/" + TwoDay + "/" + TwoYear)) {
            return true;
        } else {
            return false;
        }
    },
    //返回传递给他的任意对象的类
    isClass(o) {
        if (o === null) return "Null";
        if (o === undefined) return "Undefined";
        return Object.prototype.toString.call(o).slice(8, -1);
    },

    //过滤函数，接收一个id和一个数组，匹配数组里面的额id，返回相应的name；
    getFilter(id, list) {
        var result = "";
        for (var i = 0, len = list.length; i < len; i++) {
            if (list[i].Id == id || list[i].id == id) {
                result = list[i].name;
            }
        }
        return result;
    },
    // 包销案场根据角色权限过滤价格
    // 1-专案、3-女专、5-主委、9-主委(含女专/专案)、18-子公司总经理、19-金融专员、21-金融主管,99-最终审核人
    getFilter_exclusiveSale(key, value, roleId, unit) {
        var result = '****';
        unit = unit || '';
        value = this.moneyFormart(value);
        switch (key) {
            case 'surfacePrice': // 销售表价
                if ([1, 3, 5, 9, 18, 19, 21, 99].indexOf(roleId) > -1) {
                    result = (value || 0) + '' + unit;
                }
                break;
            case 'bottomPrice': // 销售底价
                if ([5, 9, 18, 19, 21, 99].indexOf(roleId) > -1) {
                    result = (value || 0) + '' + unit;
                }
                break;
            case 'exclusiveSalePrice': // 包销底价
                if ([19, 21, 99].indexOf(roleId) > -1) {
                    result = (value || 0) + '' + unit;
                }
                break;
        }
        return result;
    },
    formatMonth(date) {
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        return y + '-' + m;
    },
    formatDate(date) {
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
    },
    // 获取本周、本月、本年开始时间和结束时间
    getTypeDate(type) {
        var date = new Date(),
            month = date.getMonth(),
            year = date.getFullYear(),
            day = date.getDate(),
            curWeek = date.getDay() == 0 ? 7 : date.getDay(),
            endDay,
            start,
            end,
            resDate = {};

        // 获取当月天数
        if (month == 1) {
            endDay = year % 4 == 0 ? 29 : 28;
        } else if (month == 0 || month == 2 || month == 4 || month == 6 || month == 7 || month == 9 || month == 11) {
            endDay = 31;
        } else {
            endDay = 30;
        }

        if (type == 'month') {
            start = new Date(year, month, 1)
            end = new Date(year, month, endDay)
        } else if (type == 'week') {
            start = new Date(year, month, day - curWeek + 1)
            end = new Date(year, month, day + (7 - curWeek))
        } else if (type == 'year') {
            start = new Date(year, 0, 1)
            end = new Date(year, 11, 31)
        } else {
            start = end = new Date(year, month, day)
        }

        resDate.start = this.formatDate(start)
        resDate.end = this.formatDate(end)
        return resDate
    },
    // 将数字转化为千分位，三位以逗号隔开
    formatNum: function(num) {
        if (num) {
            return parseFloat(num).toLocaleString();
        }
        return num;
    },
    /*
     资金格式化插件
     @value 金额(單位分)
     */
    moneyFormart: function(value) {
        var data,
            decimals,
            integer,
            length;
        var m = [];
        var isBack = false;
        if (value < 0) {
            isBack = true;
            value = -value;
        }
        if (value == 0 || value == null) {
            return 0;
        }
        if (isNaN(Number(value))) {
            return value;
        }
        value = Number(value).toFixed(2);
        //获取小数部分
        decimals = value.match(/\.[0-9]*/g);
        //获取整数部分
        integer = parseInt(value).toString();
        var temp = integer.split("");
        length = temp.length;
        if (isBack) {
            return length > 3 ? '-' + formart() : '-' + value;
        } else {
            return length > 3 ? formart() : value;
        }
        //添加","分隔符
        function formart() {
            var result;
            var count = 0;
            for (var n = length; n > 0; n--, count++) {
                if (count && count % 3 == 0) {
                    m.unshift(",");
                    count = 0;
                }
                m.unshift(temp.pop());
            }
            result = m.join("");
            return decimals ? result.concat(decimals) : result;
        }
    },
    //函数：获取尺寸
    findDimensions: function() {
        var winHeight = 0;
        //获取窗口高度
        if (window.innerHeight)
            winHeight = window.innerHeight;
        else if ((document.body) && (document.body.clientHeight))
            winHeight = document.body.clientHeight;
        //通过深入Document内部对body进行检测，获取窗口大小
        if (document.documentElement && document.documentElement.clientHeight && document.documentElement.clientWidth) {
            winHeight = document.documentElement.clientHeight;
        }
        return winHeight;
    },
    moneyFormart_toFixed4: function(value) {
        var data,
            decimals,
            integer,
            length;
        var m = [];
        var isBack = false;
        if (value < 0) {
            isBack = true;
            value = -value;
        }
        if (value == 0 || value == null) {
            return 0;
        }
        if (isNaN(Number(value))) {
            return value;
        }
        value = Number(value).toFixed(4);
        //获取小数部分
        decimals = value.match(/\.[0-9]*/g);
        //获取整数部分
        integer = parseInt(value).toString();
        var temp = integer.split("");
        length = temp.length;
        if (isBack) {
            return length > 3 ? '-' + formart() : '-' + value;
        } else {
            return length > 3 ? formart() : value;
        }
        //添加","分隔符
        function formart() {
            var result;
            var count = 0;
            for (var n = length; n > 0; n--, count++) {
                if (count && count % 3 == 0) {
                    m.unshift(",");
                    count = 0;
                }
                m.unshift(temp.pop());
            }
            result = m.join("");
            return decimals ? result.concat(decimals) : result;
        }
    },
    decimal4: function(number) {
        var regex = /^[0-9]+\.{0,1}[0-9]{0,4}$/;
        return regex.test(number);
    },
    // 验证按钮权限
    authFunc: function(code) {
        var authCodes = this.get('authcodes');
        return authCodes && (authCodes.indexOf(code) != -1);
    },

    /*自定义提示tooltip，fixed布局，突破overflow的限制
     *html：需要在页面添加tooltip的html代码
     *js：然后配合鼠标移入移出事件完成交互@mouseenter  @mouseleave
     *<div class="mytooltip">
        <div class="tooltip_warp" >
          <div class="tooltip_bottom"></div>
          <div class="tooltip_msg">删除</div>
        </div>
      </div>
     *
     *样式已经在公共部分添加，参照iview的组件样式
     *msg为传入的提示内容，
     *element为要提示的元素
     */
    getMyTooltip(msg, element) {
        $(".mytooltip").stop(true); //清除元素的所有动画
        $(".mytooltip").find(".tooltip_msg").text(msg);
        var settop = $(element).offset().top; //元素距离窗口的顶边距
        var setleft = $(element).offset().left; //元素距离窗口的左边距
        var theight = $(".mytooltip").height(); //浏览器的高度
        var twidth = $(".mytooltip").width(); //浏览器的宽度
        var ehtight = $(element).height(); //元素的高度
        var ewidth = $(element).width(); //元素的宽度
        var setEleft = setleft + ewidth / 2 - twidth / 2;
        var setEtop = settop - ehtight - 3;
        $(".mytooltip").css({ "top": setEtop, "left": setEleft });
        $(".mytooltip").fadeIn(1);
    },
    hideMyTooltip() {
        var that = this;
        $(".mytooltip").fadeOut(1);
    },
    //指定位置 插入文本
    insertAtCursor: function(myField, myValue) {
        //IE support
        if (document.selection) {
            myField.focus();
            sel = document.selection.createRange();
            sel.text = myValue;
            sel.select();
        }
        //MOZILLA/NETSCAPE support
        else if (myField.selectionStart || myField.selectionStart == '0') {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;
            // save scrollTop before insert
            var restoreTop = myField.scrollTop;
            myField.value = myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length);
            if (restoreTop > 0) {
                myField.scrollTop = restoreTop;
            }
            myField.focus();
            myField.selectionStart = startPos + myValue.length;
            myField.selectionEnd = startPos + myValue.length;
        } else {
            myField.value += myValue;
            myField.focus();
        }
    },

    timestampToTime: function(timestamp, type) {
        var Y, M, D, h, m, s, date;
        date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + '-';
        M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        D = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
        h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
        m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
        s = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds();
        if (type && type == 'date') {
            return Y + M + D;
        }
        return Y + M + D + ' ' + h + m + s;



    }
}

function isType(type) {
    return function(obj) {
        return {}.toString.call(obj) === '[object ' + type + ']'
    }
}

/* 获取 Cookie 值
 */
function getCookie(name) {
    var c = document.cookie
    if (c.length > 0) {
        var s = c.indexOf(name + '=')
        if (s !== -1) {
            s = s + name.length + 1
            var e = c.indexOf('', s)
            return unescape(c.substring(s, e))
        }
    }
    return ''
}

/* 保存 Cookie 值
 */
function setCookie(name, value, expiredays) {
    var exdate = new Date()
    if (value !== null && value !== '' && value !== 'null') {
        exdate.setDate(exdate.getDate() + expiredays)
    } else {
        exdate.setDate(exdate.getDate() - 1)
    }
    document.cookie = name + '=' + escape(value) + ((expiredays == null) ? '' : 'expires=' + exdate.toGMTString())
}

/* 删除 Cookie
 */
function removeCookie(name) {
    setCookie(name, '', -1)
}

/**
 * 保存会话数据，临时数据存储使用
 * @param key
 * @return {*}
 */
function setSession(key, val) {
    if (sessionStorage.setItem) {
        try {
            sessionStorage.setItem(key, JSON.stringify(val));
        } catch (e) {
            _buffer.nonMark = true;
            _buffer.sessionData[key] = val;
        }
    } else {
        _buffer.nonMark = true;
        _buffer.sessionData[key] = val;
    }
};

/**
 * 获取会话数据，临时数据存储使用
 * @param key
 * @return {*}
 */
function getSession(key) {
    if (sessionStorage.getItem && !_buffer.nonMark) {
        var val = sessionStorage.getItem(key);
        try {
            return JSON.parse(val);
        } catch (e) {
            return val;
        }
    } else {
        return _buffer.sessionData[key];
    }
};

export default helper