<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        v-if="visible"
      >
        <a-form-model-item label="姓名" prop="name" ref="name">
          <a-input
            :maxLength="20"
            placeholder="请输入学员姓名"
            v-model="formData.name"
          />
        </a-form-model-item>

        <a-form-model-item label="手机号" prop="mobile" ref="mobile">
          <a-input
            :maxLength="15"
            placeholder="请输入学员手机号"
            v-model="formData.mobile"
          />
        </a-form-model-item>
        <a-form-model-item label="身份证" prop="idCardNo" ref="idCardNo">
          <a-input
            :maxLength="20"
            placeholder="请输入学员身份证"
            v-model="formData.idCardNo"
          />
        </a-form-model-item>
        <a-form-model-item label="其他信息" prop="extInfo" ref="extInfo">
          <a-input
            :maxLength="60"
            placeholder="请输入学员其他信息"
            v-model="formData.extInfo"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleConfirm"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
import sdk from "@/utils/idcard/sdk-websocket";
export default {
  components: {},
  data() {
    return {
      titleInfo: "绑定学员(学员已注册)",
      visible: false,
      loading: false,
      flag: "",
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        idCardNo: [
          { required: true, message: "请输入身份证", trigger: "blur" },
        ],
      },
      formData: {
        name: "",
        mobile: "",
        idCardNo: "",
        extInfo: "",
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v, obj) {
      this.visible = true;
      this.flag = v; //判断是否充值
      this.resetForm();
      if (obj != null) {
        this.formData.name = obj.name;
        this.formData.mobile = obj.mobile;
        this.formData.idCardNo = obj.idCardNo;
        this.formData.extInfo = obj.extInfo;
      }
      //读卡
      this.readCard()
    },
     //读卡
    readCard(){
      sdk.connect(this.connectedCallBack,this.callback);
    },
    //连接成功回到
    connectedCallBack(res){
      if(res==1){
        sdk.ReadIDCardNoJudge(); 
      }
    },
    //获取到数据回调
    callback(res){
      console.log('callback',res)
      if(res!=null&&res.partyName!=null){
        this.formData.name=res.partyName;
        this.formData.idCardNo=res.certNumber;
      }else{
        this.formData.name="";
        this.formData.idCardNo="";
      }
    },
    closeReadCard(){
      sdk.closeConnect()
    },
    resetForm() {
      this.formData = {
        name: "",
        mobile: "",
        idCardNo: "",
        extInfo: "",
      };
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (
          this.formData.name == "" ||
          this.formData.mobile == "" ||
          this.formData.idCardNo == ""
        ) {
          return;
        }
        if (valid) {
          if (this.flag == "bind") {
            //将学员与驾校进行绑定
            ApiConfig.traineeBindSchool({
              name: this.formData.name,
              mobile: this.formData.mobile,
              idCardNo: this.formData.idCardNo,
              extInfo: this.formData.extInfo,
              driverSchoolNo: Helper.get("loginInfo").belongNo,
            })
              .then((res) => {
                if (res.data.code == 200) {
                  //
                  this.$message.success(res.data.msg);
                  //隐藏当前弹出页面
                  this.visible = false;
                  //刷新父页面
                  this.$emit("backInit");
                } else {
                  this.$message.error(res.data.msg);
                }
                this.closeReadCard();
              })
              .catch(() => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleCancel(e) {
      this.visible = false;
    },
  },

  created() {},
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>