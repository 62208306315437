<template>
<div>
    <div class="rightBtn">
      <a-button type="primary" @click="returnBack()"> 返回 </a-button>
    </div>
    <div class="rightBtn">
      <a-button type="primary" @click="edit('add')"> 新增权限角色 </a-button>
    </div>
  <div class="permissionlist marginTop20">
      
    <div class="leftTable">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="roleType" slot-scope="roleType">
          {{ roleType == 1 ? "驾校端" : roleType == 2 ? "公司端" :roleType == 3 ? "代理端":"监管人员" }}
       </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a @click="del(record)">删除</a> / -->
          <a @click="edit('edit', record)">修改</a>
        </span>
        
      </a-table>
    </div>
  </div>  
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js";
import Croppermodal from "@/components/cropper-modal";
import config from "@/config";
import Helper from "@/utils/helper";
export default {
  name: "permissionlist",
  data() {
    return {
      columns: [
        {
          title: "角色描述",
          dataIndex: "roleDesc",
          key: "roleDesc",
        },
        {
          title: "角色类型",
          dataIndex: "roleType",
          key: "roleType",
          scopedSlots: { customRender: "roleType" }
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
        },

        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      dataList: [],
    };
  },
  components: {},
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    getList() {
      ApiConfig.companyStaffRoleList({
          //roleType:'2'
      })
        .then((res) => {
          console.log("res=========", res);
          if (res.data.code == 200) {
            this.dataList = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },

    del(record) {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "确定删除吗?",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          ApiConfig.roleDelete({
            roleKey: record.roleKey,
          })
            .then((res) => {
              if (res.data.code == 200) {
                that.$message.success(res.data.message);
                that.getList();
              } else {
                that.$message.error(res.data.message);
              }
            })
            .catch((error) => {
              that.$message.error(this.CommonConst.errorMsg);
            });
        },
        onCancel() {},
      });
    },
    edit(flag, record) {
      if (flag == "add") {
        this.$router.push({
          path: "/company/permissions",
          query: {
            flag: flag,
            //roleType:"2",
          },
        });
      } else {
        this.$router.push({
          path: "/company/permissions",
          query: {
            roleKey: record.roleKey,
            roleDesc: record.roleDesc,
            roleType: record.roleType,
            flag: flag,
          },
        });
      }
    },
    onChange(checkedValues) {
      console.log("checked = ", checkedValues);
    },
    handleOk() {},
    handleCancel() {
      this.$router.push({
        path: "/company/staffmanagement",
      });
    },
    returnBack(){
        this.$router.push({
        path: "/company/staffmanagement",
      });
    }
  },
};
</script>
<style lang='scss' scoped>
.marginStyle {
  margin: 10px 0px;
}
.permissionlist {
  width: 94%;
  margin: 40px auto;
  overflow: hidden;
}
.leftTable {
  width: 40%;
  float: left;
}
.rightBtn {
   float: left;
   margin: 15px;
    left: 16px;
    position: relative;
  button {
    padding: 0px 20px;
  }
}
</style>

