<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>搜索:</p>
      <a-input placeholder="请输入" v-model="searchData.key" />
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 查询 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="apply" @click="exportData()">
          导出
        </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="scoreList"
        :pagination="false"
        :rowKey="(record) => record.traineeNo"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div @click="gotoDetail(record, 0)" class="detaills">查看详情</div>
        </template>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js"
import Helper from "@/utils/helper"
import config from "@/config"
export default {
  name: "scorelistgement",
  data () {
    return {
      config: config,
      myList: [],
      // 选中的值
      selectedRowKeys: [],
      queryObj: {},
      driverSchoolNo: "",
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        key: "",
      },
      scoreList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "学生姓名",
          dataIndex: "traineeName",
          key: "traineeName",
          width: 100,
        },
        { title: "学员编号", dataIndex: "traineeNo", key: "traineeNo", width: 100 },
        {
          title: "科目二最新得分",
          dataIndex: "exam2Score",
          key: "exam2Score",
          width: 100,
        },
        {
          title: "科目三最新得分",
          dataIndex: "exam3Score",
          key: "exam3Score",
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
    }
  },
  components: {

  },
  mounted () {
    this.getList()
  },
  created () {
    this.queryObj = this.$route.query
  },
  methods: {
    reset () {
      this.searchData.key = ""
      this.pageObject.pageNum = 1
      this.getList()
    },
    search () {
      this.pageObject.pageNum = 1
      this.getList()
    },
    handleSelectChange (value) {
      console.log(value)
    },
    backInit () {
      this.pageObject.pageNum = 1
      this.getList()
    },
    //导出
    exportData () {
      let arr = this.selectedRowKeys
      console.log(arr)
      let sss = []

      this.myList.forEach(t => {
        if (this.selectedRowKeys.indexOf(t.traineeNo) >= 0) {
          sss.push(t)
        }
      })
      console.log(sss)
      ApiConfig.exportScoreList(
        sss
      )
        .then((res) => {
          if (res.data.code == 200) {
            //
            if (res.data.data.filepath != null) {
              const file_path = res.data.data.filepath
              console.log(file_path)
              let link = document.createElement("a")
              // let url = this.config.imgURL + file_path;
              console.log(this.config.imgURL + file_path)
              fetch(this.config.imgURL + file_path)
                .then((res) => res.blob())
                .then((blob) => {
                  link.href = URL.createObjectURL(blob)
                  console.log(link.href)
                  link.download = ""
                  document.body.appendChild(link)
                  link.click()
                })
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg)
        })
    },
    getList () {
      //有参数传过来
      this.driverSchoolNo = Helper.get("loginInfo").belongNo //归属驾校

      //驾校获取员工列表
      ApiConfig.scoreList({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        key: this.searchData.key,
        driverSchoolNo: this.driverSchoolNo//Helper.get("loginInfo").belongNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].key = createTimeText;
            // }

            this.scoreList = list
            this.myList = list
            this.pageObject.total = res.data.data.total
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg)
        })
    },
    //点击分页操作
    onChangePage (page, pageSize) {
      this.pageObject.pageNum = page
      this.pageObject.pageSize = pageSize
      this.getList()
    },


    //驾校员工状态变更
    gotoDetail (item) {
      console.log('挑选的值', item)
      this.$router.push({
        path: "/driverSchool/exercisescoremanagementDetaill",
        query: {
          traineeNo: item.traineeNo,
          traineeName: item.traineeName
        },
      })



    },
    onSelectChange (selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      top: 10px;
      width: 60px;
      position: relative;
      cursor: pointer;
    }
    .viewTwo {
      color: #0e9a68;
      top: -11px;
      position: relative;
      width: 30px;
      left: 100px;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 150px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 16px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
.detaills {
  cursor: pointer;
  color: #0e9a68;
}
</style>