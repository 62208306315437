/*
 * @Author: your name
 * @Date: 2021-01-05 09:34:04
 * @LastEditTime: 2021-11-26 09:30:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-driver-web\src\config\index.js
 */
//gateway 后续有nginx反向代理
//var baseUrl = 'http://192.168.0.158:10003';//       http://vrcar.molspace.com.cn/backmanger/
var baseUrl = 'http://vrcar.molspace.com.cn/backmanger/';// 
export default {
    // serverURI: 'http://120.24.42.58:8098',
    serverURI: baseUrl,
    //imgURL: `http://192.168.0.158:12000` //图片地址，末尾不需要/ //http://192.168.0.158:12000  http://vrcar.molspace.com.cn/file
    imgURL: `http://vrcar.molspace.com.cn/file`
}