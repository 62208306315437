<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="showmodal()">
          新增
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="save()"> 保存 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="restDateList"
        :pagination="false"
        :rowKey="record => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="del(record)">删除</div>
        </template>
      </a-table>
    </div>
    <RestDateAdd ref="ref_addform" @backInit="backInit" />
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import RestDateAdd from "./RestDateAdd";

export default {
  name: "restdatemanagement",
  data() {
    return {
      queryObj: {},
      isExistsValid: "0",
      restDateList: [],
      currList: [],
      driverSchoolNo: "",
      beginWeekDay: "",
      endWeekDay: "",
      enableBeginDate: "",
      restDateArray: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70
        },
        {
          title: "周开始",
          dataIndex: "beginWeekDay",
          key: "beginWeekDay",
          width: 200
        },
        {
          title: "周结束",
          dataIndex: "endWeekDay",
          key: "endWeekDay",
          width: 200
        },
        {
          title: "休息日期",
          dataIndex: "restDate",
          key: "restDate",
          width: 200
        },
        {
          title: "生效开始日期",
          dataIndex: "enableBeginDate",
          key: "enableBeginDate",
          width: 150
        },
        {
          title: "生效结束日期",
          dataIndex: "enableEndDate",
          key: "enableEndDate",
          width: 150
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  components: {
    RestDateAdd
  },
  mounted() {
    //this.getList();
  },
  created() {
    this.queryObj = this.$route.query;
  },
  methods: {
    del(item) {
      console.log(item.id);
      for (var i = 0; i < this.currList.length; i++) {
        if (this.currList[i].id == item.id) {
          //item.id==122
          this.currList.splice(i, 1);
        }
      }
      this.restDateList = this.currList;
    },
    reset() {
      //this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    //
    backInit(objTmpRestDate, callback) {
      if (objTmpRestDate != null) {
        var size = this.currList.length;
        objTmpRestDate.id = size + 1;
        objTmpRestDate.driverSchoolNo = Helper.get("loginInfo").belongNo;
        //遍历检查是否有时间交集
        for (var i = 0; i < this.currList.length; i++) {
          var c = this.currList[i];
          //判断休息日是否重复
          if (
            objTmpRestDate.restDate != null &&
            objTmpRestDate.restDate != "" &&
            objTmpRestDate.restDate == c.restDate
          ) {
            this.$message.error("休息日已经设定");
            callback(false);
            return;
          }
          //判断周是否一致
          if (
            objTmpRestDate.beginWeekDay != c.beginWeekDay ||
            objTmpRestDate.endWeekDay != c.endWeekDay
          ) {
            this.$message.error("周数据需要一致");
            callback(false);
            return;
          }
          //判断生效日期是否一致
          if (objTmpRestDate.enableBeginDate != c.enableBeginDate) {
            this.$message.error("生效日期需要一致");
            callback(false);
            return;
          }
        }
        //没有冲突
        this.currList.push(objTmpRestDate);
        this.restDateList = this.currList;
        //this.getList();
        //改用表单方式传递，参数方式改变
        this.driverSchoolNo = objTmpRestDate.driverSchoolNo;
        this.beginWeekDay = objTmpRestDate.beginWeekDay;
        this.endWeekDay = objTmpRestDate.endWeekDay;
        this.enableBeginDate = objTmpRestDate.enableBeginDate;
        this.restDateArray.push(objTmpRestDate.restDate);
      }
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    save() {
      //保存休息日信息
      ApiConfig.restDateSave({
        driverSchoolNo: this.driverSchoolNo,
        beginWeekDay: this.beginWeekDay,
        endWeekDay: this.endWeekDay,
        enableBeginDate: this.enableBeginDate,
        restDateArray: this.restDateArray //数组
      }) //原先采用json this.currList
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("保存成功");
            //跳转页面
            this.$router.push({
              path: "/driverschool/restdatemanagement"
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //弹出新增
    showmodal(flag) {
      this.isExistsValid = this.queryObj.isExistsValid;
      this.$refs["ref_addform"].init(flag, this.isExistsValid); // this.$refs触发一个组件里的方法
    },
    updateList() {}
  }
};
</script>
<style lang="scss" scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
