<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>驾校:</p>
      <a-select
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body;
          }
        "
        :dropdownStyle="{ overflow: 'auto' }"
        v-model="searchData.driverSchoolNo"
      >
        <a-select-option
          :value="item.driverSchoolNo"
          v-for="item in schoolList"
          :key="item.driverSchoolNo"
        >
          {{ item.driverSchoolName }}
        </a-select-option>
      </a-select>
      <p>结算时间:</p>
      <a-range-picker
        :getCalendarContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body;
          }
        "
        :dropdownStyle="{ overflow: 'auto' }"
        v-model="searchData.beginEndPaymentDateList"
        format="YYYY-MM-DD"
      />

      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="schoolRemitRecieveList"
        :pagination="false"
        :rowKey="(record, index) => index + 1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="showDetail(record)">查看</div>
        </template>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import moment from 'moment';
export default {
  name: "schoolProfitRecieve", //给驾校打款分成
  data() {
    return {
      queryObj: {},
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        driverSchoolNo: "",
        beginEndPaymentDateList: [],
      },
      schoolList: [],
      schoolRemitRecieveList: [],
      beginPaymentDate: "",
      endPaymentDate: "",
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "驾校名称",
          dataIndex: "driverSchoolName",
          key: "driverSchoolName",
          width: 100,
        },
        {
          title: "最后结算日期",
          dataIndex: "endPaymentDate",
          key: "endPaymentDate",
          width: 100,
        },
        {
          title: "已打款金额",
          dataIndex: "schoolRemitTotalAmt",
          key: "schoolRemitTotalAmt",
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getSchoolList();
    this.getList();
  },
  created() {},
  methods: {
    reset() {
      this.searchData.driverSchoolNo = "";
      this.searchData.beginEndPaymentDateList = [];
      this.beginPaymentDate = "";
      this.endPaymentDate = "";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.getList();
    },
    //获得列表
    getSchoolList() {
      ApiConfig.getSchoolList({})
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.schoolList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getList() {
      //用户如果选择，显示选择的区间信息
      if (this.searchData.beginEndPaymentDateList != null &&
        this.searchData.beginEndPaymentDateList[0] != null) {
        console.log(this.searchData.beginEndPaymentDateList[0]._d);
        this.beginPaymentDate = Helper.dateFormat(
          "YYYY-mm-dd",
          this.searchData.beginEndPaymentDateList[0]._d
        );
        this.endPaymentDate = Helper.dateFormat(
          "YYYY-mm-dd",
          this.searchData.beginEndPaymentDateList[1]._d
        );
      } else {
        //设定一个默认值
        var currentDate = new Date();
        this.endPaymentDate = Helper.dateFormat(
          "YYYY-mm-dd",
          currentDate
        );
        //
        var currentCopyDate=new Date();
        currentCopyDate.setMonth(currentCopyDate.getMonth()-1);
        this.beginPaymentDate = Helper.dateFormat(
          "YYYY-mm-dd",
          currentCopyDate
        );
        //初始化没有日期限制，则设定默认值
        this.searchData.beginEndPaymentDateList= [moment(currentCopyDate, 'YYYY-MM-DD'), moment(currentDate, 'YYYY-MM-DD')];
        //this.searchData.beginEndPaymentDateList[0]=currentDate;
      }
      console.log(this.beginPaymentDate+"====="+this.endPaymentDate);

      //给驾校汇总打款记录
      ApiConfig.schoolRemitReceiveSumaryList({
        driverSchoolNo: this.searchData.driverSchoolNo,
        beginPaymentDate: this.beginPaymentDate,
        endPaymentDate: this.endPaymentDate,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }

            this.schoolRemitRecieveList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    showDetail(item) {
      this.$router.push({
        path: "/company/schoolremitdetail",
        query: {
          driverSchoolNo: item.driverSchoolNo,
        },
      });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },

    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 30px;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      margin-right: 6px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    .ant-calendar-picker {
      float: left;
      width: 240px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 14px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>