/*
 * @Author: your name
 * @Date: 2021-01-05 09:34:04
 * @LastEditTime: 2021-11-25 13:56:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-driver-web\src\api\index.js
 */
import axios from '../utils/facth'
import qs from 'qs'
// import config from '@/config'
var config = {
    //网关对应的端口
    //serverURI: "http://192.168.0.158:10003"   //     http://vrcar.molspace.com.cn/backmanger/ 
    serverURI: "http://vrcar.molspace.com.cn/backmanger/"
}


class API {
    get(uri, data = null) {
        if (!uri) {
            console.error('API function call requires url argument')
            return
        }
        const url = config.serverURI + uri
        return axios({
            method: 'get',
            url,
            params: data
        });
    }
    post(uri, data = null) {
        if (!uri) {
            console.error('API function call requires url argument')
            return
        }
        const url = config.serverURI + uri
        //axios.defaults.headers.post['Content-Type'] = 'application/json';
        // return axios({
        //     method: 'post',
        //     url,
        //     data
        // });
        //采用x-www-form-urlencoded提交数据
        return axios({
            url:url,
            method:'post',
            data:qs.stringify(data,{indices:false}),
            // trainsformRequest:[function(data){
            //     let ret=''
            //     for(let it in data){
            //         ret+=encodeURIComponent(it)+'='+encodeURIComponent(data[it])+'&'
            //     }
            //     return ret
            // }],
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            }
        });
    }

    postByJson(uri, data = null) {
        if (!uri) {
            console.error('API function call requires url argument')
            return
        }
        const url = config.serverURI + uri
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        return axios({
            method: 'post',
            url,
            data
        });
    }

    put(uri, data = null) {
        if (!uri) {
            console.error('API function call requires url argument')
            return
        }
        const url = config.serverURI + uri
        return axios({
            method: 'put',
            url,
            data
        });
    }

    delete(uri, data = null) {
        if (!uri) {
            console.error('API function call requires url argument')
            return
        }
        const url = config.serverURI + uri
        return axios({
            method: 'delete',
            url,
            data
        });
    }

}

export default new API()