<template>
  <div id="app">
    <a-layout class="components-layout">
      <a-layout-header class="header">
        <div class="userOper">
          <a-dropdown class="dropdownBox">
            <div class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <img
                src="https://img.houtaii.com/img/woman.png?x-oss-process=style/750"
                class="userHeader"
              />
              <span class="userName">{{ this.loginInfo.name }}</span>
            </div>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="outLogin">退出登录</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider
          width="200"
          style="background: #fff; overflow-y: auto; overflow-x: hidden"
        >
          <a-menu
            mode="inline"
            :default-open-keys="openkeys"
            :default-selected-keys="selectedkeys"
            :style="{ height: '100%', borderRight: 0 }"
          >
            <a-sub-menu v-for="item in routerList" :key="item.routerUrl">
              <span slot="title">
                <icon-font :type="item.icon" :style="{ fontSize: '17px' }" />
                {{ item.title }}
              </span>
              <a-menu-item
                v-for="obj in item.children"
                :key="obj.routerUrl"
                style="margin-right: 20px"
              >
                <router-link :to="`/${item.routerUrl}/${obj.routerUrl}`">{{
                  obj.title
                }}</router-link>
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 0px">
          <a-layout-content>
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Helper from "@/utils/helper";
import ApiConfig from "@/api/apiConfig.js";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2218306_7hvw4xr2162.js",
});
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      collapsed: false,
      loginInfo: {},
      openkeys: [],
      selectedkeys: [],
      routerList: [],
      // routerList: [
      //   {
      //     title: "学生端",
      //     routerUrl: "student",
      //     icon: "icon-boshihouchuzhanshenqing",
      //     children: [
      //       {
      //         title: "课程列表",
      //         routerUrl: "courseList",
      //       },
      //     ],
      //   },
      //   {
      //     title: "教师端",
      //     routerUrl: "teacher",
      //     icon: "icon-xuexiao_jiaoshi",
      //     children: [
      //       {
      //         title: "课程管理",
      //         routerUrl: "coursemanagement",
      //       },
      //       {
      //         title: "实验管理",
      //         routerUrl: "experimentmanagement",
      //       },
      //       {
      //         title: "批改作业",
      //         routerUrl: "homeworkcorrecting",
      //       },
      //       {
      //         title: "数据统计",
      //         routerUrl: "datastatistics",
      //       },
      //     ],
      //   },
      //   {
      //     title: "校外人员",
      //     routerUrl: "schoolpersonnel",
      //     icon: "icon-ren",
      //     children: [
      //       {
      //         title: "流水单",
      //         routerUrl: "flowsheetf",
      //       },
      //       {
      //         title: "提现列表",
      //         routerUrl: "withdrawal",
      //       },
      //       {
      //         title: "实验列表",
      //         routerUrl: "listofexperiments",
      //       },
      //     ],
      //   },
      //   {
      //     title: "教务人员",
      //     routerUrl: "edupersonnel",
      //     icon: "icon-w_laoshi",
      //     children: [
      //       {
      //         title: "实验库",
      //         routerUrl: "theLibrary",
      //       },
      //       {
      //         title: "开课申请",
      //         routerUrl: "courseapplication",
      //       },
      //       {
      //         title: "开课审核",
      //         routerUrl: "courseopeningaudit",
      //       },
      //       {
      //         title: "实验审核",
      //         routerUrl: "experimentalreview",
      //       },
      //       {
      //         title: "平台实收",
      //         routerUrl: "platformadministration",
      //       },
      //       {
      //         title: "消费统计",
      //         routerUrl: "consumptionstatistics",
      //       },
      //       {
      //         title: "提现审核",
      //         routerUrl: "withdrawalaudit",
      //       },
      //       {
      //         title: "校外人员管理",
      //         routerUrl: "personnelmanagement",
      //       },
      //     ],
      //   },
      //   {
      //     title: "系统管理",
      //     routerUrl: "systemmanagement",
      //     icon: "icon-shezhi",
      //     children: [
      //       {
      //         title: "权限",
      //         routerUrl: "permissionlist",
      //       },
      //       {
      //         title: "老师管理",
      //         routerUrl: "teachermanagement",
      //       },
      //       {
      //         title: "学生管理",
      //         routerUrl: "studentmanagement",
      //       },
      //       {
      //         title: "专业管理",
      //         routerUrl: "professionalmanagement",
      //       },
      //     ],
      //   },
      //   {
      //     title: "个人中心",
      //     routerUrl: "personalcenter",
      //     icon: "icon-gerenzhongxin",
      //     children: [
      //       {
      //         title: "基本设置",
      //         routerUrl: "basicsettings",
      //       },
      //     ],
      //   },
      // ],
    };
  },
  mounted() {
    this.loginInfo = Helper.get("loginInfo");
    // if(JSON.stringify(this.loginInfo) == "{}") {
    //     this.isLogin = false
    // } else {
    //     this.isLogin = true
    // }

    // var urlSplit = this.$route.path.substr(1).split("/")
    //   this.openkeys.push(urlSplit[0])
    //   this.selectedkeys.push(urlSplit[1])

    if (Helper.get("routerList")) {
      this.routerList = Helper.get("routerList");
    } else {
      this.getRouter();
    }
  },
  methods: {
    getRouter() {
      ApiConfig.getRouterInfo({
        account: this.loginInfo.account,
        account: Helper.get("loginInfo").account,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.routerList = res.data.data;
            Helper.set({
              routerList: JSON.stringify(this.routerList),
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    collapse() {},
    outLogin() {
      Helper.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang='scss' >
body,
html {
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  color: #333;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ant-layout-content {
  background: #fff;
  // padding: 24px;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  zoom: 1;
}
.components-layout {
  width: 100%;
  height: 100%;
  .logo {
    color: #ffffff;
    font-size: 18px;
    margin: 0px 0px 0px 0px;
    float: left;
  }
  .userOper {
    float: right;
    color: #ffffff;
    margin: 0px 0px 0px 0px;
  }
}
.ant-layout {
  background: #ffffff;
}
.ant-layout-header {
  background: #0e9a68;
}
.ant-layout-sider {
  box-shadow: #ccc 1px 1px 10px 1px; //边框阴影
}
.ant-breadcrumb {
  padding: 20px 0 20px 20px;
  border-bottom: solid 1px rgb(235, 231, 231);
}
.userOper {
  .dropdownBox {
    height: 60px;
  }
  .ant-dropdown-link {
    color: #ffffff;
    .userHeader {
      width: 25px;
      height: 25px;
      float: left;
      margin-top: 20px;
    }
    .userName {
      float: left;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      margin: 20px 0px 0px 10px;
    }
  }
}
.ant-menu-submenu-title {
  color: #000;
}
.ant-menu-item > a {
  color: #000;
}
.ant-menu-item > a:hover,
.ant-menu-item-selected > a,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-selected:hover,
.ant-menu-submenu-active:hover,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  color: #0e9a68;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: #0e9a68 3px solid;
}
.footerCon {
  text-align: center;
}
</style>





