import api from '@/api'
import urls from '@/urls'

class ApiConfig {
    ///////////////////用户管理/////////////////
    //注册
    reg (data) {
        return api.post(urls.reg, data)
    }
    //发送短信验证码
    sendSmsCode (data) {
        return api.post(urls.sendSmsCode, data)
    }
    //登陆
    login (data) {
        return api.post(urls.login, data)
    }
    sendEmailCode (data) {
        return api.post(urls.sendEmailCode, data)
    }
    //重置密码
    setpwd (data) {
        return api.post(urls.setpwd, data)
    }
    //用户角色权限
    user_roles_resources (data) {
        return api.post(urls.user_roles_resources, data)
    }
    //用户路由信息
    getRouterInfo (data) {
        return api.post(urls.getRouterInfo, data)
    }
    //添加角色
    roleAdd (data) {
        return api.post(urls.roleAdd, data)
    }
    //权限列表
    resourcesList (data) {
        return api.post(urls.resourcesList, data)
    }
    //添加角色以及角色对应的权限
    addByResources (data) {
        return api.post(urls.addByResources, data)
    }
    roleList (data) {
        return api.post(urls.roleList, data)
    }
    roleDelete (data) {
        return api.post(urls.roleDelete, data)
    }

    roleQuery (data) {
        return api.post(urls.roleQuery, data)
    }
    //角色配置
    roleConfig (data) {
        return api.post(urls.roleConfig, data)
    }
    usersendEmailCode (data) {
        return api.post(urls.usersendEmailCode, data)
    }
    modifyPwd (data) {
        return api.post(urls.modifyPwd, data)
    }
    forgetPwd (data) {
        return api.post(urls.forgetPwd, data)
    }

    //////////////////驾校/////////////////////////////
    //将学员与驾校进行绑定
    traineeBindSchool (data) {
        return api.post(urls.traineeBindSchool, data)
    }
    //学员列表
    traineeListByDS (data) {
        return api.post(urls.traineeListByDS, data)
    }
    //学员详情
    traineeDetail (data) {
        return api.post(urls.traineeDetail, data)
    }
    //学员预定VR设备列表
    traineeBookList (data) {
        return api.post(urls.traineeBookList, data)
    }
    //学员券使用情况
    couponInfoList (data) {
        return api.post(urls.couponInfoList, data)
    }
    //驾校给学员充值
    traineeRechageAdd (data) {
        return api.post(urls.traineeRechageAdd, data)
    }
    //根据订单号获取二维码
    getSchoolPayQRCodeInfo (data) {
        return api.post(urls.getSchoolPayQRCodeInfo, data)
    }

    //获取驾校给学员支付的结果数据
    traineePayResultInfo (data) {
        return api.post(urls.traineePayResultInfo, data)
    }
    getProList (data) {
        return api.post(urls.getProList, data)
    }
    //学员预新增
    traineePreAdd (data) {
        return api.post(urls.traineePreAdd, data)
    }
    //学员批量预导入
    traineePreBatchAdd (data) {
        return api.post(urls.traineePreBatchAdd, data)
    }
    //查看预导入的结果
    traineePreAddListByDS (data) {
        return api.post(urls.traineePreAddListByDS, data)
    }
    //获取预导入对应的订单信息
    traineePreAddOrderDS (data) {
        return api.post(urls.traineePreAddOrderDS, data)
    }
    //将预导入的数据导入到正式数据库
    traineePreAddImportByDS (data) {
        return api.post(urls.traineePreAddImportByDS, data)
    }
    //获取券使用情况
    getCouponInfoBySchool (data) {
        return api.post(urls.getCouponInfoBySchool, data)
    }
    //获得单个券所有完整的信息
    getCouponOrderMoreInfo (data) {
        return api.post(urls.getCouponOrderMoreInfo, data)
    }
    //计算驾校给学员充值练车券时费用
    calDriverSchoolPayByCouponCount (data) {
        return api.post(urls.calDriverSchoolPayByCouponCount, data)
    }
    checkTraineeMobile (data) {
        return api.post(urls.checkTraineeMobile, data)
    }
    //驾校充值付款
    driverSchoolPay (data) {
        return api.post(urls.driverSchoolPay, data)
    }
    //驾校收入列表
    driverSchoolIncomeList (data) {
        return api.post(urls.driverSchoolIncomeList, data)
    }
    //驾校支出列表
    driverSchoolExpenseList (data) {
        return api.post(urls.driverSchoolExpenseList, data)
    }
    driverSchoolRefundList (data) {
        return api.post(urls.driverSchoolRefundList, data)
    }
    //保存退款申请
    saveRefundApply (data) {
        return api.post(urls.saveRefundApply, data)
    }
    //驾校设备当天使用列表
    driverSchoolDeviceTodayUseList (data) {
        return api.post(urls.driverSchoolDeviceTodayUseList, data)
    }
    //驾校单个设备当天所有预约
    driverSchoolOneDeviceTodayBookList (data) {
        return api.post(urls.driverSchoolOneDeviceTodayBookList, data)
    }
    //驾校分成到账
    driverSchoolProfitRecieveList (data) {
        return api.post(urls.driverSchoolProfitRecieveList, data)
    }
    //驾校预约时间信息
    driverSchoolBookTimeZoneList (data) {
        return api.post(urls.driverSchoolBookTimeZoneList, data)
    }
    //驾校预约时间后续生效的
    driverSchoolBookTimeZoneFutureList (data) {
        return api.post(urls.driverSchoolBookTimeZoneFutureList, data)
    }
    driverSchoolRestDateList (data) {
        return api.post(urls.driverSchoolRestDateList, data)
    }
    driverSchoolRestDateFutureList (data) {
        return api.post(urls.driverSchoolRestDateFutureList, data)
    }
    restDateSave (data) {
        return api.post(urls.restDateSave, data)
    }
    //驾校时间区间保存
    timeZoneSave (data) {
        return api.post(urls.timeZoneSave, data)
    }
    //驾校预约时间信息修改或新增
    driverSchoolBookTimeZoneSave (data) {
        return api.post(urls.driverSchoolBookTimeZoneSave, data)
    }
    //驾校员工列表
    driverSchoolStaffList (data) {
        return api.post(urls.driverSchoolStaffList, data)
    }
    //驾校员工角色列表
    schoolStaffRoleList (data) {
        return api.post(urls.schoolStaffRoleList, data)
    }
    //驾校员工保存(配置角色)
    schoolStaffSaveByRole (data) {
        return api.post(urls.schoolStaffSaveByRole, data)
    }
    //驾校员工状态禁用启用
    driverSchoolStaffStatusSave (data) {
        return api.post(urls.driverSchoolStaffStatusSave, data)
    }
    //驾校基本信息
    driverSchoolInfo (data) {
        return api.post(urls.driverSchoolInfo, data)
    }
    //驾校上传封面图片
    driverSchoolUploadCoverImg (data) {
        return api.post(urls.driverSchoolUploadCoverImg, data)
    }
    //驾校上传简介图片
    driverSchoolUploadDescImg (data) {
        return api.post(urls.driverSchoolUploadDescImg, data)
    }
    //驾校修改信息
    driverSchoolInfoSave (data) {
        return api.post(urls.driverSchoolInfoSave, data)
    }
    //下载模板
    downloadTemplate (data) {
        return api.post(urls.downloadTemplate, data)
    }
    scoreList (data) {
        return api.post(urls.scoreList, data)
    }
    exportScoreList (data) {
        return api.postByJson(urls.exportScoreList, data)
    }
    // 学生成绩详情trainee-exercise-result/getListExerBySchoolForWeb
    getListExerBySchoolForWeb (data) {
        return api.post(urls.getListExerBySchoolForWeb, data)
    }
    ////////////////////公司端//////////////////////////
    //学员列表
    traineeListByCompany (data) {
        return api.post(urls.traineeListByCompany, data)
    }
    //学员详情
    // traineeDetail(data) {
    //     return api.post(urls.traineeDetail, data)
    // }
    //公司员工列表
    companyStaffList (data) {
        return api.post(urls.companyStaffList, data)
    }
    //公司员工角色
    companyStaffRoleList (data) {
        return api.post(urls.companyStaffRoleList, data)
    }
    //公司员工保存账号（权限）
    companyStaffSaveByRole (data) {
        return api.post(urls.companyStaffSaveByRole, data)
    }
    userdetail (data) {
        return api.post(urls.userdetail, data)
    }
    //公司员工状态（停用，启用）
    companyStaffStatusSave (data) {
        return api.post(urls.companyStaffStatusSave, data)
    }
    //驾校充值统计列表
    driverSchoolRechargeList (data) {
        return api.post(urls.driverSchoolRechargeList, data)
    }
    //驾校充值统计明细
    driverSchoolRechargeDetail (data) {
        return api.post(urls.driverSchoolRechargeDetail, data)
    }
    //学员充值统计列表
    traineeRechargeList (data) {
        return api.post(urls.traineeRechargeList, data)
    }
    //学员充值统计明细
    traineeRechargeDetail (data) {
        return api.post(urls.traineeRechargeDetail, data)
    }
    //驾校列表
    driverSchoolList (data) {
        return api.post(urls.driverSchoolList, data)
    }
    //驾校保存
    driverSchoolSave (data) {
        return api.post(urls.driverSchoolSave, data)
    }
    getProvinceAndCityList (data) {
        return api.post(urls.getProvinceAndCityList, data)
    }
    getAgentList (data) {
        return api.post(urls.getAgentList, data)
    }
    //驾校修改
    driverSchoolModify (data) {
        return api.post(urls.driverSchoolModify, data)
    }
    //获得驾校详情
    getSchoolDetail (data) {
        return api.post(urls.getSchoolDetail, data)
    }
    getProfitList (data) {
        return api.post(urls.getProfitList, data)
    }
    //保存分成信息
    saveProfit (data) {
        return api.post(urls.saveProfit, data)
    }
    //驾校状态（停用、启用）
    driverSchoolStatusSave (data) {
        return api.post(urls.driverSchoolStatusSave, data)
    }
    //驾校用户账号列表
    // driverSchoolStaffList(data) {
    //     return api.post(urls.driverSchoolStaffList, data)
    // }
    //新增驾校用户以及权限密码
    driverSchoolStaffSave (data) {
        return api.post(urls.driverSchoolStaffSave, data)
    }
    //修改驾校用户权限以及密码
    // driverSchoolStaffSave(data) {
    //     return api.post(urls.driverSchoolStaffSave, data)
    // }
    //修改驾校用户状态（启用、停用）
    // driverSchoolStaffStatusSave(data) {
    //     return api.post(urls.driverSchoolStaffStatusSave, data)
    // }
    //代理列表
    agentList (data) {
        return api.post(urls.agentList, data)
    }
    getAgentDetail (data) {
        return api.post(urls.getAgentDetail, data)
    }
    //代理新增
    agentSave (data) {
        return api.post(urls.agentSave, data)
    }
    //代理修改
    // agentSave(data) {
    //     return api.post(urls.agentSave, data)
    // }
    //代理状态（停用，启用）
    agentStatusSave (data) {
        return api.post(urls.agentStatusSave, data)
    }
    //代理用户账号列表
    agentStaffList (data) {
        return api.post(urls.agentStaffList, data)
    }
    //新增代理用户以及权限密码
    agentStaffSave (data) {
        return api.post(urls.agentStaffSave, data)
    }
    //修改代理用户权限以及密码
    // agentStaffSave(data) {
    //     return api.post(urls.agentStaffSave, data)
    // }
    //修改代理用户状态（启用、停用）
    agentStaffStatusSave (data) {
        return api.post(urls.agentStaffStatusSave, data)
    }
    //获得代理列表
    // getAgentList(data){
    //     return api.post(urls.getAgentList, data)
    // }
    //获得驾校列表
    getSchoolList (data) {
        return api.post(urls.getSchoolList, data)
    }
    //给代理打款的汇总数据
    agentRemitReceiveSumaryList (data) {
        return api.post(urls.agentRemitReceiveSumaryList, data)
    }
    agentRemitReceiveList (data) {
        return api.post(urls.agentRemitReceiveList, data)
    }
    //给驾校打款的汇总数据
    schoolRemitReceiveSumaryList (data) {
        return api.post(urls.schoolRemitReceiveSumaryList, data)
    }
    schoolRemitReceiveList (data) {
        return api.post(urls.schoolRemitReceiveList, data)
    }
    //计算给驾校打款的数据
    schoolCalRemitByPayment (data) {
        return api.post(urls.schoolCalRemitByPayment, data)
    }
    agentCalRemitByPayment (data) {
        return api.post(urls.agentCalRemitByPayment, data)
    }
    uploadRemitProofImg (data) {
        return api.post(urls.uploadRemitProofImg, data)
    }
    //保存给驾校打款信息
    saveSchoolRemitInfo (data) {
        return api.post(urls.saveSchoolRemitInfo, data)
    }
    saveAgentRemitInfo (data) {
        return api.post(urls.saveAgentRemitInfo, data)
    }
    //退款审核
    refundAudit (data) {
        return api.post(urls.refundAudit, data)
    }
    //设备软件版本信息
    deviceSoftwareVersionList (data) {
        return api.post(urls.deviceSoftwareVersionList, data)
    }
    getDriverSchoolListByPro (data) {
        return api.post(urls.getDriverSchoolListByPro, data)
    }
    deviceVersionSave (data) {
        return api.post(urls.deviceVersionSave, data)
    }
    deviceVersionUpdate (data) {
        return api.post(urls.deviceVersionUpdate, data)
    }
    getDeviceVersion (data) {
        return api.post(urls.getDeviceVersion, data)
    }
    driverSchoolDeviceList (data) {
        return api.post(urls.getDeviceList, data)
    }
    deviceSave (data) {
        return api.post(urls.deviceSave, data)
    }
    deviceStatusSave(data) {
        return api.post(urls.deviceStatusSave, data)
    }
    changeTraineeBookDevice(data){
        return api.post(urls.changeTraineeBookDevice, data)
    }
    complaintList(data){
        return api.post(urls.complaintList, data)
    }

    ////////////////////////////代理端////////////////////////////
    //收益列表
    agentIncomeList (data) {
        return api.post(urls.agentIncomeList, data)
    }
    //收益详情
    agentIncomeDetail (data) {
        return api.post(urls.agentIncomeDetail, data)
    }
    //代理分成到账
    agentProfitRecieveList (data) {
        return api.post(urls.agentProfitRecieveList, data)
    }
    //代理员工列表
    // agentStaffList(data) {
    //     return api.post(urls.agentStaffList, data)
    // }
    //代理员工角色列表
    agentStaffRoleList (data) {
        return api.post(urls.agentStaffRoleList, data)
    }
    //新增代理用户以及权限密码
    agentStaffSaveByRole (data) {
        return api.post(urls.agentStaffSaveByRole, data)
    }
    //获取代理下驾校
    getSchoolListByAgent (data) {
        return api.post(urls.getSchoolListByAgent, data)
    }

    //修改代理用户状态（启用、停用）
    // agentStaffStatusSave(data) {
    //     return api.post(urls.agentStaffStatusSave, data)
    // }
    ////////////////////////////////////////////////////////////
    //监管部门查看学员信息
    traineeListBySupervision (data) {
        return api.post(urls.traineeListBySupervision, data)
    }
    driverschoolListBySupervision (data) {
        return api.post(urls.driverschoolListBySupervision, data)
    }



    ////////////////////////////////////////////////////////////

    //获取城市列表
    cityList (data) {
        return api.get(urls.cityList, data)
    }
}

export default new ApiConfig()