const pageSize = {
    start: 1,
    end: 10
};

const useFlag = [{
        text: "正常",
        value: "1",
    },
    {
        text: "失效",
        value: "2"
    }
]

const errorMsg = "服务异常,请联系后台管理人员..........."

const listStatus = [{
        label: "全部",
        code: ""
    },
    {
        label: "待审",
        code: "0"
    },
    {
        label: "审核通过",
        code: "1"
    },
    {
        label: "审核失败",
        code: "-1"
    }
]
const listisHasCheck = [{
        label: "全部",
        code: ""
    },
    {
        label: "未批改",
        code: "0"
    },
    {
        label: "已批改",
        code: "1"
    }
]
export default {
    pageSize,
    useFlag,
    errorMsg,
    listStatus,
    listisHasCheck
};