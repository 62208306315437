<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>练车驾校:</p>
      <a-select 
       :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
  
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.driverSchoolNo">
          <a-select-option :value="item.driverSchoolNo"
            v-for="item in driverSchoolList"
            :key="item.driverSchoolNo">
            {{ item.driverSchoolName }}
          </a-select-option>
        </a-select>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="showPayment"> 新增 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="agentRemitRecieveList"
        :pagination="false"
        :rowKey="(record,index) =>index+1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="proofInfoUrl" slot-scope="text, record">
          <!-- <img :id ="record.proofInfoUrl" :src="config.imgURL+record.proofInfoUrl" 
          @click="showImg(record)" 
          width="30px" height="30px"/> -->
          <!--show zoom img-->
          <viewer :images="record.imgList">
            <img v-for="src in record.imgList" :src="src" :key="src"  width="30px" height="30px" alt=""/>
          </viewer>
        </span>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>

    <a-modal v-model="visibleChoosePayment" title="账期选择" :maskClosable="false">
      <a-form-model
        ref="paymentForm"
        :model="paymentForm"
        :rules="paymentRules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
         v-if="visibleChoosePayment"
      >
        <a-form-model-item style="width:800px"
            label="账期开始日期"
            prop="beginPaymentDate"
            ref="beginPaymentDate">
        <a-date-picker v-model="paymentForm.beginPaymentDate" 
        :disabledDate="disabledDate"
        valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>
       

        <a-form-model-item style="width:800px"
            label="账期结束日期"
            prop="endPaymentDate"
            ref="endPaymentDate">
        <a-date-picker v-model="paymentForm.endPaymentDate" 
        :disabledDate="disabledDate"
            valueFormat="YYYY-MM-DD"/>
        </a-form-model-item>

        <a-form-model-item style="width:800px"
            label="代理驾校"
            prop="driverSchoolNo"
            ref="driverSchoolNo">
             <a-select 
             :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
             v-model="paymentForm.driverSchoolNo" style="width:200px">
                <a-select-option :value="item.driverSchoolNo"
                  v-for="item in driverSchoolList"
                  :key="item.driverSchoolNo">
                  {{ item.driverSchoolName }}
            </a-select-option>
        </a-select>
        </a-form-model-item>

      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button @click="handlePaymentCancel">取消</a-button>
          <a-button type="primary" @click="handlePaymentOk"> 确定 </a-button>
        </div>
      </template>
    </a-modal>
    <a-modal  @cancel="handlePaymentCalCancel"
        v-model="visibleCalPayment" title="分成信息" :maskClosable="false">
      <a-form-model
        ref="paymentCalForm"
        :model="paymentCalForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="visibleCalPayment"
      >
      <a-form-model-item class="allmodel"
            label="代理"
            prop="agentNo"
            ref="agentNo">
         {{paymentCalForm.agentName}}
      </a-form-model-item>
      <a-form-model-item class="allmodel"
            label="驾校"
            prop="driverSchoolNo"
            ref="driverSchoolNo">
         {{paymentCalForm.driverSchoolName}}
      </a-form-model-item>
       <a-form-model-item class="allmodel"
            label="账期"
            prop="beginPaymentDate"
            ref="beginPaymentDate">
         {{paymentCalForm.beginPaymentDate}}-{{paymentCalForm.endPaymentDate}}
      </a-form-model-item>
        <a-form-model-item class="allmodel"
            label="代理应得分成金额"
            prop="profitAmt"
            ref="profitAmt">
         {{paymentCalForm.profitAmt}}元
      </a-form-model-item>
       <a-form-model-item class="allmodel"
            label="代理应得退款金额"
            prop="refundAmt"
            ref="refundAmt">
         {{paymentCalForm.refundAmt}}元
      </a-form-model-item>
     
       <a-form-model-item class="payMent"
            label="预计打款金额："
            prop="calAmt"
            ref="calAmt">
         {{paymentCalForm.calAmt}}元
      </a-form-model-item>
      <a-form-model-item class="payMent"
            label="实际打款："
            prop="realAmt"
            ref="realAmt">
         <a-input-number placeholder="请输入" :precision="2" :min="1" :max="10000000"
         v-model="paymentCalForm.realAmt" style="width:120px" />&nbsp;元
      </a-form-model-item>

        <a-form-model-item style="width:900px;height:140px"
            ref="fengmiantu"
            label="凭证上传"
            class="fengmian has-error"
          >
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :custom-request="function () {}"
              :before-upload="beforeUpload"
              @change="handleUploadChange"
            >
              <img
                v-if="paymentCalForm.proofInfoUrl"
                :src="configUrl + paymentCalForm.proofInfoUrl"
                alt="avatar"
                style="width: 104px; height: 104px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <div class="tips">支持png、jpg、jpeg格式的图片，大小3M以内</div>
            <div class="ant-form-explain" v-if="isShowImgTips">
              封面图片不得为空
            </div>
          </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button @click="handlePaymentCalCancel">取消</a-button>
          <a-button type="primary" @click="handlePaymentCalOk"> 确定 </a-button>
        </div>
      </template>
    </a-modal>
    <Croppermodal
      @ok="handleProofOK"
      :autoCropWidth="600"
      :autoCropHeight="600"
      ref="cropperModal"
    />



  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import Croppermodal from "@/components/cropper-modal";
import moment from 'moment';
import config from "@/config";
export default {
  name: "agentProfitRecieve",//给代理打款分成
  data() {
    return {
      queryObj: {},//页面查询参数
      config: config,
      searchData: {//查询
        agentNo:"",
      },
      visibleChoosePayment:false,//两个弹框控制
      visibleCalPayment:false,

      driverSchoolList:[],//搜索框列表
      agentRemitRecieveList: [],//列表数据
      //用户选择的账期数据
      chooseBeginPaymentDate:"",
      chooseEndPaymentDate:"",
      chooseDriverSchoolNo:"",
      //图片相关参数
      configUrl: config.imgURL,//图片IP
      loading: false,
      isShowImgTips: false,
      //两个弹框的参数
      paymentForm:{
        beginPaymentDate:"",
        endPaymentDate:"",
        driverSchoolNo:""
      },
      
      paymentCalForm:{
        profitAmt:"",
        refundAmt:"",
        calAmt:"",
        realAmt:"",
        beginPaymentDate:"",
        endPaymentDate:"",
        driverSchoolName:"",
        agentName:""
      },
      paymentRules: {
        beginPaymentDate: [
          {
            required: true,
            message: "请选择账期开始日期",
            trigger: "change",
          },
        ],
        endPaymentDate: [
          {
            required: true,
            message: "请选择账期结束日期",
            trigger: "change",
          },
        ],
         driverSchoolNo: [
          {
            required: true,
            message: "请选择驾校",
            trigger: "change",
          },
        ],
      },
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "账期开始日期",
          dataIndex: "beginPaymentDate",
          key: "beginPaymentDate",
          width: 200,
        },
        { title: "账期结束日期", dataIndex: "endPaymentDate", key: "endPaymentDate", width: 200 },
        { title: "驾校", dataIndex: "driverSchoolName", key: "driverSchoolName", width: 200 },
        { title: "分成", dataIndex: "profitAmt", key: "profitAmt", width: 200 },
        { title: "退款", dataIndex: "refundAmt", key: "refundAmt", width: 200 },
        { title: "预计打款", dataIndex: "calAmt", key: "calAmt", width: 200 },
        { title: "实际打款", dataIndex: "realAmt", key: "realAmt", width: 200 },
        { title: "填写日期", dataIndex: "operateTime", key: "operateTime", width: 200 },
        { 
            title: "打款凭证", 
            dataIndex: "proofInfoUrl", 
            key: "proofInfoUrl", 
            width: 200,
            scopedSlots: { customRender: 'proofInfoUrl' },  
        },
      ],
    };
  },
  components: {
    Croppermodal,
  },
  mounted() {
    this.getSchoolList();
    this.getList();
  },
  created() {
      this.queryObj = this.$route.query;
  },
  methods: {
    reset() {
      this.searchData.driverSchoolNo="";
      this.chooseBeginPaymentDate="";
      this.chooseEndPaymentDate="";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.getList();
    },
    resetPayment(){
      this.paymentForm.beginPaymentDate="";
      this.paymentForm.endPaymentDate="";
      this.paymentForm.driverSchoolNo="";
    },
    resetPaymentCal(){
      this.paymentCalForm.driverSchoolName="";
      this.paymentCalForm.agentName="";
      this.paymentCalForm.beginPaymentDate="";
      this.paymentCalForm.endPaymentDate="";
      this.paymentCalForm.profitAmt="";
      this.paymentCalForm.refundAmt="";
      this.paymentCalForm.calAmt="";
      this.paymentCalForm.realAmt="";
    },
    handlePaymentCancel(){
      this.resetPayment();
      this.visibleChoosePayment=false;
    },
     
    handleRealCancel(){
      this.resetPayment();
      this.visibleChoosePayment=false;
    },

    handlePaymentCalCancel(){
      this.resetPaymentCal();
      this.visibleCalPayment=false;
    },
    //显示账期弹出框
    showPayment(){
      this.resetPayment();
      this.visibleChoosePayment=true;
    },
    //获得驾校列表
    getSchoolList(){
        ApiConfig.getSchoolListByAgent({
          agentNo:this.queryObj.agentNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.driverSchoolList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
     //获得列表
    
    getList() {
      //给代理打款记录
      ApiConfig.agentRemitReceiveList({
        agentNo:this.queryObj.agentNo,
        driverSchoolNo:this.searchData.driverSchoolNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }
             for (let i = 0; i < list.length; i++) {
              list[i].imgList=[];
              list[i].imgList.push(config.imgURL+list[i].proofInfoUrl);
            }
            this.agentRemitRecieveList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },

    //处理账期数据
    handlePaymentOk(){
      //处理日期
      if(this.paymentForm.beginPaymentDate!=null&&this.paymentForm.beginPaymentDate!=""
          &&this.paymentForm.endPaymentDate!=null&&this.paymentForm.endPaymentDate!=""){
        this.chooseBeginPaymentDate=this.paymentForm.beginPaymentDate;
        this.chooseEndPaymentDate=this.paymentForm.endPaymentDate;

        if(new Date(Date.parse(this.chooseBeginPaymentDate))>=new Date(Date.parse(this.chooseEndPaymentDate))){
          this.$message.error("日期不合理");
          return;
        }
      }else{
        this.$message.error("日期不合理");
        return;
      }
      //检查账期是否正确,以及计算
      ApiConfig.agentCalRemitByPayment({
        agentNo:this.queryObj.agentNo,
        driverSchoolNo:this.paymentForm.driverSchoolNo,
        beginPaymentDate:this.chooseBeginPaymentDate,//用户选择的账期
        endPaymentDate:this.chooseEndPaymentDate
      })
        .then((res) => {
          if (res.data.code == 200) {
            var info = res.data.data;
            //将返回的数据赋值给第二个弹出页面
            this.paymentCalForm.profitAmt=info.profitAmt;
            this.paymentCalForm.refundAmt=info.refundAmt;
            this.paymentCalForm.calAmt=info.calAmt;
            this.paymentCalForm.beginPaymentDate=this.chooseBeginPaymentDate;
            this.paymentCalForm.endPaymentDate=this.chooseEndPaymentDate;
            this.paymentCalForm.proofInfoUrl="";
            this.paymentCalForm.driverSchoolName=info.driverSchoolName;
            this.paymentCalForm.agentName=info.agentName;     

            this.chooseDriverSchoolNo=this.paymentForm.driverSchoolNo;
            this.visibleChoosePayment=false; 
            this.resetPayment();
            this.visibleCalPayment=true;
            //
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //保存打款信息
    handlePaymentCalOk(){
        //判断金额
        if(this.paymentCalForm.realAmt<=0){
          this.$message.error("实际打款金额不能为0");
          return;
        }
        if(Math.abs(this.paymentCalForm.realAmt-this.paymentCalForm.calAmt)>=10){
          this.$message.error("实际打款金额不能超过预计金额");
          return;
        }
        if(this.chooseDriverSchoolNo==""||this.queryObj.agentNo==""){
          this.$message.error("驾校编号或者代理编号不存在，请联系管理员");
          return;
        }
        //保存驾校分成信息
        ApiConfig.saveAgentRemitInfo({
            ...this.paymentCalForm,
            driverSchoolNo:this.chooseDriverSchoolNo,
            agentNo:this.queryObj.agentNo,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.visibleCalPayment=false;
            this.$message.success("打款保存成功");
            this.resetPaymentCal();
            this.getList();  
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });

    },
     beforeUpload(file) {
      const sizeOk = file.size < 1024 * 3000;
      const typeOk = file.type === "image/jpeg" || file.type === "image/png";
      return new Promise((resolve, reject) => {
        if (!typeOk) {
          this.$message.error("照片格式有误！");
          reject(file);
        } else if (!sizeOk) {
          this.$message.error("照片大小超过3M！");
          reject(file);
        } else {
          resolve(file);
        }
      });
    },
    handleUploadChange(info) {
      // 这个回调函数的参数是个File对象,所以要用FileReader将对象转换成 data-uri (base64) 对象,才能给vue-cropper
      var reader = new FileReader();
      const _this = this;
      // 当文件处理完成的回调函数
      reader.onload = function (e) {
        // e.target.result 该属性表示目标对象的DataURL
        // 然后调用cropperModal的edit方法使得对话框可见
        _this.$refs["cropperModal"].edit(e.target.result, "A");
      };
      // 记录原始的文件名字,用于DataURL(base64)转成file对象,不转就可以不用
      this.tmpImgName = info.file.name;
      // 文件处理 file=>base64
      reader.readAsDataURL(info.file.originFileObj);
    },
    handleProofOK(data) {
        console.log("上传凭证");
        //上传凭证
        this.loading = true;
        let file = this.dataURLtoFile(data, this.tmpImgName);
        // ....省略api请求 2种格式的对象都拿到了 怎么请求不用说了吧
        //上传凭证图片
        ApiConfig.uploadRemitProofImg({
          keyNo: "",
          base64Str: data,
        })
          .then((res) => {
            if (res.data.code == 200) {
              this.isShowImgTips = false;
              this.paymentCalForm.proofInfoUrl = res.data.data[0];
            } else {
              this.$message.error(res.data.msg);
            }
            this.loading = false;
          })
          .catch((error) => {
            this.$message.error(this.CommonConst.errorMsg);
          });
    },
    // 将base64转换为文件 百度随便找的 看需求使用
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    disabledDate(current){
      //今天以前，今天不可选择
      return current && current > moment().subtract(1, "day");
      //return current && current > moment().endOf("day");
    },

    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
  
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.payMent{
  width: 900px;
  height: 30px;
}
.allmodel{
  width: 900px;
  height: 20px;
}
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    .ant-calendar-picker{
        float: left;
        width: 240px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
   
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
       margin-left: 10px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>