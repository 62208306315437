<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="showmodal()"> 新增 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="save()"> 保存 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      
      <a-table
        :columns="columns"
        :data-source="timezoneList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="del(record)">删除</div>
        </template>
      </a-table>
    </div>
    <TimeZoneAdd
       ref="ref_addform" @backInit="backInit"
    />
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import TimeZoneAdd from './TimeZoneAdd'

export default {
  name: "timezonemanagement",
  data() {
    return {
      queryObj:{},
      titleFuture:'', 
      timezoneList: [],
      currList:[],
      showFuture:false,
      columns: [
         {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },  
        {
          title: "时间区间",
          dataIndex: "trainTimeZone",
          key: "trainTimeZone",
          width: 200,
        },
        {
          title: "开始时间",
          dataIndex: "beginTime",
          key: "beginTime",
          width: 200,
        },
        {
          title: "结束时间",
          dataIndex: "endTime",
          key: "endTime",
          width: 200,
        },
        {
          title: "生效开始日期",
          dataIndex: "enableBeginDate",
          key: "enableBeginDate",
          width: 150,
        },
        {
          title: "生效结束日期",
          dataIndex: "enableEndDate",
          key: "enableEndDate",
          width: 150,
        },
         {
          title: "操作",
          key: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
      
    };
  },
  components: {
      TimeZoneAdd,
  },
  mounted() {
    //this.getList();
  },
  created() {
    this.queryObj = this.$route.query;
  },
  methods: {
    del(item){
        console.log(item.id);
        for(var i=0;i<this.currList.length;i++){
            if (this.currList[i].id == item.id){//item.id==122
                this.currList.splice(i,1)
    　　    } 
        }
        this.timezoneList=this.currList;
    },
    reset() {
      //this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    //
    backInit(objTmpTimeZone,callback) {
       if(objTmpTimeZone!=null){
            var size= this.currList.length;
            objTmpTimeZone.id=size+1;
            objTmpTimeZone.driverSchoolNo=Helper.get("loginInfo").belongNo;
            //遍历检查是否有时间交集
            for(var i=0;i<this.currList.length;i++){
                var c=this.currList[i];
                if(!this.checkTime(c.beginTime,c.endTime,objTmpTimeZone.beginTime,objTmpTimeZone.endTime)){
                    this.$message.error("时间有冲突");
                    callback(false);
                    return;
                }
                //判断生效日期是否一致
                if(objTmpTimeZone.enableBeginDate!=c.enableBeginDate){
                    this.$message.error("生效日期需要一致");
                    callback(false);
                    return;
                }
            }
            //没有冲突
            this.currList.push(objTmpTimeZone);
            this.timezoneList=this.currList;
            //this.getList();
       }
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    save() {
        //保存时间区间信息
      ApiConfig.timeZoneSave({
        driverSchoolTimezones:JSON.stringify(this.currList)
      })//this.currList
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("保存成功");
            //跳转页面
            this.$router.push({
                path: "/driverschool/timezonemanagement",
            });
            //var list = res.data.data;
            //this.timezoneList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //弹出新增
    showmodal(flag) {
      var isExistsValid=this.queryObj.isExistValid;
      this.$refs["ref_addform"].init(flag,isExistsValid); // this.$refs触发一个组件里的方法
    },
    //检查时间是否有交集
    checkTime(firstBeginTime, firstEndTime, secondBeginTime, sencondEndTime) {
        var times1 = [],
        times2 = [];
        if (firstBeginTime < firstEndTime) {
            //未跨天
            times1.push([firstBeginTime, firstEndTime]);
        } else {
            //跨天
            times1.push([firstBeginTime, "24:00"], ["00:00", firstEndTime]);
        }

        if (secondBeginTime < sencondEndTime) {
            times2.push([secondBeginTime, sencondEndTime]);
        } else {
            times2.push([secondBeginTime, "24:00"], ["00:00", sencondEndTime]);
        }

        var flag = false;
        //循环比较时间段是否冲突
        for (var i = 0; i < times1.length; i++) {
            if (flag)
                break;
            for (var j = 0; j < times2.length; j++) {
                if (this.check(times1[i][0], times1[i][1], times2[j][0], times2[j][1])) {
                    flag = true;
                    break;
                }
            }
        }
        if (flag) {
            return false;
        } else {
            // alert("没有冲突");
            return true;
        }
    },
    check(a, b, x, y) {
        if (y <= a || b <= x) {
            return false;
        } else {
            return true;
        }
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>