<template>
  <div class="loginBg">
    <div class="loginCon">
      <div class="boxCenter">
        <div class="title">虚拟驾校管理平台</div>
        <div class="boxLogin">
          <div class="tab">
            <div class="type" v-if="active == 1 || active == 2">
              <div class="n1" :class="{ active: active == 1 }" @click="tab(1)">
                登录
              </div>
            </div>
          </div>
          <!-- 登录 -->
          <div class="boxLoginInfo" v-if="active == 1" key="1">
            <a-form-model ref="ruleForm" :model="formData" :rules="rules">
              <a-form-model-item prop="account" ref="account">
                <a-input
                  class="styleInput"
                  v-model="formData.account"
                  placeholder="驾校/公司/代理"
                  :maxLength="20"
                />
              </a-form-model-item>

              <a-form-model-item ref="password" prop="password">
                <a-input
                  class="styleInput"
                  v-model="formData.password"
                  type="password"
                  placeholder="密码"
                  :maxLength="16"
                />
              </a-form-model-item>
            </a-form-model>
            <a-button type="primary" @click="login" class="buttonCon">
              登录
            </a-button>
          </div>
          <div class="tits" v-if="active == 2 || active == 1">
             说明：驾校、公司以及代理登录。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/utils/helper";
import ApiConfig from "@/api/apiConfig.js";
import md5 from 'js-md5';

export default {
  data() {
    return {
      active: 1,
      formData: {
        account: "",
        password: ""
      },
   
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    Helper.clear();
  },
  methods: {
    keyupClear(field) {
      this.formData[field] = this.formData[field].replace(/[^\w]/g, "");
    },
    checkFormatPwd(pwd) {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/;
      var re = new RegExp(reg);
      if (re.test(pwd)) {
        return true;
      } else {
        return false;
      }
    },
    checkPwd(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.checkFormatPwd(value)) {
          if (this.formData.restPwd !== "") {
            this.$refs.ruleForm.validateField("restPwd");
          }
          callback();
        } else {
          callback(new Error("请输入6到10位数字与字母组合的密码"));
        }
        callback();
      }
    },
    checkResetPwd(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入重复密码"));
      } else if (value !== this.formData.pwd) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    },
    testMobilephone(str) {
      const regex = /^1[3456789]\d{9}$/;
      if (!regex.test(str)) {
        return false;
      } else {
        return true;
      }
    },
    checkmobile(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!this.testMobilephone(value)) {
          callback(new Error("请输入正确手机格式"));
        }
        callback();
      }
    },
   
     login(e) {
      this.$refs.ruleForm.validate((valid) => {
        
        if (valid) {
          ApiConfig.login({
            account: this.formData.account,
            password: md5(this.formData.password),
          })
            .then((res) => {
              if (res.data.code == 200) {
                var userData = res.data.data;
                //驾校员工，公司员工，代理员工,监管人员
                if (userData.userType == 1 
                || userData.userType == 2|| userData.userType==3||userData.userType==4)
                {
                  this.$message.success("登录成功!");
                  Helper.set({
                    loginInfo: JSON.stringify(userData),
                  });
                  setTimeout(() => {
                    this.$router.push({
                      path: "/home/index",
                    });
                  }, 1000);
                }
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((error) => {
              this.$message.error(this.CommonConst.errorMsg);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.loginBg {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/bg.png");
  overflow: hidden;
  .loginCon {
    width: 770px;
    margin: 0px auto;
    border: solid 1px #ccc;
    height: 100%;
    background: rgba(92, 91, 91, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .boxCenter {
      width: 100%;
    }
    .title {
      font-size: 32px;
      color: #fff;
      text-align: center;
    }
    .boxLogin {
      width: 55%;
      margin: 0px auto;
      .tab {
        margin-top: 50px;
        .type {
          overflow: hidden;
          .n1 {
            float: left;
            margin-right: 20px;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            height: 40px;
            line-height: 40px;
          }
          .active {
            color: #0e9a68;
            border-bottom: solid 1px #0e9a68;
          }
        }
      }
      .boxLoginInfo {
        margin-top: 20px;
      }
      .forgetPass {
        text-align: right;
        color: #0e9a68;
        margin-top: -20px;
        cursor: pointer;
        position: relative;
        z-index: 99;
      }
      .buttonCon {
        width: 140px;
        height: 40px;
        display: block;
        margin: 0px auto;
        margin-top: 20px;
      }
      .styleInput {
        height: 40px;
      }
      .tits {
        color: #fff;
        margin-top: 50px;
      }
    }
  }
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>






