<template>
    <div class="coursemanagement paddingCon">
      <div class="course-hea">
        <p>关键字:</p>
        <a-input placeholder="请输入" v-model="searchData.key" />
        <a-config-provider :auto-insert-space-in-button="false">
          <a-button type="primary" :ghost="true" class="hgf" @click="reset">
            重置
          </a-button>
        </a-config-provider>
        <a-config-provider :auto-insert-space-in-button="false">
          <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
        </a-config-provider>
      </div>
      <div class="course-content">
        <a-table
          :columns="columns"
          :data-source="complaintList"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
          <template slot="statusDesc" slot-scope="text, record">
            <div class="viewOne" v-if="record.status == 0" style="color:red">未处理</div>
            <div class="viewOne" v-if="record.status == 1" style="color:blue">已处理</div>
          </template>
        </a-table>
  
        <div class="paginationBox">
          <a-pagination
            :total="pageObject.total"
            @change="onChangePage"
            show-less-items
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ApiConfig from "@/api/apiConfig.js";
  //import Helper from "@/utils/helper";
  
  export default {
    name: "complaintmanagement",
    data() {
      return {
        pageObject: {
          pageNum: 1,
          pageSize: 10,
          total: 0,
        },
        searchData: {
          key: ""
        },
        complaintList: [],
        options: [],
        columns: [
          {
            title: "序号",
            dataIndex: "index",
            key: "index",
            customRender: (text, record, index) => index + 1,
            width: 40,
          },
          {
            title: "驾校名称",
            dataIndex: "driverSchoolName",
            key: "driverSchoolName",
             width: 60,
          },
          {
            title: "驾校编号",
            dataIndex: "driverSchoolNo",
            key: "driverSchoolNo",
             width: 40,
          },
          {
            title: "学员名称",
            dataIndex: "traineeName",
            key: "traineeName",
             width: 40,
          },
          {
            title: "手机号",
            dataIndex: "mobile",
            key: "mobile",
             width: 60,
          },
          {
            title: "反馈时间",
            dataIndex: "complaintTime",
            key: "complaintTime",
             width: 90,
          },
          {
            title: "状态",
            key: "statusDesc",
            scopedSlots: { customRender: "statusDesc" },
            width: 30
          },
          {
            title: "处理时间",
            dataIndex: "processTime",
            key: "processTime",
            width: 90  
          },
          {
            title: "处理结果",
            dataIndex: "processMsg",
            key: "processMsg",
            width: 120  
          },
          {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            width: 120 
          },
        ],
      };
    },
    components: {

    },
    mounted() {
      this.getList();
    },
    created() {},
    methods: {
      reset() {
        this.searchData.key = "";
        this.pageObject.pageNum = 1;
        this.getList();
      },
      search() {
        this.pageObject.pageNum = 1;
        this.getList();
      },
      handleSelectChange(value) {
        console.log(value);
      },
      backInit() {
        this.pageObject.pageNum = 1;
        this.getList();
      },
      getList() {
        //代理列表
        ApiConfig.complaintList({
          pageNum: this.pageObject.pageNum,
          pageSize: this.pageObject.pageSize,
          key:this.searchData.key,
        })
          .then((res) => {
            if (res.data.code == 200) {
              var list = res.data.data.records;
              this.complaintList = list;
              this.pageObject.total = res.data.data.total;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.$message.error(this.CommonConst.errorMsg);
          });
      },
      
      //点击分页操作
      onChangePage(page, pageSize) {
        this.pageObject.pageNum = page;
        this.pageObject.pageSize=pageSize;
        this.getList();
      },
      updateList() {},
    },
  };
  </script>
  <style lang='scss' scoped>
  .coursemanagement {
    .applyforclass-popup {
      width: 488px;
      height: 518px;
      background-color: red;
    }
    .course-content {
      margin-top: 18px;
      .view {
        color: #0e9a68;
        cursor: pointer;
        width:60px;
        float: left;
      }
      .viewTwo{
        color: #0e9a68;
        cursor: pointer;
        position: relative;
        width: 30px;
        left: 50px;
        top:-11px;
      }
      .viewOne{
        color: #0e9a68;
         top:10px;
         width: 80px;
         position: relative;
         //cursor: pointer;
      }
    }
    .paginationBox {
      margin-top: 80px;
    }
    .course-hea {
      width: 100%;
      height: 32px;
      overflow: hidden;
      p {
        float: left;
        line-height: 32px;
        padding-left: 10px;
        font-family: Source Han Sans CN;
        font-weight: regular;
      }
      // 输入框
      .ant-input {
        width: 180px;
        height: 32px;
        float: left;
        padding-left: 7px;
        margin-left: 5px;
      }
      .ant-select {
        float: left;
        padding: 0px 5px;
        width: 198px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
      button {
        float: left;
        width: 60px;
        height: 31px;
      }
      // 查询重置
      .fgh {
        margin-left: 16px;
        margin-right: 8px;
      }
      .hgf {
        border: 1px solid #ccc;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
        margin-left: 14px;
      }
  
      // button:focus{
      //   border-color: #0E9A68;
      //   outline:none;
      // }
      // .qur-1{
      //   color: #FFFFFF;
      //   font-family: Source Han Sans CN;
      //   font-weight: regular;
      //   font-size: 14px;
      //   line-height: normal;
      //   letter-spacing: 0px;
      //   text-align: center;
      //   border-radius: 2px;
      //   background: #0E9A68;
      //   border:none;
      //   margin-left:20px;
      // }
  
      // .qur-2{
      //   border-radius: 2px;
      //   background: #FFFFFF;
      //   border: 1px solid #D9D9D9;
      // }
      .apply {
        width: 112px;
        height: 32px;
        float: right;
        border-radius: 2px;
        background: #0e9a68;
        color: #ffffff;
        font-family: Source Han Sans CN;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;
        border: none;
        cursor: pointer;
      }
    }
  }
  </style>