<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
   
      <a-form-model-item label="代理名称" prop="agentName" ref="agentName">
          <a-input
            :maxLength="80"
            placeholder="请输入代理名称"
            v-model="formData.agentName"
          />
        </a-form-model-item>
        <!-- <a-form-model-item label="地址" prop="addr" ref="addr">
          <a-input
            :maxLength="50"
            placeholder="请输入代理地址"
            v-model="formData.addr"
          />
        </a-form-model-item> -->
  
    </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";

export default {
  components: {
  },
  data() {
    
    return {
      titleInfo: "",
      visible: false,
      loading: false,
      flag:'',
      rules: {
        agentName: [
          { required: true, message: "请输入代理名称", trigger: "change" },
        ],
       
      },
      formData: {
        agentName: "",
        agentNo:""
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v,obj) {
      this.resetForm();
      this.visible = true;
      this.flag=v;
      this.titleInfo="编辑代理信息";
      if(v=="add"){
          this.titleInfo="新增代理信息";
      };
      if(v!="add"){
          this.getAgentDetail(obj);
      }
    },
    getAgentDetail(obj){
        ApiConfig.getAgentDetail({
            agentNo:obj.agentNo
        })
        .then((res) => {
          if (res.data.code == 200) {
            var agentInfo = res.data.data;
            if(agentInfo!=null){
                this.formData = {
                    agentName: agentInfo.agentName,
                    agentNo:agentInfo.agentNo
                }
            }
            
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    resetForm() {
      this.formData = {
        agentName: "",
        agentNo:""
      };
    },
 
    handleSelectChange(value) {
      console.log(value);
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (this.formData.agentName == "") {
          return;
        }
 
        if (valid) {
           //保存
           ApiConfig.agentSave({
            agentName:this.formData.agentName,
            agentNo: this.formData.agentNo,
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.visible = false;
                this.$emit("backInit");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((error) => {
              this.$message.error(this.CommonConst.errorMsg);
            });
           
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    

    handleCancel(e) {
      this.visible = false;
    },
  },
  created() {},
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>