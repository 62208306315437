<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>身份证:</p>
      <a-input placeholder="请输入" v-model="searchData.idCardNo" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" />
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>

      <div>
        <!-- <a-config-provider :auto-insert-space-in-button="false">
          <a-button type="primary" class="apply" @click="download('')">
            模板下载
          </a-button>
        </a-config-provider>
        <a-config-provider :auto-insert-space-in-button="false">
          <a-upload
            class="apply"
            name="file"
            :show-upload-list="false"
            :multiple="true"
            :customRequest="customTraineeUploadRequest"
            :before-upload="beforeUpload"
            @change="handleTraineeUploadChange"
          >
            <a-button type="primary">
              <a-icon
                type="upload"
                style="text-align: left; margin-left: -38px"
              />批量导入
            </a-button>
          </a-upload>
        </a-config-provider> -->
        <!-- <a-config-provider :auto-insert-space-in-button="false">
          <a-button type="primary" class="apply" @click="showmodal('')">
            单独录入
          </a-button>
        </a-config-provider> -->
        <a-config-provider :auto-insert-space-in-button="false">
          <a-button type="primary" class="apply" @click="showmodal('bind')">
            学员绑定
          </a-button>
        </a-config-provider>

        <!-- <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="apply" @click="showmodal('recharge')">
          给学员充值
        </a-button>
      </a-config-provider> -->
      </div>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="traineeList"
        :pagination="false"
        :rowKey="(record, index) => index + 1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="traineeDetailClick(record)">查看</div>
        </template>

        <template slot="Action" slot-scope="text, record">
          <div class="view" @click="showmodal('recharge', record)">充值</div>
        </template>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
      <div>
        说明：本系统将手机号作为学员身份认证的唯一识别号。如果学员使用了两个手机号，将被认为是两个人。
      </div>
    </div>

    <TraineePreAdd ref="ref_addform" @backInit="backInit"> </TraineePreAdd>

    <TraineeBind ref="ref_bindform" @backInit="backInit"> </TraineeBind>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import TraineePreAdd from "./TraineePreAdd.vue";
import TraineeBind from "./TraineeBind.vue";
import Helper from "@/utils/helper";
import config from "@/config";
import axios from "axios";
//import sdk from "../../static/idcard/sdk";

// sdk.open_device()
//       .catch(err =>{
//       })
export default {
  name: "traineemanagement",
  data() {
    return {
      config: config,
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        traineeList: [],
        name: "",
        idCardNo: "",
        mobile: "",
      },
      traineeList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 50,
        },
        {
          title: "学员姓名",
          dataIndex: "name",
          key: "name",
          width: 100,
        },
        { title: "学员手机号", dataIndex: "mobile", key: "mobile", width: 120 },
        {
          title: "专属驾校",
          dataIndex: "driverSchoolName",
          key: "driverSchoolName",
          width: 200,
        },
        {
          title: "身份证",
          dataIndex: "idCardNo",
          key: "idCardNo",
          width: 160,
        },
        {
          title: "其他信息",
          dataIndex: "extInfo",
          key: "extInfo",
          width: 200,
        },
        {
          title: "剩余练车券",
          dataIndex: "leftCouponCount",
          key: "leftCouponCount",
          width: 110,
        },
        {
          title: "已用练车券",
          dataIndex: "hasUseCouponCount",
          key: "hasUseCouponCount",
          width: 110,
        },
        {
          title: "缺勤次数",
          dataIndex: "absenceCount",
          key: "absenceCount",
          width: 110,
        },
        {
          title: "驾校可赠送",
          dataIndex: "canFreeGiveStr",
          key: "canFreeGiveStr",
          width: 110,
        },
        {
          title: "录入时间",
          dataIndex: "createTimeText",
          key: "createTimeText",
          width: 180,
        },
        {
          title: "录入人",
          dataIndex: "createUser",
          key: "createUser",
          width: 100,
        },
        {
          title: "练车券",
          key: "Action",
          width: 80,
          scopedSlots: { customRender: "Action" },
        },
        {
          title: "详情",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
    TraineePreAdd,
    TraineeBind,
  },
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    reset() {
      this.searchData.mobile = "";
      this.searchData.name = "";
      this.searchData.idCardNo = "";
      this.searchData.traineeList = [];
      this.pageObject.pageNum = 1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      //驾校获取学员列表
      ApiConfig.traineeListByDS({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        driverSchoolNo: Helper.get("loginInfo").belongNo,
        name: this.searchData.name,
        mobile: this.searchData.mobile,
        idCardNo: this.searchData.idCardNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            for (let i = 0; i < list.length; i++) {
              var createTimeText = Helper.FormatToDate(
                list[i].createTime,
                true
              );
              list[i].createTimeText = createTimeText;
              if (list[i].createUser == null || list[i].createUser == "") {
                list[i].createUser = "小程序";
              }
              if(list[i].canFreeGive!=null&&list[i].canFreeGive=="1"){
                list[i].canFreeGiveStr="***";
              }else{
                list[i].canFreeGiveStr="";
              }
            }

            this.traineeList = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    beforeUpload(file) {
      const extension = file.name.split(".")[1] === "xls";
      const extension2 = file.name.split(".")[1] === "xlsx";
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!extension && !extension2) {
        this.$message.error("上传模板只能是 xls、xlsx格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传模板大小不能超过 10MB!");
        return;
      }
    },
    customTraineeUploadRequest(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      // formData.append("Authorization", Helper.get("loginInfo").token);
      axios({
        method: "post",
        url: config.serverURI + "/api-trainee/trainee-pre-add/batchImport",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {},
        data: formData,
      })
        .then((res) => {
          if (res.data.code == 200) {
            //获取保存的批量单号
            console.log("------" + res.data.orderNo);
            //跳转预导入页面
            this.$router.push({
              path: "/trainee/traineepreadd",
              query: {
                batchNo: res.data.data.batchNo,
              },
            });
            //this.$message.success(res.data.msg);
            //this.resetForm();
            //this.pageObject.pageNum = 1;
            //this.getList();
          } else {
            //返回错误，一种可能是对应的excel文件，或者是提示文字
            this.$message.error(res.data.msg);
            if (res.data.data.errorFile != null) {
              const file_path = res.data.data.errorFile;
              console.log(file_path);
              let link = document.createElement("a");
              let url = this.config.imgURL + file_path;
              fetch(this.config.imgURL + file_path)
                .then((res) => res.blob())
                .then((blob) => {
                  link.href = URL.createObjectURL(blob);
                  console.log(link.href);
                  link.download = "";
                  document.body.appendChild(link);
                  link.click();
                });
            }
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //批量导入
    handleTraineeUploadChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 上传成功`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败.`);
      }
    },

    showmodal(flag, obj) {
      //绑定学员
      if (flag == "bind") {
        this.$refs["ref_bindform"].init(flag, obj);
      } else {
        this.$refs["ref_addform"].init(flag, obj); // this.$refs触发一个组件里的方法
      }
    },
    //学员详情
    traineeDetailClick(item) {
      this.$router.push({
        path: "/trainee/detail",
        query: {
          traineeNo: item.traineeNo,
          driverSchoolNo: Helper.get("loginInfo").belongNo, //item.driverSchoolNo
        },
      });
    },
    download() {
      ApiConfig.downloadTemplate({})
        .then((res) => {
          if (res.data.code == 200) {
            const file_path = res.data.data.filepath;
            let link = document.createElement("a");
            let url = this.config.imgURL + file_path;
            fetch(this.config.imgURL + file_path)
              .then((res) => res.blob())
              .then((blob) => {
                link.href = URL.createObjectURL(blob);
                console.log(link.href);
                link.download = "";
                document.body.appendChild(link);
                link.click();
              });
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 30px;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 120px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 16px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 90px;
      height: 32px;
      float: right;
      margin-left: 7px;
      margin-right: 8px;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      text-align: center;
      font-family: Source Han Sans CN;
      font-size: 14px;
      border: none;
      cursor: pointer;
    }
  }
}
</style>