<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        v-if="visible"
      >
      <a-form-model-item
            label="开始时间"
            prop="beginTime"
            ref="beginTime"
        >
        <a-time-picker v-model="formData.beginTime" format="HH:mm" valueFormat="HH:mm"/>
      </a-form-model-item>
      <a-form-model-item
            label="结束时间"
            prop="endTime"
            ref="endTime"
        >
        <a-time-picker v-model="formData.endTime" format="HH:mm" valueFormat="HH:mm"/>
      </a-form-model-item>

      <a-form-model-item
            label="生效起始日期"
            prop="beginDate"
            ref="beginDate"
        >
        <a-date-picker v-model="formData.beginDate" 
        :disabled-date="disabledDate"
        :disabled="forbidon"
        valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
import moment from 'moment';
export default {
  components: {
  },
  data() {
    
    return {
      titleInfo: "录入时间区间信息",
      visible: false,
      loading: false,
      isExistValid:"0",
      forbidon:false,
      flag:'',
      rules: {
        beginTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        beginDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
      },
      formData: {
        beginTime: null,
        endTime:null,
        beginDate:null
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v,isExistValid) {
      this.visible = true;
      this.flag=v;
      this.forbidon=false;
      this.isExistValid=isExistValid;//用于判断日期范围
      this.titleInfo="配置时间区间";
      this.resetForm();
    },
    disabledDate(current) {
      //存在已配置数据，7天之后
      if(this.isExistValid=="1"){
         var end = moment().add('days', 7);
         return current<end;
      }else{
        //没有配置，则从当天开始
        var end = moment().add('days', -1);
        return current<end;
        //return current && current <= moment().endOf('day');
      }
      
    },
    resetForm() {
      this.formData = {
        beginTime: null,
        endTime:null,
        beginDate:null
      };
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (this.formData.beginTime == ""
            ||this.formData.endTime == ""
            ||this.formData.beginDate==null) {
          return;
        }
       
        if (valid) {
          //父页面列表数据新增
          var preAddObj={
            "trainTimeZone":this.formData.beginTime+"~"+this.formData.endTime,
            "beginTime": this.formData.beginTime,
            "endTime": this.formData.endTime,
            "enableBeginDate": this.formData.beginDate,
            "enableEndDate": "2099-12-31",
          };
          if(preAddObj.beginTime>=preAddObj.endTime){
            this.$message.error("开始时间与结束时间数据不合法");
            return;
          }
          //
          let data={};
          this.$emit("backInit",preAddObj,val=>{
            data=val;
          });
          if(data){
            this.forbidon=true;
          }
          this.formData.beginTime=this.formData.endTime;
          this.formData.endTime=null;
          
           
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    

    handleCancel(e) {
      this.visible = false;
    },
  },
  created() {},
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>