<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        v-if="visible"
      >
      <a-form-model-item
            label="每周开放日期"
            prop="beginEndWeek"
            ref="beginEndWeek"
        >
        <a-select default-value="1" style="width: 120px" @select="handleChangeFrom" :disabled="disInfo">
            <a-select-option value="1">周一</a-select-option>
            <a-select-option value="2">周二</a-select-option>
            <a-select-option value="3">周三</a-select-option>
            <a-select-option value="4">周四</a-select-option>
            <a-select-option value="5">周五</a-select-option>
            <a-select-option value="6">周六</a-select-option>
            <a-select-option value="7">周日</a-select-option>
        </a-select>
        ~
        <a-select default-value="7" style="width: 120px" @select="handleChangeTo" :disabled="disInfo">
            <a-select-option value="1">周一</a-select-option>
            <a-select-option value="2">周二</a-select-option>
            <a-select-option value="3">周三</a-select-option>
            <a-select-option value="4">周四</a-select-option>
            <a-select-option value="5">周五</a-select-option>
            <a-select-option value="6">周六</a-select-option>
            <a-select-option value="7">周日</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
            label="休息日"
            prop="restDate"
            ref="restDate"
        >
        <a-date-picker v-model="formData.restDate" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>

      <a-form-model-item
            label="生效起始日期"
            prop="enableBeginDate"
            ref="enableBeginDate"
        >
            <a-date-picker v-model="formData.enableBeginDate" 
                :disabled-date="disabledDate"
                valueFormat="YYYY-MM-DD" :disabled="disInfo"/>
      </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
import moment from 'moment';
export default {
  components: {
  },
  data() {
    
    return {
      titleInfo: "录入休息日信息",
      visible: false,
      loading: false,
      disInfo:false,
      isExistsValid:"0",
      flag:'',
      rules: {
        enableBeginDate: [
          { required: true, message: "请选择生效日", trigger: "change" },
        ],
      },
      formData: {
        beginWeekDay: 1,
        endWeekDay:7,
        restDate:null,
        enableBeginDate:null
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v,isExistsValid) {
      this.visible = true;
      this.flag=v;
      this.isExistsValid=isExistsValid;
      this.titleInfo="配置时间区间";
      this.disInfo=false;
      this.resetForm();
    },
    resetForm() {
      this.formData = {
        beginTime: null,
        endTime:null,
        beginDate:null
      };
    },
    disabledDate(current) {
      if(this.isExistsValid=="1"){
          var end = moment().add('days', 7);
          // Can not select days before today and today
          return current<end;
        //return current && current < moment().endOf('day');
      }else{
        var end = moment().add('days', -1);
        // Can not select days before today and today
        return current<end;
        //return current && current < moment().endOf('day');
      }
      
    },
    resetForm() {
      this.formData = {
        beginWeekDay: 1,
        endWeekDay:7,
        restDate:null,
        enableBeginDate:null
      };
      this.disInfo=false;
    },
    handleChangeFrom(value){
        console.log("------"+value);
        this.formData.beginWeekDay=value;
    },
    handleChangeTo(value){
        this.formData.endWeekDay=value;
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (this.formData.beginWeekDay == ""
            ||this.formData.endWeekDay == ""
            ||this.formData.enableBeginDate==null) {
          return;
        }
       
        if (valid) {
          //父页面列表数据新增
          var preAddObj={
            "beginWeekDay":this.formData.beginWeekDay,
            "endWeekDay": this.formData.endWeekDay,
            "restDate": this.formData.restDate,
            "enableBeginDate": this.formData.enableBeginDate,
            "enableEndDate": "2099-12-31",
          };
          if(preAddObj.beginWeekDay>=preAddObj.endWeekDay){
            this.$message.error("周数据不合法");
            return;
          }
          //用于控制生效日期是否灰化
          let checkdata={};
          this.$emit("backInit",preAddObj,val=>{
             checkdata=val;
          });
          console.log(checkdata);
          if(checkdata){
             this.disInfo=true;
          }else{
            this.disInfo=false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    

    handleCancel(e) {
      this.visible = false;
      this.disInfo=false;
    },
  },
  created() {},
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>