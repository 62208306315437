<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="showmodal('add')"> 新增 </a-button>
      </a-config-provider>
      <!-- <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="roleclick()"> 权限管理 </a-button>
      </a-config-provider> -->

    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="staffList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="showmodal('edit',record)">修改权限</div>
          <div class="view" @click="statusClick(record, 1)" v-if="record.isEnable == 0">
            启用
          </div>
          <div class="view" @click="statusClick(record, 0)" v-if="record.isEnable == 1">
            禁用
          </div>
        </template>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
    <SchoolStaffEdit
      ref="ref_addform"
      @backInit="backInit"
    >
    </SchoolStaffEdit>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import SchoolStaffEdit from "./SchoolStaffEdit"
export default {
  name: "cSchoolStaffgement",
  data() {
    return {
      queryObj: {},
      driverSchoolNo:"",
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        staffList: [],
        name: "",
      },
      staffList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 40,
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          width: 60,
        },
        { title: "账号", dataIndex: "account", key: "account", width: 60 },
        {
          title: "状态",
          dataIndex: "userStatus",
          key: "userStatus",
          width: 60,
        },
        {
          title: "角色",
          dataIndex: "strRolesName",
          key: "strRolesName",
          width: 60,
        },
        {
          title: "操作",
          key: "action",
          width: 60,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
    SchoolStaffEdit,
  },
  mounted() {
    this.getList();
  },
  created() {
    this.queryObj = this.$route.query;
  },
  methods: {
    reset() {
      this.searchData.name = "";
      this.searchData.staffList = [];
      this.pageObject.pageNum = 1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      //有参数传过来
      if(this.queryObj!=null){
        this.driverSchoolNo=this.queryObj.driverSchoolNo;
      }else{
        this.driverSchoolNo=Helper.get("loginInfo").belongNo //归属驾校
      }
      //如果为空，则表示不是驾校用户自己登陆
      // if(Helper.get("loginInfo").belongNo==null){
      //   this.driverSchoolNo=Helper.get("driverSchoolNo");
      // }else{
      //   this.driverSchoolNo=Helper.get("loginInfo").belongNo; //归属驾校
      // }
      // console.log("driverSchoolNo==="+Helper.get("driverSchoolNo"));
      //驾校获取员工列表
      ApiConfig.driverSchoolStaffList({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        //createBy: Helper.get("loginInfo").account,
        name: this.searchData.name,
        userType:1,
        driverSchoolNo:this.driverSchoolNo//Helper.get("loginInfo").belongNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }

            this.staffList = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
    //驾校员工角色设定
    roleclick() {
      this.$router.push({
        path: "/driverschool/rolemanagement",
        query: {
          driverSchoolNo:this.driverSchoolNo,//Helper.get("loginInfo").belongNo,
          roleType:'1'
        },
      });
    },
    //驾校员工状态变更
    statusClick(item,status) {
      console.log(item);
      ApiConfig.driverSchoolStaffStatusSave({
        account: item.account,
        isEnable: status
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("状态保存成功");
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
    
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 16px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>