<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>审核时间:</p>
      <a-range-picker
        v-model="searchData.beginEndAuditDateList"
        format="YYYY-MM-DD"
      />
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" />
      <p>练车驾校:</p>
      <a-select
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body;
          }
        "
        :dropdownStyle="{ overflow: 'auto' }"
        v-model="searchData.driverSchoolNo"
      >
        <a-select-option
          :value="item.driverSchoolNo"
          v-for="item in driverSchoolList"
          :key="item.driverSchoolNo"
        >
          {{ item.driverSchoolName }}
        </a-select-option>
      </a-select>
      <p>代理:</p>
      <a-select
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body;
          }
        "
        :dropdownStyle="{ overflow: 'auto' }"
        v-model="searchData.agentNo"
      >
        <a-select-option
          :value="item.agentNo"
          v-for="item in agentList"
          :key="item.agentNo"
        >
          {{ item.agentName }}
        </a-select-option>
      </a-select>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
      <!-- <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 新增 </a-button>
      </a-config-provider> -->
    </div>
    <div class="refund">当前筛选条件下，驾校获得退款{{schoolRefundAmt}}元，代理获的退款{{agentRefundAmt}}元。</div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="refundList"
        :pagination="false"
        :rowKey="(record, index) => index + 1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="moreClick(record)">更多</div>
          <div
            class="view"
            v-if="record.auditResult == '审核中'"
            @click="auditClick(record)"
            @click.stop
          >
            审核
          </div>
        </template>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>

    <a-modal v-model="visibleDetail" title="更多详情">
      <a-form-model
        ref="detailForm"
        :model="detailForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="visibleDetail"
      >
        <a-form-model-item
          style="width: 800px"
          label="申请理由"
          prop="applyReason"
          ref="applyReason"
        >
          {{ detailForm.applyReason }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="充值金额"
          prop="couponAmt"
          ref="couponAmt"
        >
          {{ detailForm.couponAmt }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="充值日期"
          prop="couponTime"
          ref="couponTime"
        >
          {{ detailForm.couponTime }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="预约练车日期"
          prop="bookTrainDate"
          ref="bookTrainDate"
        >
          {{ detailForm.bookTrainDate }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="预约练车时间段"
          prop="bookTimeZone"
          ref="bookTimeZone"
        >
          {{ detailForm.bookTimeZone }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="申请人"
          prop="applyPerson"
          ref="applyPerson"
        >
          {{ detailForm.applyPerson }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="审核人"
          prop="auditPerson"
          ref="auditPerson"
        >
          {{ detailForm.auditPerson }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="充值流水单号"
          prop="rechargeNo"
          ref="rechargeNo"
        >
          {{ detailForm.rechargeNo }}
        </a-form-model-item>
        <a-form-model-item
          style="width: 800px"
          label="退款流水单号"
          prop="refundNo"
          ref="refundNo"
        >
          {{ detailForm.refundNo }}
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button type="primary" @click="handleDetailCloseOk">
            确定
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal v-model="isConfirmVisible" title="退款审核">
      <a-form-model
        ref="detailForm"
        :model="detailForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="isConfirmVisible"
      >
        <a-form-model-item style="width: 800px">
          请进行审核操作
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button type="primary" @click="confirm"> 审核通过 </a-button>
          <a-button type="primary" @click="cancel"> 审核不通过 </a-button>
        </div>
      </template>
    </a-modal>

    <!-- <div class="mask_popup" >
        <a-popconfirm 
          :title="popTipTitle"
          ok-text="审核通过"
          cancel-text="审核不通过"
          @confirm="confirm"
          @cancel="cancel" :visible="isConfirmVisible"
        >
        </a-popconfirm>
  
    </div> -->

    <a-modal v-model="isEndConfirmVisible" title="审核">
      <a-form-model
        ref="detailForm"
        :model="detailForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="isEndConfirmVisible"
      >
        <a-form-model-item style="width: 800px">
          确定该操作吗？
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div class="footerCon">
          <a-button type="primary" @click="endConfirm"> 确定 </a-button>
          <a-button type="primary" @click="endCancel"> 取消 </a-button>
        </div>
      </template>
    </a-modal>

    <!-- <div class="mask_popup">
        <a-popconfirm 
          :title="endPopTipTitle"
          ok-text="确认"
          cancel-text="取消"
          @confirm="endConfirm"
          @cancel="endCancel" :visible="isEndConfirmVisible"
        >
        </a-popconfirm>
    </div> 
    -->

    <div class="jiazai" v-if="spinning">
      <a-spin class="zhuanquan" :spinning="spinning" size="large"></a-spin>
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import moment from 'moment';
export default {
  name: "companyRefundApply", //驾校退款申请管理
  data() {
    return {
      schoolRefundAmt:0,
      agentRefundAmt:0,
      companyRefundAmt:0,
      spinning: false,
      queryObj: {},
      searchData: {
        beginEndAuditDateList: [],
        name: "",
        mobile: "",
        driverSchoolNo: "",
        agentNo: "",
      },
      popTipTitle: "请进行审核操作",
      visibleDetail: false,

      refundList: [],
      //绑定列表
      agentList: [],
      driverSchoolList: [],
      //更多
      detailForm: {},
      isConfirmVisible: false,
      isEndConfirmVisible: false,
      endPopTipTitle: "确认通过吗?",
      auditBeginTime: "",
      auditEndTime: "",
      auditEndStatus: false,
      auditItem: {},
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "券码",
          dataIndex: "couponNo",
          key: "couponNo",
          width: 100,
        },
        {
          title: "学员姓名",
          dataIndex: "traineeName",
          key: "traineeName",
          width: 100,
        },
        {
          title: "学员手机号",
          dataIndex: "traineeMobile",
          key: "traineeMobile",
          width: 120,
        },
        // {
        //   title: "购买时间",
        //   dataIndex: "couponTime",
        //   key: "couponTime",
        //   width: 200,
        // },
        {
          title: "购买渠道",
          dataIndex: "buyChannel",
          key: "buyChannel",
          width: 100,
        },
        {
          title: "练车驾校",
          dataIndex: "driverSchoolName",
          key: "driverSchoolName",
          width: 100,
        },
        {
          title: "所属代理",
          dataIndex: "agentName",
          key: "agentName",
          width: 100,
        },

        {
          title: "申请时间",
          dataIndex: "applyTime",
          key: "applyTime",
          width: 100,
        },
        {
          title: "是否分成",
          dataIndex: "profitStatus",
          key: "profitStatus",
          width: 100,
        },

        {
          title: "审核结果",
          dataIndex: "auditResult",
          key: "auditResult",
          width: 100,
        },
        {
          title: "审核时间",
          dataIndex: "auditTime",
          key: "auditTime",
          width: 100,
        },
        //  {
        //   title: "充值金额（元）",
        //   dataIndex: "couponAmt",
        //   key: "couponAmt",
        //   width: 200,
        // },
        {
          title: "驾校获得退款",
          dataIndex: "schoolProfitRefundAmt",
          key: "schoolProfitRefundAmt",
          width: 140,
        },
        {
          title: "代理获得退款",
          dataIndex: "agentProfitRefundAmt",
          key: "agentProfitRefundAmt",
          width: 110,
        },
        {
          title: "公司获得退款",
          dataIndex: "companyProfitRefundAmt",
          key: "companyProfitRefundAmt",
          width: 110,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getAgentList();
    this.getSchoolList();
    this.getList();
    // 监听页面的点击事件，如果鼠标在pop弹出框和按钮外点击，那么让弹出框不显示
    //  document.addEventListener('click', (e)=> {
    //     if (e.target.className != 'ant-popover-buttons'
    //         &&e.target.parentNode.className != 'ant-popover-buttons'
    //         &&e.target.className !='ant-popover-message-title') {
    //         console.log(e.target.className);
    //         this.isConfirmVisible = false;
    //         this.isEndConfirmVisible = false;
    //     }
    //  });
  },
  created() {},
  methods: {
    //首次确认
    confirm() {
      this.isConfirmVisible = false;
      this.isEndConfirmVisible = true;
      this.auditEndStatus = true;
    },
    cancel() {
      this.isConfirmVisible = false;
      this.isEndConfirmVisible = true;
      this.endPopTipTitle = "确认不通过吗?";
      this.auditEndStatus = false;
    },
    endCancel() {
      this.isEndConfirmVisible = false;
    },

    reset() {
      this.searchData.name = "";
      this.searchData.mobile = "";
      this.searchData.agentNo = "";
      this.searchData.driverSchoolNo = "";
      this.searchData.beginEndAuditDateList = [];
      this.auditBeginTime = "";
      this.auditEndTime = "";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      if (
        this.searchData.beginEndAuditDateList != null &&
        this.searchData.beginEndAuditDateList[0] != null
      ) {
        this.auditBeginTime = Helper.dateFormat(
          "YYYY-mm-dd",
          this.searchData.beginEndAuditDateList[0]._d
        );
        this.auditEndTime = Helper.dateFormat(
          "YYYY-mm-dd",
          this.searchData.beginEndAuditDateList[1]._d
        );
      } else {
        //  //设定一个默认值
        // var currentDate = new Date();
        // this.auditEndTime = Helper.dateFormat(
        //   "YYYY-mm-dd",
        //   currentDate
        // );
        // //
        // var currentCopyDate=new Date();
        // currentCopyDate.setMonth(currentCopyDate.getMonth()-1);
        // this.auditBeginTime = Helper.dateFormat(
        //   "YYYY-mm-dd",
        //   currentCopyDate
        // );
        // //初始化没有日期限制，则设定默认值
        // this.searchData.beginEndAuditDateList= [moment(currentCopyDate, 'YYYY-MM-DD'), moment(currentDate, 'YYYY-MM-DD')];
      }

      //获得退款
      ApiConfig.driverSchoolRefundList({
        auditBeginTime: this.auditBeginTime,
        auditEndTime: this.auditEndTime,
        traineeName: this.searchData.name,
        traineeMobile: this.searchData.mobile,
        driverSchoolNo: this.searchData.driverSchoolNo,
        agentNo: this.searchData.agentNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.list;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }
            this.refundList = list;
            //汇总数据
             //显示相关汇总数据
            var totalInfo=res.data.data.sumary;
            if(totalInfo!=null){

              this.schoolRefundAmt=totalInfo.school;
              this.companyRefundAmt=totalInfo.company;
              this.agentRefundAmt=totalInfo.agent;
              
            }
            
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    //获得代理列表
    getAgentList() {
      ApiConfig.getAgentList({})
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.agentList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //获得驾校列表
    getSchoolList() {
      ApiConfig.getSchoolList({})
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.driverSchoolList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //更多
    moreClick(item) {
      //赋值
      this.detailForm = item;
      this.visibleDetail = true;
    },
    handleDetailCloseOk() {
      this.visibleDetail = false;
    },
    //最终确认
    endConfirm() {
      this.spinning = true;
      //退款审核
      ApiConfig.refundAudit({
        couponNo: this.auditItem.couponNo,
        applyNo: this.auditItem.applyNo,
        auditStatus: this.auditEndStatus,
      })
        .then((res) => {
          this.spinning = false;
          if (res.data.code == 200) {
            this.isEndConfirmVisible = false;
            this.$message.success("退款审核提交成功");
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.spinning = false;
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //审核
    auditClick(item) {
      this.auditItem = item;
      console.log(item);
      this.isConfirmVisible = true;
      if (item.buyChannel != "驾校代充") {
        //本人充值
        this.popTipTitle = "学员充值的练车券，请先将款项退还再通过申请";
      }
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.refund{
  margin-top: 20px;
  margin-left: 10px;
  font-size: 16px;
}
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 30px;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      margin-right: 6px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
    }
    .ant-calendar-picker {
      float: left;
      width: 220px;
    }
    .ant-select {
      float: left;

      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 14px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
  //  .mask_popup{
  //           position: absolute;
  //           top: 50%;
  //           left: 50%;
  //           -webkit-transform: translate(-50%, -50%);
  //           -moz-transform: translate(-50%, -50%);
  //           -ms-transform: translate(-50%, -50%);
  //           -o-transform: translate(-50%, -50%);
  //           transform: translate(-50%, -50%);
  // }
}
.jiazai {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0, 0, 0.3);
  .zhuanquan {
    display: block;
    margin: 20% auto;
  }
}
</style>