const root = '/api'
//微服务调用端口
const root_user = '/api-user'
const root_driverschool = '/api-driverschool'
const root_trainee = '/api-trainee'
const root_company = '/api-company'
const root_agent = '/api-agent'


export default {
    cityList: '/v2/pagePosts',
    webList: '/cup/webList',

    //登陆
    login: root_user + '/login',
    getRouterInfo: root_user + '/user/getRouterInfo',

    //资源列表
    resourcesList: root_user + '/resource/list',//权限列表
    addByResources: root_user + '/role/addByResources',//添加角色以及角色对应的资源

    roleQuery: root_user + '/role-resource/query',//查询该角色下相关权限
    roleConfig: root_user + '/role-resource/configRoleAndResources',//配置该角色对应的资源信息

    /***********驾校端*************/
    getProList: root_driverschool + '/driver-school/getProList',//获取省份列表数据

    traineeBindSchool: root_trainee + '/trainee/bindSchool',//绑定学员驾校

    traineeListByDS: root_trainee + '/trainee/list',//获取学员列表
    traineeDetail: root_trainee + '/trainee/detail',//获取学员详情
    traineeBookList: root_trainee + '/trainee-book/getBookAndExerByPage',//学员预定以及练习的数据
    traineeRechageAdd: root_driverschool + '/driver-school-coupon-pay/saveTraineePayInfo',

    getSchoolPayQRCodeInfo: root_driverschool + '/driver-school-coupon-pay/getPayQRInfo',//获取驾校给学员支付的二维码
    traineePayResultInfo: root_driverschool + '/driver-school-coupon-pay/getPayResultInfo',//获取驾校给学员支付的信息

    couponInfoList: root_trainee + '/trainee-coupon-order/listByTrainee',//学员券使用情况

    calDriverSchoolPayByCouponCount: root_driverschool + '/driver-school-profit-ratio/calPayByCouponCount',
    checkTraineeMobile: root_trainee + '/trainee/checkTraineeMobile',//判断手机号是否存在
    traineePreAdd: root_trainee + '/trainee-pre-add/save',
    traineePreAddListByDS: root_trainee + '/trainee-pre-add/list',
    traineePreAddOrderDS: root_trainee + '/trainee-pre-add/getOrderInfo',//获得某批次的订单信息
    traineePreAddImportByDS: root_trainee + '/trainee-pre-add/saveToReal',//保存到正式表

    driverSchoolIncomeList: root_trainee + '/trainee-pay/list',//学员支付信息
    driverSchoolExpenseList: root_driverschool + '/driver-school-coupon-pay/list',//驾校给学员充值记录
    driverSchoolDeviceTodayUseList: root_driverschool + '/driver-school-device/listTodayBook',//驾校设备当天所有预约情况
    driverSchoolOneDeviceTodayBookList: root_driverschool + '/driver-school-device/listOneDeviceTodayBook',

    driverSchoolProfitRecieveList: root_company + '/company-remit-info/getSchoolRemitInfo',//获取学习分成记录
    getCouponInfoBySchool: root_trainee + '/trainee-coupon-order/list',//获取券使用情况
    getCouponOrderMoreInfo: root_trainee + '/trainee-coupon-order/getCouponOrderMoreInfo',//单个券所有完整的信息

    driverSchoolRefundList: root_driverschool + '/driver-school-refund-apply/list',//退款列表
    saveRefundApply: root_driverschool + '/driver-school-refund-apply/add',//退款申请

    driverSchoolBookTimeZoneList: root_driverschool + '/driver-school-timezone/getTimeZoneValid',//时间区间
    driverSchoolBookTimeZoneFutureList: root_driverschool + '/driver-school-timezone/getTimeZoneFuture',
    timeZoneSave: root_driverschool + '/driver-school-timezone/save',


    driverSchoolRestDateList: root_driverschool + '/driver-school-rest/getRestValid',//休息日
    driverSchoolRestDateFutureList: root_driverschool + '/driver-school-rest/getRestFuture',
    restDateSave: root_driverschool + '/driver-school-rest/save',

    driverSchoolStaffList: root_user + '/user/list',//员工管理
    driverSchoolStaffStatusSave: root_user + '/user/setUserStatus',//驾校员工状态变更
    schoolStaffSaveByRole: root_user + '/user/saveUserAndRoles',//驾校员工保存
    schoolStaffRoleList: root_user + '/role/list',//驾校员工角色

    driverSchoolInfo: root_driverschool + '/driver-school/detail',//驾校信息
    driverSchoolUploadCoverImg: root_driverschool + '/driver-school/uploadCoverImg',//上传封面图片
    driverSchoolUploadDescImg: root_driverschool + '/driver-school/uploadDescImg',//上传简介图片
    driverSchoolInfoSave: root_driverschool + '/driver-school/save',//保存驾校信息

    downloadTemplate: root_trainee + '/trainee-pre-add/downloadTraineeTemplate',//下载模板

    scoreList: root_trainee + '/trainee-exercise-result/listExerScorePage',
    exportScoreList: root_trainee + '/trainee-exercise-result/exportExerScore',

    getListExerBySchoolForWeb: root_trainee + '/trainee-exercise-result/getListExerBySchoolForWeb',


    //公司端
    traineeListByCompany: root_trainee + '/trainee/listByCouponSchool',//学员列表
    driverSchoolRechargeList: root_driverschool + '/driver-school-coupon-pay/list',//驾校充值列表
    //driverSchoolRechargeDetail:root_driverschool+'/driver-school-coupon-pay/list',//驾校充值明细

    traineeRechargeList: root_trainee + '/trainee-pay/listAll',//学员充值列表
    //traineeRechargeDetail:root_trainee+'/trainee-pay/listAll',//学员充值明细

    driverSchoolList: root_driverschool + '/driver-school/list',//驾校分页列表
    companyStaffList: root_user + '/user/list',//公司员工管理
    companyStaffRoleList: root_user + '/role/list',//获取公司员工角色列表
    companyStaffStatusSave: root_user + '/user/setUserStatus',//公司员工状态
    companyStaffSaveByRole: root_user + '/user/saveUserAndRoles',//保存员工信息
    userdetail: root_user + '/user/detail',//员工信息以及权限
    agentStatusSave: root_agent + '/agent/statusSave',//代理状态变动
    driverSchoolStatusSave: root_driverschool + '/driver-school/statusSave',//驾校状态变动
    getProvinceAndCityList: root_driverschool + '/driver-school/getProvinceAndCityList',//获取所有的省份城市关联信息
    getProfitList: root_driverschool + '/driver-school-profit-ratio/list',//分成列表
    saveProfit: root_driverschool + '/driver-school-profit-ratio/save',//保存分成
    getAgentList: root_agent + '/agent/listAll',//列出所有的代理
    getAgentDetail: root_agent + '/agent/detail',//代理详情
    agentSave: root_agent + '/agent/save',//保存代理
    driverSchoolSave: root_driverschool + '/driver-school/save',
    getSchoolDetail: root_driverschool + '/driver-school/detail',

    getSchoolList: root_driverschool + '/driver-school/listAll',//列出所有的驾校

    agentRemitReceiveSumaryList: root_company + '/company-remit-info/getAgentRemitSumaryInfo',//给代理打款的汇总数据
    agentRemitReceiveList: root_company + '/company-remit-info/getAgentRemitInfo',
    schoolRemitReceiveSumaryList: root_company + '/company-remit-info/getSchoolRemitSumaryInfo',
    schoolRemitReceiveList: root_company + '/company-remit-info/getSchoolRemitInfo',
    schoolCalRemitByPayment: root_company + '/company-remit-info/calSchoolPaymentAmt',//计算某个账期给驾校打款
    uploadRemitProofImg: root_company + '/company-remit-info/saveProofInfo',//上传凭证图片信息
    saveSchoolRemitInfo: root_company + '/company-remit-info/saveSchoolRemitInfo',//保存驾校打款信息
    agentCalRemitByPayment: root_company + '/company-remit-info/calAgentAndSchoolPaymentAmt',//计算某个账期给某一个驾校对应的代理打款
    saveAgentRemitInfo: root_company + '/company-remit-info/saveAgentRemitInfo',//保存给对应驾校的代理打款

    refundAudit: root_company + '/refund/audit',//驾校提交的退款进行审核

    deviceSoftwareVersionList: root_driverschool + '/device-software-version/list',
    getDriverSchoolListByPro: root_driverschool + '/driver-school/getDriverSchoolListByPro',
    //批量保存该驾校下对应的设备版本信息
    deviceVersionSave: root_driverschool + '/device-software-version/saveBySchool',
    deviceVersionUpdate: root_driverschool + '/device-software-version/updateDeviceVersion',

    getDeviceVersion: root_driverschool + '/device-software-version/getVersionInfoDetail',

    getDeviceList: root_driverschool + '/driver-school-device/listDevice',
    deviceSave: root_driverschool + '/driver-school-device/addDevice',
    //更改设备状态
    deviceStatusSave: root_driverschool + '/driver-school-device/deviceStatusSave',
    changeTraineeBookDevice:root_trainee+'/trainee-book/changeTraineeBookDevice',
    //学员反馈设备故障等
    complaintList:root_trainee+'/trainee-complaint/list',

    //代理端
    agentList: root_agent + '/agent/querylist',
    agentStaffList: root_user + '/user/list',
    agentProfitRecieveList: root_company + '/company-remit-info/getAgentRemitInfo',//代理分成到账
    agentStaffStatusSave: root_user + '/user/setUserStatus',//修改代理员工状态
    agentStaffRoleList: root_user + '/role/list',//代理员工角色列表
    agentStaffSaveByRole: root_user + '/user/saveUserAndRoles',//保存代理员工信息以及角色信息
    getSchoolListByAgent: root_driverschool + '/driver-school/getSchoolListByAgent',//获得该代理下驾校的信息

    //监管端
    traineeListBySupervision: root_trainee + '/trainee/listBySupervision',//监管部门查看学员信息
    driverschoolListBySupervision: root_driverschool + '/driver-school/listBySupervision',//监管部门查看学员信息

}