const sdk = new (function() {
  let websockets;
  let isConnected = false;
  let messageCallback = null; //消息回调
  let connectedCallback = null;
  //创建一个数组对象用于存放当前的连接的状态，以便在页面上实时展示出来当前的状态
  let statusArr = [
    { state: 0, value: "正在连接" },
    { state: 1, value: "已建立连接" },
    { state: 2, value: "正在关闭连接" },
    { state: 3, value: "已关闭连接" },
  ];
  let timer = null;

  /**
   *   建立连接
   *
   */
  this.connect = function(callback1, callback) {
    if (!isConnected) {
      // 1. 创建websockets对象，参数为服务器websockets地址
      //let hostname = location.hostname;
      let url = "ws:127.0.0.1:7415";
      websockets = new WebSocket(url);

      // 2.监听websocket的状态变化，接收的信息，关闭时的状态
      //监听连接状态的变化
      websockets.onopen = () => socketChange();

      connectedCallback = callback1;
      messageCallback = callback;
      //监听接收消息的情况
      websockets.onmessage = (res) => {
        if (res.data == "failed to obtain ID card information") {
          console.log("接收数据", res.data);
        }
        var alldata = res.data.split("|");
        if (alldata.length >= 17) {
          let data = {};

          data.partyName = alldata[1];
          data.certNumber = alldata[6];
          messageCallback(data);
          //其他对应字段
          //   document.getElementById("partyName").value = alldata[1];
          //   document.getElementById("nation").value = alldata[3];
          //   document.getElementById("gender").value = alldata[2];
          //   document.getElementById("certAddress").value = alldata[5];
          //   document.getElementById("bornDay").value = alldata[4];
          //   document.getElementById("certNumber").value = alldata[6];
          //   document.getElementById("certOrg").value = alldata[7];
          //   document.getElementById("effDate").value = alldata[8];
          //   document.getElementById("expDate").value = alldata[9];
          //   document.all['PhotoDisplay'].src = 'data:image/bmp;base64,' + alldata[17];
        } else {
          console.log(res.data);
        }
      };

      //监听关闭时的状态变化
      websockets.onclose = () => socketChange();
    } else {
      //this.closeConnect();
    }
  };

  this.TimeOutReadIDCard = function() {
    if (!isConnected) {
      console.log("未连接服务");
    } else {
      let val = "02?timeout=2000";
      websockets.send(val);
    }
  };
  this.readCard = function() {
    if (!isConnected) {
      console.log("未连接服务");
    } else {
      let val = "03?timeout=2000";
      websockets.send(val);
    }
  };
  this.ReadIDCardNoJudge = function() {
    if (!isConnected) {
      console.log("未连接服务");
    } else {
      timer = setInterval(() => {
        if(isConnected) {
            let val = "04?timeout=1500";
            websockets.send(val);
        }
      }, 2000);
    }
  };
  /**
   *   socket状态变化
   *
   */
  function socketChange() {
    let state = websockets.readyState;
    let val = statusArr.map((item) => {
      if (item.state == state) {
        return item.value;
      }
    });
    console.log(val);
    //读身份证
    if (1 == state) {
      isConnected = true;
      connectedCallback(1);
    }
    if (3 == state) {
      isConnected = false;
    }
  }
  /**
   *   关闭连接
   *
   */
  this.closeConnect = function() {
    websockets.close();
    //关闭定时器
    clearInterval(timer);
  };
})();

export default sdk;
