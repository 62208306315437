<template>
  <div class="platformadministration paddingCon">
    <div class="rechargeText">
      <ul>
        <li>
          <span>学员姓名:</span>
          <span>{{ wang.name }}</span>
        </li>
        <li>
          <span>学员手机号:</span>
          <span>{{ wang.mobile }}</span>
        </li>
      </ul>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="newtistics"
        :pagination="false"
        :rowKey="(record, index) => index + 1"
      >
        <!-- record代表当前行里所有的数据 -->
      </a-table>
    </div>

    <!-- 分页 -->
    <div class="paginationBox">
      <a-pagination
        v-model="current"
        :pageNum="pageObject.pageNum"
        :pageSize="pageObject.pageSize"
        :total="pageObject.total"
        @change="pagingClick"
        show-less-items
      />
    </div>
  </div>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
export default {
  name: "traineedetail",
  data() {
    return {
      queryObj: {},
      pageObject: {
        pageNum: 1, //当前页数
        pageSize: 8,
        total: 0,
      },
      current: 1,
      columns: [
        {
          title: "id",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 80,
        },
        { title: "券码", dataIndex: "couponNo", key: "couponNo", width: 320 },
        {
          title: "购买时间",
          dataIndex: "couponTimeText",
          key: "couponTimeText",
          width: 236,
        },
        {
          title: "购买渠道",
          dataIndex: "buyChannel",
          key: "buyChannel",
          width: 236,
        },
        {
          title: "券金额（元）",
          dataIndex: "couponAmt",
          key: "couponAmt",
          width: 236,
        },
        {
          title: "设备名称",
          dataIndex: "deviceName",
          key: "deviceName",
          width: 236,
        },
        {
          title: "预约练车日期",
          dataIndex: "bookTrainDate",
          key: "bookTrainDate",
          width: 236,
        },
        {
          title: "时间段",
          dataIndex: "bookTimeZone",
          key: "bookTimeZone",
          width: 236,
        },
        // { title: "出勤状态", dataIndex: "attendStatus", key: "attendStatus", width: 236 },
        {
          title: "退款",
          dataIndex: "refundDesc",
          key: "refundDesc",
          width: 240,
        },
      ],
      ticArray: [
        { key: 1, adopt: "出勤" },
        { key: 2, adopt: "缺课" },
      ],
      newtistics: [],
      wang: [],
    };
  },
  components: {},
  mounted() {
    this.getDetail();
    this.getCouponInfoList(); //券使用情况
  },
  created() {
    this.queryObj = this.$route.query;
  },
  methods: {
    getCouponInfoList() {
      ApiConfig.couponInfoList({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        traineeNo: this.queryObj.traineeNo,
        driverSchoolNo: this.queryObj.driverSchoolNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            for (let i = 0; i < list.length; i++) {
              var couponTimeText = Helper.FormatToDate(
                list[i].couponTime,
                true
              );
              list[i].couponTimeText = couponTimeText;
            }
            this.newtistics = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //学员详情
    getDetail() {
      ApiConfig.traineeDetail({
        traineeNo: this.queryObj.traineeNo,
        driverSchoolNo: this.queryObj.driverSchoolNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.wang = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //查看
    seeClick() {
      console.log(1);
    },
    //点击分页操作
    pagingClick(page, pageSize) {
      this.pageObject.pageNum = page;
      this.pageObject.pageSize = pageSize;
      this.getCouponInfoList();
    },
  },
};
</script>
<style lang='scss' scoped>
.rechargeText {
  padding-left: 9px;
  ul {
    overflow: hidden;
    li {
      list-style: none;
      float: left;
      span {
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: right;
      }

      span:nth-child(even) {
        padding-left: 14px;
      }
    }

    li:nth-child(2) {
      margin-left: 48px;
      margin-right: 48px;
    }
    li:nth-child(3) {
      margin-right: 48px;
    }
  }
}
.searchHeader {
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 9px;
  margin-bottom: 23px;

  .header-1,
  .header-2,
  .header-3 {
    float: left;
  }

  .header-1 {
    // width: 240px;
    height: 32px;
    overflow: hidden;
    box-sizing: border-box;
    float: left;

    p {
      // width: 40px;
      height: 32px;
      float: left;
      box-sizing: border-box;
      text-align: center;
      line-height: 32px;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      padding-right: 5px;
    }
    p {
      float: left;
      line-height: 32px;
    }

    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-input-affix-wrapper {
      width: 198px;
      height: 32px;
      float: left;
      // padding-left:7px;
    }

    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-left: 0px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
  }

  .header-2 {
    width: 240px;
    height: 32px;
    overflow: hidden;
    box-sizing: border-box;
    float: left;
    p {
      float: left;
      text-align: center;
      line-height: 32px;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      margin-right: 7px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    margin-left: 15px;
  }

  .header-3 {
    float: left;
    button {
      width: 60px;
      height: 31px;
      text-align: center;
      line-height: 31px;
      border-radius: 2px;
      // font-size:14px;
      outline: none;
      cursor: pointer;
      border: none;
    }

    .fgh {
      margin-left: 8px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  .apply {
    width: 112px;
    height: 32px;
    float: right;
    border-radius: 2px;
    background: #0e9a68;
    color: #ffffff;
    font-family: Source Han Sans CN;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    border: none;
    cursor: pointer;
  }
}

.view {
  color: #0e9a68;
  cursor: pointer;
  float: left;
  font-size: 14px;
  padding-left: 2px;
}
.pagbox {
  text-align: center;
  position: absolute;
  bottom: 3%;
  left: 50%;
}
</style>

