<template>
  <div class="permissions marginTop20">
    <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="角色名称" prop="roleDesc" ref="roleDesc">
        <a-input
          style="width: 40%"
          v-model="formData.roleDesc"
          :disabled="this.queryObj.flag == 'edit'"
        ></a-input>
      </a-form-model-item>

      <div style="height: 20px"></div>
      <a-form-model-item label="权限内容：" prop="quanxian" ref="quanxian">
        <div v-for="(item, index) in resourcesList" :key="index">
          <div class="labeltit">{{ item.title }}</div>
          <div style="clear: both"></div>
          <div v-for="child in item.children" :key="child.resKey" class="f14">
            <input
              type="checkbox"
              :id="child.resKey"
              :value="child.resKey"
              v-model="formData.resArray"
            />
            <label :for="child.resKey">{{ child.title }}</label>
          </div>
          <div style="clear: both; height: 10px"></div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="footerCon">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk"> 确定 </a-button>
    </div>
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js";
export default {
  name: "permissions",
  data() {
    return {
      formData: {
        enable: 1,
        roleType:"",//角色类型（用于区分驾校公司等不同的角色定义）
        roleDesc: "",
        resArray: [],
      },
      rules: {
        roleDesc: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
      queryObj: {},
      resourcesList: [],
    };
  },
  components: {},
  mounted() {
    this.getList();
  },
  created() {
    this.queryObj = this.$route.query;
    console.log("参数=========", this.queryObj);
    if (this.queryObj.flag == "edit") {
      this.formData.roleDesc = this.queryObj.roleDesc
      this.getRole();
    }
    this.formData.roleType=this.queryObj.roleType;
  },
  methods: {
    //获取该角色下所有的权限
    getRole() {
      ApiConfig.roleQuery({
        roleKey: this.queryObj.roleKey,
      })
        .then((res) => {
          if (res.data.code == 200) {
            
            var tempArr = [];
            var list = res.data.data;
            console.log("=====AAAA=",list.length)
            for (var i = 0; i < list.length; i++) {
              for (var j = 0; j < list[i].children.length; j++) {
                console.log("============", list[i].children[j].checked);
                if (list[i].children[j].checked) {
                  tempArr.push(list[i].children[j].resKey);
                }
              }
            }
            console.log("tempArr=============", tempArr);
            this.formData.resArray = tempArr;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getList() {
      //获取权限资源列表
      ApiConfig.resourcesList({
          resType:this.queryObj.roleType
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.resourcesList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.formData.resArray.length == 0) {
            this.$message.error("请选择权限");
            return;
          }

          if(!this.queryObj.roleKey) {
              ApiConfig.addByResources(this.formData)
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$message.success(res.data.msg);
                    this.$router.push({
                      path: "/agent/rolemanagement",
                    });
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch((error) => {
                  this.$message.error(this.CommonConst.errorMsg);
                });
          } else {
              ApiConfig.roleConfig({...this.formData,roleKey:this.queryObj.roleKey})
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$message.success(res.data.msg);
                    this.$router.push({
                      path: "/agent/rolemanagement",
                    });
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
                .catch((error) => {
                  this.$message.error(this.CommonConst.errorMsg);
                });
          }
            
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel() {
      this.$router.push({
        path: "/agent/rolemanagement",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.marginStyle {
  margin: 10px 0px;
}
.permissions {
  width: 80%;
  margin: 40px auto;
  overflow: hidden;
}
.labeltit {
  font-size: 16px;
  height: 30px;
}
.checkboxCon {
  margin: 10px 0px 40px 0px;
}
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
  button {
    padding: 0px 30px;
    margin: 0px 10px;
  }
}
.f14 {
  float: left;
  margin-right: 17px;
  input {
    margin-right: 5px;
    cursor: pointer;
    margin-top: 3px;
  }
  label {
    cursor: pointer;
  }
}
</style>

