<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>账期时间:</p>
      <a-range-picker 
       :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.beginEndReceiveDateList" format="YYYY-MM-DD"/>   
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="profitReceiveList"
        :pagination="false"
        :rowKey="(record,index) => index+1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="proofInfoUrl" slot-scope="text, record">
          <!-- <img :id ="record.proofInfoUrl" :src="config.imgURL+record.proofInfoUrl" 
          @click="showImg(record)" 
          width="30px" height="30px"/> -->
          <!--show zoom img-->
          <viewer :images="record.imgList">
            <img v-for="src in record.imgList" :src="src" :key="src"  width="30px" height="30px"/>
          </viewer>
        </span>
      </a-table>

    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
import moment from "moment";
 
export default {
  name: "profitrecievemanagement",//驾校分成到账
  data() {
    return {
      searchData: {
        beginEndReceiveDateList: [],
      },
      beginPaymentDate:"",
      endPaymentDate:"",
      profitReceiveList: [],
      options: [],
      config: config,
      imgList: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "账期起始日期",
          dataIndex: "beginPaymentDate",
          key: "beginPaymentDate",
          width: 200,
        },
        { title: "账期结束日期", dataIndex: "endPaymentDate", key: "endPaymentDate", width: 200 },
        //  {
        //   title: "代理驾校",
        //   dataIndex: "driverSchoolName",
        //   key: "driverSchoolName",
        //   width: 200,
        // },
        {
          title: "分成金额",
          dataIndex: "profitAmt",
          key: "profitAmt",
          width: 200,
        },
        {
          title: "退款",
          dataIndex: "refundAmt",
          key: "refundAmt",
          width: 200,
        },
        {
          title: "预计到账",
          dataIndex: "calAmt",
          key: "calAmt",
          width: 150,
        },
         {
          title: "实际到账",
          dataIndex: "realAmt",
          key: "realAmt",
          width: 150,
        },
         {
          title: "打款凭证",
          dataIndex: "proofInfoUrl",
          key: "proofInfoUrl",
          width: 150,
          scopedSlots: { customRender: 'proofInfoUrl' }, 
        },
         {
          title: "备注信息",
          dataIndex: "remark",
          key: "remark",
          width: 150,
        },
      ],
    };
  },
  components: {
  },
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    reset() {
      this.searchData.beginEndReceiveDateList = [];
      this.beginPaymentDate="";
      this.endPaymentDate="";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.getList();
    },
    // showImg(record){
    //   console.log(record)
    //   var viewer = new Viewer(document.getElementById(item.proofInfoUrl), {
    //     url: config.imgURL+item.publicFileURL,
    //   });
    // },
    getList() {
      if(this.searchData.beginEndReceiveDateList!=null
          &&this.searchData.beginEndReceiveDateList[0]!=null){
        this.beginPaymentDate=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndReceiveDateList[0]._d);
        this.endPaymentDate=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndReceiveDateList[1]._d);
      }else{
            //设定一个默认值
        var currentDate = new Date();
        this.endPaymentDate = Helper.dateFormat("YYYY-mm-dd", currentDate);
        //
        var currentCopyDate = new Date();
        currentCopyDate.setMonth(currentCopyDate.getMonth() - 1);
        this.beginPaymentDate = Helper.dateFormat("YYYY-mm-dd", currentCopyDate);
        //初始化没有日期限制，则设定默认值
        this.searchData.beginEndReceiveDateList = [
          moment(currentCopyDate, "YYYY-MM-DD"),
          moment(currentDate, "YYYY-MM-DD"),
        ];

      }
      //获取驾校到账列表
      ApiConfig.driverSchoolProfitRecieveList({
        driverSchoolNo:Helper.get("loginInfo").belongNo,
        beginPaymentDate:this.beginPaymentDate,
        endPaymentDate:this.endPaymentDate
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }
            //由于viewer对应的图片是数据
            for (let i = 0; i < list.length; i++) {
              list[i].imgList=[];
              list[i].imgList.push(config.imgURL+list[i].proofInfoUrl);
            }
            this.profitReceiveList = list;
            //this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flage, item1) {
      this.$refs["ref_addform"].init(flage, item1); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
     .ant-calendar-picker{
      float: left;
      padding: 0px 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
   
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 10px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>