import Vue from 'vue'
import Router from 'vue-router'
import Default from '@/views/default/index'
import Home from '@/views/home/index'
import Login from '@/views/login/index'
/*****************驾校端********************/
import DTraineeList from '@/views/driverschool/traineemanagement/index'
import DTraineeDetail from '@/views/driverschool/traineedetail/index'
import DTraineePreAddResult from '@/views/driverschool/traineepreadd/index'

import DIncomeList from '@/views/driverschool/incomemanagement/index'
import DExpenseList from '@/views/driverschool/expensemanagement/index'

import DCouponList from '@/views/driverschool/couponmanagement/index'
import DRefundManagement from '@/views/driverschool/refundmanagement/index'

import DeviceList from '@/views/driverschool/devicemanagement/index'
import TimeZoneManagement from '@/views/driverschool/timezonemanagement/index'
import TimeZoneAddManagement from '@/views/driverschool/timezoneadd/index'
//分成到账
import DProfitReceiveManagement from '@/views/driverschool/profitreceivemanagement/index'

//休息日
import RestDateManagement from '@/views/driverschool/restdatemanagement/index'
import RestDateSaveManagement from '@/views/driverschool/restdateaddmanagement/index'


import DStaffList from '@/views/driverschool/staffmanagement/index'
import DRoleList from '@/views/driverschool/staffmanagement/role/index'
import DPermissionManagement from '@/views/driverschool/promissionmanagement/index'
import DInfo from '@/views/driverschool/info/index'
import DInfoEdit from '@/views/driverschool/info/edit'

import StudentScore from '@/views/driverschool/studentscore/index'
import StudentScoreDetaill from '@/views/driverschool/studentscore/scoreDetaill.vue'


/*****************公司端********************/
import CTraineeManagement from '@/views/company/traineemanagement/index'
import CTraineeDetail from '@/views/company/traineedetail/index'
import CSchoolPayManagement from '@/views/company/schoolrecharge/index'
import CSchoolPayDetail from '@/views/company/schoolrechargedetail/index'
import CTraineeRechargeList from '@/views/company/traineerecharge/index'
import CTraineeRechargeDetail from '@/views/company/traineerechargedetail/index'
import CDriverSchoolManagement from '@/views/company/driverschoolmanagement/index'
import AgentList from '@/views/company/agentmanagement/index'
import CStaffList from '@/views/company/staffmanagement/index'
import CRoleManagement from '@/views/company/staffmanagement/role/index'
import CPromissionManagement from '@/views/company/promissionmanagement/index'
import CProfitManagement from '@/views/company/profitmanagement/index'
import CCouponManagement from '@/views/company/couponmanagement/index'
import CAgentRemitManagement from '@/views/company/agentremitmanagement/index'
import CAgentRemitDetail from '@/views/company/agentremitdetail/index'
import CSchoolRemitManagement from '@/views/company/schoolremitmanagement/index'
import CSchoolRemitDetail from '@/views/company/schoolremitdetail/index'
import CRefuntManagement from '@/views/company/refundmanagement/index'

import CSchoolStaffManagement from '@/views/company/schoolstaffmanagement/index'
import CAgentStaffManagement from '@/views/company/agentstaffmanagement/index'

import DeviceVersionManagement from '@/views/company/deviceversionmanagement/index'

import DeviceRegManagement from '@/views/company/deviceregmanagement/index'

import ComplaintList from '@/views/company/traineecomplaint/index'

/*****************代理端********************/
import AgentCouponManagement from '@/views/agent/couponmanagement/index'
import AgentStaffList from '@/views/agent/staffmanagement/index'
import AgentImcomeList from '@/views/agent/incomemanagement/index'
import ARoleManagement from '@/views/agent/staffmanagement/role/index'
import APromissionManagement from '@/views/agent/promissionmanagement/index'
import AgentProfitReceiveList from '@/views/agent/profitreceivemanagement/index'
import AgentRefundManagement from '@/views/agent/refundmanagement/index'

/**************监管部门*************/
import SupTraineeMagement from '@/views/supervision/traineemanagement/index'
import DriverSchoolMagement from '@/views/supervision/driverschoolmanagement/index'
import SupTraineeDetail from '@/views/supervision/traineedetail/index'
import SupDriverSchoolDetail from '@/views/supervision/info/index'

// 解决路由重复报错的
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
const routes = [{
    path: '/',
    component: Default,
    redirect: '/home/index',
    meta: {
        requireAuth: true,
        keepAlive: true,
        title: '虚拟驾校管理平台'
    },
},
{
    path: '/login',
    component: Login,
    name: 'login',
    hidden: true,
    meta: {
        requireAuth: false,
        keepAlive: false,
        title: '登录'
    }
},
{
    path: '/',
    component: Default,
    children: [
        //首页
        {
            path: '/home/index',
            name: 'home',
            component: Home,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '虚拟驾校管理平台'
            },
        },
        /*************驾校端*****************/
        {
            path: '/driverSchool/traineemanagement',
            name: 'traineeList',
            component: DTraineeList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员管理'
            },
        },
        {
            path: '/trainee/detail',
            name: 'traineeDetail',
            component: DTraineeDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员详情'
            },
        },
        {
            path: '/trainee/traineepreadd',
            name: 'traineepreadd',
            component: DTraineePreAddResult,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员预增'
            },
        },
        {
            path: '/driverSchool/incomemanagement',
            name: 'driverSchoolIncomeManagement',
            component: DIncomeList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '收入管理'
            },
        },
        {
            path: '/driverSchool/expensemanagement',
            name: 'driverSchoolExpenseManagement',
            component: DExpenseList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '支出管理'
            },
        },
        {
            path: '/driverSchool/couponmanagement',
            name: 'driverSchoolCouponManagement',
            component: DCouponList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '练车券管理'
            },
        },
        {
            path: '/driverschool/refundmanagement',
            name: 'driverSchoolRefundManagement',
            component: DRefundManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '退款管理'
            },
        },
        {
            path: '/driverSchool/devicemanagement',
            name: 'driverSchoolDeviceManagement',
            component: DeviceList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '设备管理'
            },
        },
        {
            path: '/driverSchool/profitreceivemanagement',
            name: 'driverSchoolProfitReceiveManagement',
            component: DProfitReceiveManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '分成到账'
            },
        },
        {
            path: '/driverSchool/timezonemanagement',
            name: 'driverSchoolTimeZoneeManagement',
            component: TimeZoneManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '预约时间管理'
            },
        },
        {
            path: '/driverSchool/timezoneadd',
            name: 'driverSchoolTimeZoneeAdd',
            component: TimeZoneAddManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '时间配置'
            },
        },
        {
            path: '/driverSchool/restdatemanagement',
            name: 'driverSchoolRestDateManagement',
            component: RestDateManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '预约日期管理'
            },
        },
        {
            path: '/driverschool/restdateadd',
            name: 'driverSchoolRestDateAddManagement',
            component: RestDateSaveManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '预约日期配置'
            },
        },

        {
            path: '/driverSchool/staffmanagement',
            name: 'driverSchoolStaffManagement',
            component: DStaffList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校员工管理'
            },
        },
        {
            path: '/driverschool/rolemanagement',
            name: 'driverSchoolRoleManagement',
            component: DRoleList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校角色管理'
            },
        },
        {
            path: '/driverschool/permissions',
            name: 'driverSchoolPermissionsManagement',
            component: DPermissionManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校权限管理'
            },
        },


        {
            path: '/driverSchool/info',
            name: 'driverSchoolInfo',
            component: DInfo,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校基本信息'
            },
        },
        {
            path: '/driverSchool/editinfo',
            name: 'driverSchoolInfoEdit',
            component: DInfoEdit,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '修改驾校基本信息'
            },
        },
        {
            path: '/driverSchool/exercisescoremanagement',
            name: 'exercisescoremanagement',
            component: StudentScore,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学生成绩'
            },
        },
        {
            path: '/driverSchool/exercisescoremanagementDetaill',
            name: 'exercisescoremanagementDetaill',
            component: StudentScoreDetaill,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学生成绩详情'
            },
        },

        /************公司端 ***************/
        {
            path: '/company/traineemanagement',
            name: 'ctraineemanagement',
            component: CTraineeManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员管理'
            },
        },
        {
            path: '/company/traineemanagement/trainee/detail',
            name: 'ctraineemanagement',
            component: CTraineeDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员管理'
            },
        },

        {
            path: '/company/schoolrecharge',
            name: 'cschoolpay',
            component: CSchoolPayManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校充值统计'
            },
        },
        {
            path: '/company/schoolrechargedetail',
            name: 'cschoolpaydetail',
            component: CSchoolPayDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校充值详情'
            },
        },
        {
            path: '/company/traineerecharge',
            name: 'traineerechage',
            component: CTraineeRechargeList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员充值统计'
            },
        },
        {
            path: '/company/traineerechargedetail',
            name: 'traineerechagedetail',
            component: CTraineeRechargeDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员充值统计'
            },
        },
        {
            path: '/company/couponmanagement',
            name: 'CCouponManagement',
            component: CCouponManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '练车券管理'
            },
        },
        {
            path: '/company/agentremitmanagement',
            name: 'CAgentRemitManagement',
            component: CAgentRemitManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '代理打款管理'
            },
        },
        {
            path: '/company/agentremitdetail',
            name: 'CAgentRemitDetail',
            component: CAgentRemitDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '代理打款详情'
            },
        },
        {
            path: '/company/refundmanagement',
            name: 'CRefuntManagement',
            component: CRefuntManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '退款管理'
            },
        },
        {
            path: '/company/schoolremitmanagement',
            name: 'CSchoolRemitManagement',
            component: CSchoolRemitManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校打款管理'
            },
        },
        {
            path: '/company/schoolremitdetail',
            name: 'CSchoolRemitDetail',
            component: CSchoolRemitDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校打款详情'
            },
        },

        {
            path: '/company/driverschoolmanagement',
            name: 'driverschoolmanagement',
            component: CDriverSchoolManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校管理'
            },
        },
        {
            path: '/company/driverschoolmanagement/driverschool/staffmanagement',
            name: 'cDriverSchoolStaffManagement',
            component: CSchoolStaffManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校员工管理'
            },
        },
        {
            path: '/company/driverschoolmanagement/profitmanagement',
            name: 'profitmanagement',
            component: CProfitManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '分成管理'
            },
        },

        {
            path: '/company/agentmanagement',
            name: 'agentmanagement',
            component: AgentList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '代理管理'
            },
        },

        {
            path: '/company/agent/staffmanagement',
            name: 'cAgentStaffManagement',
            component: CAgentStaffManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校员工管理'
            },
        },
        {
            path: '/company/staffmanagement',
            name: 'staffmanagement',
            component: CStaffList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '员工管理'
            },
        },
        {
            path: '/company/rolemanagement',
            name: 'crolemanagement',
            component: CRoleManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '角色管理'
            },
        },
        {
            path: '/company/permissions',
            name: 'cpromissionmanagement',
            component: CPromissionManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '权限管理'
            },
        },
        {
            path: '/company/versionmanagement',
            name: 'versionmanagement',
            component: DeviceVersionManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '设备软件版本管理'
            },
        },

        {
            path: '/company/deviceregmanagement',
            name: 'deviceregmanagement',
            component: DeviceRegManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '设备登记'
            },
        },
        {
            path: '/company/traineecomplaint',
            name: 'ComplaintList',
            component: ComplaintList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员设备反馈'
            },
        },
        


        /***********代理端 ********************/
        {
            path: '/agent/couponmanagement',
            name: 'agentCouponManagement',
            component: AgentCouponManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '练车券使用情况'
            },
        },
        {
            path: '/agent/staffmanagement',
            name: 'agentStaffmanagement',
            component: AgentStaffList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '代理员工管理'
            },
        },
        {
            path: '/agent/incomemanagement',
            name: 'agentIncomemanagement',
            component: AgentImcomeList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '代理收益管理'
            },
        },
        {
            path: '/agent/profitreceivemanagement',
            name: 'agentProfitManagement',
            component: AgentProfitReceiveList,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '分成到账'
            },
        },
        {
            path: '/agent/refundmanagement',
            name: 'agentRefundManagement',
            component: AgentRefundManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '退款管理'
            },
        },
        {
            path: '/agent/rolemanagement',
            name: 'arolemanagement',
            component: ARoleManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '角色管理'
            },
        },
        {
            path: '/agent/permissions',
            name: 'apromissionmanagement',
            component: APromissionManagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '权限管理'
            },
        },
        /**********监管部门**************/
        {
            path: '/supervision/traineemanagement',
            name: 'supervision',
            component: SupTraineeMagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员一览'
            },
        },
        {
            path: '/supervision/traineemanagement/trainee/detail',
            name: 'traineeDetail',
            component: CTraineeDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '学员详情'
            },
        },
        {
            path: '/supervision/driverschoolmanagement',
            name: 'supervision',
            component: DriverSchoolMagement,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校一览'
            },
        },
        {
            path: '/supervision/driverschoolmanagement/info',
            name: 'supervision',
            component: SupDriverSchoolDetail,
            meta: {
                requireAuth: true,
                keepAlive: true,
                title: '驾校详情'
            },
        },

    ]
},
]

const router = new Router({
    mode: 'hash',
    routes
})

export default router