<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>代理名称:</p>
      <a-input placeholder="请输入" v-model="searchData.agentName" />
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" style="float: right;" @click="showmodal('add','')"> 新增 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="agentList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="viewOne" @click="showmodal('edit',record)">修改</div>
           <div class="viewTwo" @click="statusClick(record, 1)" v-if="record.enable == 0">
            启用
          </div>
          <div class="viewTwo" @click="statusClick(record, 0)" v-if="record.enable == 1">
            停用
          </div>
        </template>

        <template slot="Action" slot-scope="text, record">
          <div class="view" @click="userClick(record)">账号详情</div>
        </template>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
    <AgentAdd ref="ref_addform" @backInit="backInit"/>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import AgentAdd from "./AgentAdd";

export default {
  name: "agentmanagement",
  data() {
    return {
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        agentList: [],
        agentName: ""
      },
      agentList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 40,
        },
        {
          title: "代理",
          dataIndex: "agentName",
          key: "agentName",
           width: 60,
        },
      
        // {
        //   title: "地址",
        //   dataIndex: "addr",
        //   key: "addr",
        //   width: 150,
        // },
        {
          title: "状态",
          dataIndex: "statusDesc",
          key: "statusDesc",
          width: 60  
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width:60
        },
        {
          title: "账号管理",
          key: "Action",
          scopedSlots: { customRender: "Action" },
          width:60,
        },
      ],
    };
  },
  components: {
    AgentAdd,
  },
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    reset() {
      this.searchData.agentName = "";
      this.searchData.agentList = [];
      this.pageObject.pageNum = 1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getList() {
      //代理列表
      ApiConfig.agentList({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        agentName:this.searchData.agentName,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].tradeTime);
            //   list[i].createTimeText = createTimeText;
            // }

            this.agentList = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //状态更改
    statusClick(record, status) {
      console.log(record);
      ApiConfig.agentStatusSave({
        agentNo: record.agentNo,
        enable: status
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("修改状态成功");
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
    //代理用户管理
    userClick(item) {
      //传驾校编号
      Helper.set({agentNo:JSON.stringify(item.agentNo)});
      this.$router.push({
        path: "/company/agent/staffmanagement",
        query: {
          agentNo: item.agentNo,
        },
      });
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width:60px;
      float: left;
    }
    .viewTwo{
      color: #0e9a68;
      cursor: pointer;
      position: relative;
       width: 30px;
      left: 50px;
      top:-11px;
    }
    .viewOne{
      color: #0e9a68;
       top:10px;
       width: 30px;
       position: relative;
       cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 180px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 14px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>