<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>省份:</p>
       <a-select
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body;
          }
        "
        :dropdownStyle="{ overflow: 'auto' }"
        v-model="searchData.proNo"
      >
        <a-select-option
          :value="item.proNo"
          v-for="item in optionProList"
          :key="item.proNo"
        >
          {{ item.proName }}
        </a-select-option>
      </a-select>
      <p>驾校名称:</p>
      <a-input placeholder="请输入" v-model="searchData.driverSchoolName" />
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" style="float: right;" @click="showmodal('add','')"> 新增 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="deviceVersionList"
        :pagination="false"
        :rowKey="(record,index) => index+1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="forceUpdateDesc" slot-scope="text, record">
          <div class="viewOne" v-if="record.isForceUpdate==null||record.isForceUpdate == 0">否</div>
          <div class="viewOne" v-if="record.isForceUpdate == 1">是</div>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="viewOne" @click="showmodal('edit',record)">修改</div>
        </template>
      </a-table>

      <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
    <deviceVersionEdit ref="ref_addform" @backInit="backInit"/>
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import deviceVersionEdit from "./deviceVersionEdit"

export default {
  name: "versionmanagement",
  data() {
    return {
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        proNo:"",
        deviceVersionList: [],
        driverSchoolName: "",
      },
      optionProList:[],//省份列表
      deviceVersionList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 10,
        },
        { title: "省", dataIndex: "proName", key: "proName", width: 80 },       
        {
          title: "驾校名称",
          dataIndex: "driverSchoolName",
          key: "driverSchoolName",
          width: 150,
        },

        { title: "设备编号", dataIndex: "deviceNo", key: "deviceNo", width: 150 },
        {
          title: "版本号",
          dataIndex: "versionNo",
          key: "versionNo",
          width: 80,
        },
        {
          title: "版本代码编号",
          dataIndex: "versionCodeNo",
          key: "versionCodeNo",
          width: 80,
        },
        {
          title: "资源url",
          dataIndex: "resUrl",
          key: "resUrl",
          width: 200,
        },
        {
          title: "强制更新",
          dataIndex: "isForceUpdate",
          key: "isForceUpdate",
          scopedSlots: { customRender: "forceUpdateDesc" },
          width: 60,
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 180
        }
      ],
    };
  },
  components: {
    deviceVersionEdit,
  },
  mounted() {
    this.getProList();
    this.getList();
  },
  created() {
      // TODO:
  },
  methods: {
    reset() {
      this.searchData.driverSchoolName = "";
      this.searchData.proNo="";
      this.pageObject.pageNum = 1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    getProList() {
      ApiConfig.getProList({})
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.optionProList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getList() {
      //设备软件版本列表
      ApiConfig.deviceSoftwareVersionList({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        proNo:this.searchData.proNo,
        driverSchoolName: this.searchData.driverSchoolName,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.records;
            this.deviceVersionList = list;
            this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag,item) {
      this.$refs["ref_addform"].init(flag,item); // this.$refs触发一个组件里的方法
    },
     //状态更改
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 60px;
    }
    .viewOne{
      color: #0e9a68;
       top:10px;
       width: 30px;
       position: relative;
       cursor: pointer;
       
    }
    .viewTwo{
       color: #0e9a68;
       top:-11px;
       width: 30px;
       position: relative;
       left: 50px;
       cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 180px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 14px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>