<template>
  <a-modal :title="titleInfo" :visible="visible" @cancel="handleCancel" :maskClosable="false">
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        v-if="visible"
      >
      <a-form-model-item
          label="手机号"
          prop="bookTraineeMobile"
          ref="bookTraineeMobile"
        >
          <a-input v-model="formData.bookTraineeMobile" disabled/>
        </a-form-model-item>
        <a-form-model-item
          label="练习区间"
          prop="trainTimeZone"
          ref="trainTimeZone"
        >
          <a-input v-model="formData.trainTimeZone" disabled/>
        </a-form-model-item>
        
      <a-form-model-item
          label="原设备"
          prop="deviceNo"
          ref="deviceNo"
        >
          <a-input v-model="formData.deviceNo" disabled/>
        </a-form-model-item>
        <a-form-model-item
          label="新设备"
          prop="newDeviceNo"
          ref="newDeviceNo"
        >
          <a-input v-model="formData.newDeviceNo" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
  <script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";

export default {
  name: "staffmanagement",
  data() {
    return {
      queryObj: {},
      driverSchoolNo: "",
      visible: false,
      loading: false,
      searchData: {
        key: "",
      },
      titleInfo: "",
      formData: {
        bookTraineeMobile:'',
        bookTraineeNo: "",
        bookTrainDate: "",
        trainTimeZone: "",
        bookTimeZoneNo: "",
        deviceNo: "",
        newDeviceNo: "",
      },
      ruleArr: [],
      rules: {
        newDeviceNo: [
          { required: true, message: "请输入编号", trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  methods: {
    init(v, obj) {
      this.visible=true;
      this.titleInfo = "更改设备(当前时段内不可用,进行更改)";
      this.formData.bookTraineeMobile=v.bookTraineeMobile;
      this.formData.bookTraineeNo = v.bookTraineeNo;
      var currentDate = new Date();
      this.formData.bookTrainDate  = Helper.dateFormat("YYYY-mm-dd", currentDate);
      this.formData.trainTimeZone = v.trainTimeZone;
      this.formData.bookTimeZoneNo = v.bookTimeZoneNo;
      this.formData.deviceNo = v.deviceNo;
      this.formData.newDeviceNo = "";
    },
    resetForm() {
      this.formData.newDeviceNo = "";
    },

    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            this.formData.newDeviceNo == null ||
            this.formData.newDeviceNo == ""
          ) {
            this.$message.error("变更的设备不能为空");
            return;
          }
          //有参数传过来
          this.driverSchoolNo = Helper.get("loginInfo").belongNo; //归属驾校

          ApiConfig.changeTraineeBookDevice({
            driverSchoolNo:this.driverSchoolNo,
            traineeNo: this.formData.bookTraineeNo,
            bookTrainDate: this.formData.bookTrainDate,
            bookTimeZoneNo: this.formData.bookTimeZoneNo,
            deviceNo: this.formData.deviceNo,
            newDeviceNo:this.formData.newDeviceNo
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.visible = false;
                this.$message.success(res.data.msg);
                this.$emit("backInit");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              this.$message.error(this.CommonConst.errorMsg);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  mounted() {
    //this.getList();
  },
  created() {
    this.queryObj = this.$route.query;
  },
};
</script>
  <style lang='scss' scoped>
.teachermanagement {
  .view {
    float: left;
    color: #0e9a68;
    cursor: pointer;
    padding: 3px;
  }

  .pagbox {
    text-align: center;
    margin-top: 140px;
  }
  .searchHeader {
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 9px;

    .header-1,
    .header-2,
    .header-3 {
      float: left;
    }

    .header-1 {
      // width: 240px;
      height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      float: left;

      p {
        // width: 40px;
        height: 32px;
        float: left;
        box-sizing: border-box;
        text-align: center;
        line-height: 32px;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        padding-right: 5px;
      }
      p {
        float: left;
        line-height: 32px;
      }

      .ant-input {
        width: 198px;
        height: 32px;
        float: left;
        padding-left: 7px;
        margin-left: 5px;
      }
      .ant-input-affix-wrapper {
        width: 198px;
        height: 32px;
        float: left;
        // padding-left:7px;
      }

      .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-left: 0px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
    }

    .header-2 {
      width: 240px;
      height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      float: left;
      p {
        float: left;
        text-align: center;
        line-height: 32px;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        margin-right: 7px;
      }
      .ant-select-selection--single {
        width: 198px;
        height: 32px;
      }
      margin-left: 15px;
    }

    .header-3 {
      float: left;
      button {
        width: 60px;
        height: 31px;
        text-align: center;
        line-height: 31px;
        border-radius: 2px;
        // font-size:14px;
        outline: none;
        cursor: pointer;
        border: none;
      }

      .fgh {
        margin-left: 8px;
        margin-right: 8px;
      }
      .hgf {
        border: 1px solid #ccc;
        color: #000000;
        font-family: Source Han Sans CN;
        font-weight: regular;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    .apply {
      font-size: 14px;
      padding: 0px 10px;
      float: right;
    }
  }

  .management-content {
    .big-box {
      overflow: hidden;
      .small-box1 {
        width: 100%;
        height: 56px;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        background-color: rgba($color: #ccc, $alpha: 0.2);
        border-bottom: 1px solid #e8e8e8;
        li {
          list-style: none;
          float: left;
          line-height: 56px;
        }

        li:nth-child(1) {
          width: 5%;
          height: 55px;
          text-align: center;
        }
        li:nth-child(2) {
          width: 50%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
        li:nth-child(3) {
          width: 25%;
          height: 55px;
          text-align: center;
          text-indent: 10px;
        }
        li:nth-child(4) {
          width: 20%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
      }

      .small-box2 {
        width: 100%;
        height: 56px;
        margin: 0;
        padding: 0;
        //  background-color:rgba($color: #c968d6, $alpha:0.05);
        li {
          list-style: none;
          float: left;
          line-height: 56px;
          border-bottom: 1px solid #e8e8e8;
          a {
            float: left;
            color: #0e9a68;
          }
          p {
            float: left;
            color: #0e9a68;
          }
        }

        li:nth-child(1) {
          width: 5%;
          height: 55px;
          text-align: center;
        }
        li:nth-child(2) {
          width: 50%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
        li:nth-child(3) {
          width: 25%;
          height: 55px;
          text-align: center;
          text-indent: 10px;
        }
        li:nth-child(4) {
          width: 20%;
          height: 55px;
          text-align: left;
          text-indent: 10px;
        }
      }
    }
  }
}
</style>