<template>
  <div class="basicsettings marginTop20">
    <div class="leftTable">
      <h3>驾校基本信息</h3>
      <div class="heightAuao"></div>
      <a-row>
        <a-col :span="5"> 驾校封面图片： </a-col>
        <a-col :span="21"><img v-if="driverSchoolInfo.img!=null" :src="config.imgURL +'/'+ driverSchoolInfo.img" /> </a-col>
        <div class="heightAuao"></div>
        <a-col :span="5"> 驾校上下班时间： </a-col>
        <a-col :span="21"> {{ driverSchoolInfo.workTime }} </a-col>
        <div class="heightAuao"></div>
        <a-col :span="5"> 驾校电话： </a-col>
        <a-col :span="21"> {{ driverSchoolInfo.contactPhone }} </a-col>
        <div class="heightAuao"></div>
         <a-col :span="5"> VR设备数量： </a-col>
         <a-col :span="21"> {{ driverSchoolInfo.deviceCount }} </a-col>
         <div class="heightAuao"></div>
         <a-col :span="5"> 驾校简介图片： </a-col>
         <a-col :span="21"> 
           <!-- {{ driverSchoolInfo.email }}  -->
           <ul class="imgShowClass">
                    <li style=""
                        v-for="(item) in driverSchoolInfo.imgs"
                        :key="item.id"
                        :data-id="item.id" 
                    >
                    <img :src="config.imgURL + item.imgUrl" />
                    </li>
            </ul>
        </a-col>
         <div class="heightAuao"></div>
         <a-col :span="5"> 驾校简介文字： </a-col>
         <a-col :span="21"> {{ driverSchoolInfo.introduce }} </a-col>
      </a-row>
      <a-button type="primary" class="editPass" @click="editDriverSchool">
        修改
      </a-button>
    </div>
    <div class="rightBtn"></div>
  </div>
</template>

<script>
import Helper from "@/utils/helper";
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
export default {
  name: "driverSchoolInfo",
  data() {
    return {
      formData: {
        email: "",
        pwd: "",
        password: "",
        checkCode: "",
      },
      driverSchoolInfo: {},
      config: config,
      visible: false,
      
    };
  },
  components: {},
  methods: {
    getSchoolInfo() {
      //获取驾校信息
      ApiConfig.driverSchoolInfo({
        driverSchoolNo:Helper.get("loginInfo").belongNo,
      }).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data);
          this.driverSchoolInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    editDriverSchool(){
      this.$router.push({
        path: "/driverschool/editinfo",
        query: {
          flag:'edit',
          driverSchoolNo:Helper.get("loginInfo").belongNo
        },
      });
    }
  },
  mounted() {
    this.getSchoolInfo();
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.imgShowClass{
			font-size: 18px;
			font-weight: bold;
}

.imgShowClass li{
			text-decoration: none;
			list-style: none;//去掉li前面的圆点
			display: inline;//让li横向排列
			border: 1px solid #FFFFFF;
			background-color: #30DDEB;
		}

.marginStyle {
  margin: 10px 0px;
}
.basicsettings {
  width: 94%;
  margin: 40px auto;
  overflow: hidden;
}
.leftTable {
  width: 50%;
  float: left;
}
.rightBtn {
  float: left;
  margin-left: 40px;
  button {
    padding: 0px 20px;
  }
}
.heightAuao {
  clear: both;
  height: 25px;
}
.headerImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  margin-top: 40px;
}
.editPass {
  padding: 0px 25px;
  margin-top: 30px;
}
</style>

