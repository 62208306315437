<template>
  <div class="coursemanagement paddingCon">
     <div class="course-hea">
      <p>购买时间:</p>
      <a-range-picker 
       :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.beginEndBuyDateList" format="YYYY-MM-DD"/>   
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" />
      <p>练车驾校:</p>
      <a-select 
              :getPopupContainer="
                triggerNode => {
                return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
              v-model="searchData.driverSchoolNo">
          <a-select-option :value="item.driverSchoolNo"
            v-for="item in listDriverSchool"
            :key="item.driverSchoolNo"
          >
            {{ item.driverSchoolName }}
          </a-select-option>
        </a-select>

      
      <p>练车时间:</p>
   
      <a-range-picker
              :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
               v-model="searchData.beginEndExerDateList" format="YYYY-MM-DD"/> 
      <p>分成状态:</p> 

        
        <a-select  
             :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              style="width: 150px"
              :dropdownStyle="{  overflow: 'auto' }"
            v-model="searchData.profitStatus" placeholder="请选择分成状态"
            >
              <a-select-option value="1">未分成
              </a-select-option>
              <a-select-option value="2">已分成
              </a-select-option>
        </a-select>
    

      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>

    </div>
    <!--汇总数据-->
    <div class="writeOff">当前核销的金额，
      驾校{{schoolTotalProfitAmt}}元，
      公司{{companyTotalProfitAmt}}元，
      代理{{agentTotalProfitAmt}}元
    </div>
    <div class="notWriteOff">当前未核销的金额，
      驾校{{schoolTotalUnProfitAmt}}元，
      公司{{companyTotalUnProfitAmt}}元，
      代理{{agentTotalUnProfitAmt}}元
      </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="couponList"
        :pagination="false"
        class="table-thead-ma"
        :rowKey="(record,index) => index+1"
      >
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="showmodal(record)">查看</div>
        </template>
      </a-table>
      <div class="paginationBox">
        <a-pagination
          :current="pageObject.current"
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import moment from "moment";

export default {
  name: "agentCouponManagement",//代理券管理
  data() {
    return {
      schoolTotalProfitAmt:0,
      companyTotalProfitAmt:0,
      agentTotalProfitAmt:0,
      schoolTotalUnProfitAmt:0,
      companyTotalUnProfitAmt:0,
      agentTotalUnProfitAmt:0,
      pageObject: {
        pageNum: 1,
        current:1,
        pageSize: 10,
        total: 0,
      },
      visible: false,
      couponList: [],
      searchData:{
          name:"",
          mobile:"",
          beginEndBuyDateList:[],
          beginEndExerDateList:[],
          profitStatus:""
      },
      listDriverSchool:[],
      buyBeginDate:"",
      buyEndDate:"",
      exerBeginDate:"",
      exerEndDate:"",
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 80,
        },
        {
          title: "券码",
          dataIndex: "couponNo",
          key: "couponNo",
          width:100
        },
        { title: "学员名称", dataIndex: "traineeName", key: "traineeName",width:100 },
        { title: "学员手机号", dataIndex: "traineeMobile", key: "traineeMobile" ,width:130},
        { title: "购买时间", dataIndex: "couponTimeText", key: "couponTimeText",width:120 },
        { title: "购买渠道", dataIndex: "buyChannel", key: "buyChannel" ,width:100},
        { title: "充值金额", dataIndex: "couponAmt", key: "couponAmt",width:100 },
        { title: "练车驾校", dataIndex: "driverSchoolName", key: "driverSchoolName",width:100 },
        { title: "所属代理", dataIndex: "agentName", key: "agentName" ,width:100},
        { title: "预约练车日期", dataIndex: "bookTrainDate", key: "bookTrainDate" ,width:130},
        { title: "预约时间段", dataIndex: "bookTimeZone", key: "bookTimeZone",width:120 },
        { title: "出勤状态", dataIndex: "attendStatus", key: "attendStatus",width:100 },
        { title: "分成状态", dataIndex: "profitStatus", key: "profitStatus" ,width:100},
        { title: "驾校分成", dataIndex: "schoolProfitAmtText", key: "schoolProfitAmtText" ,width:100},
        { title: "代理分成", dataIndex: "agentProfitAmtText", key: "agentProfitAmtText" ,width:100},
        { title: "公司分成", dataIndex: "companyProfitAmtText", key: "companyProfitAmtText",width:100 },
        { title: "退款", dataIndex: "refundDesc", key: "refundDesc" ,width:80},
      ],
      tistics: [],
    };
  },
  components: {

  },
  mounted() {
    this.getList();
    this.getSchoolList();
  },
  created() {},
  methods: {
    reset() {
      this.searchData.beginEndExerDateList=[];
      this.searchData.beginEndBuyDateList=[];
      this.searchData.name="";
      this.searchData.mobile="";
      this.searchData.driverSchoolNo="";
      this.searchData.profitStatus="";
      this.buyBeginDate="";
      this.buyEndDate=""; 
      this.exerBeginDate="";
      this.exerEndDate="";
      this.pageObject.pageNum=1;
      this.pageObject.pageSize=10;
      this.pageObject.current=1;
      this.getList();
    },
    search() {
      this.pageObject.pageNum=1;
      this.pageObject.pageSize=10;
      this.pageObject.current=1;
      this.getList();
    },

    handleOk(e) {
      
    },
    handleCancel(e) {
      this.visible = false;
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.pageObject.current = page;
      this.getList();
    },
    showmodal(item1) {
      this.$refs["ref_view"].init(item1); // this.$refs触发一个组件里的方法
    },
    //获取该代理下的驾校
    getSchoolList(){
         ApiConfig.getSchoolListByAgent({
            agentNo:Helper.get("loginInfo").belongNo,
         })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.listDriverSchool = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getList() {
      //购买时间范围
      if(this.searchData.beginEndBuyDateList!=null
         &&this.searchData.beginEndBuyDateList[0]!=null){
          this.buyBeginDate=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndBuyDateList[0]._d);
          this.buyEndDate=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndBuyDateList[1]._d);
      }else{
                 //设定一个默认值
        var currentDate = new Date();
        this.buyEndDate = Helper.dateFormat("YYYY-mm-dd", currentDate);
        //
        var currentCopyDate = new Date();
        currentCopyDate.setMonth(currentCopyDate.getMonth() - 1);
        this.buyBeginDate = Helper.dateFormat("YYYY-mm-dd", currentCopyDate);
        //初始化没有日期限制，则设定默认值
        this.searchData.beginEndBuyDateList = [
          moment(currentCopyDate, "YYYY-MM-DD"),
          moment(currentDate, "YYYY-MM-DD"),
        ];
      }
      //练车时间范围
      if(this.searchData.beginEndExerDateList!=null
         &&this.searchData.beginEndExerDateList[0]!=null){
          this.exerBeginDate=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndExerDateList[0]._d);
          this.exerEndDate=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndExerDateList[1]._d);
      }
      //获取券使用情况
      ApiConfig.getCouponInfoBySchool({
        agentNo:Helper.get("loginInfo").belongNo,
        driverSchoolNo:this.searchData.driverSchoolNo,
        name:this.searchData.name,
        mobile:this.searchData.mobile,
        buyBeginDate:this.buyBeginDate,
        buyEndDate:this.buyEndDate,
        exerBeginDate:this.exerBeginDate,
        exerEndDate:this.exerEndDate,
        profitStatus:this.searchData.profitStatus,
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
      })
        .then((res) => {
          if (res.data.code == 200) {
            //返回值包含了分页数据以及汇总数据
            var list = res.data.data.pageList.records;
            for (let i = 0; i < list.length; i++) {
              var couponTimeText = Helper.FormatToDate(list[i].couponTime,true);
              list[i].couponTimeText = couponTimeText;
              //显示百分比
              var schoolProfitAmtText = list[i].schoolProfitAmt+"("+list[i].schoolProfitRatio*100+"%)";
              var agentProfitAmtText = list[i].agentProfitAmt+"("+list[i].agentProfitRatio*100+"%)";
              var companyProfitAmtText = list[i].companyProfitAmt+"("+list[i].companyProfitRatio*100+"%)";
              list[i].schoolProfitAmtText = schoolProfitAmtText;
              list[i].agentProfitAmtText = agentProfitAmtText;
              list[i].companyProfitAmtText = companyProfitAmtText;
           
            }
            this.couponList = list;
            this.pageObject.total = res.data.data.pageList.total;
            //显示相关汇总数据
            var totalInfo=res.data.data.sumary;
            if(totalInfo!=null){
              console.log(totalInfo.profit);
              if(totalInfo.unprofit!=null){
                  this.schoolTotalUnProfitAmt=totalInfo.unprofit.schoolProfitAmt;
                  this.companyTotalUnProfitAmt=totalInfo.unprofit.companyProfitAmt;
                  this.agentTotalUnProfitAmt=totalInfo.unprofit.agentProfitAmt;
              }
              if(totalInfo.profit!=null){
                  this.schoolTotalProfitAmt=totalInfo.profit.schoolProfitAmt;
                  this.companyTotalProfitAmt=totalInfo.profit.companyProfitAmt;
                  this.agentTotalProfitAmt=totalInfo.profit.agentProfitAmt;
              }
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.writeOff{
  margin-top: 20px;
  margin-left: 10px;
  font-size: 16px;
}
.notWriteOff{
  font-size: 16px;
  margin-left: 10px;
}
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 150px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
     .ant-calendar-picker{
      float: left;
      padding: 0px 5px;
      width: 220px;
      position: relative;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 150px;
       position: relative;
    }
   
    .ant-select-selection--single {
      width: 80px;
      height: 32px;
       position: relative;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    .fixexpand{
      height: auto;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
       margin-left: 10px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
