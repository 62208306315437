<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="timeZoneClick()"> 配置预约时间 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      
      <a-table
        :columns="columns"
        :data-source="timezoneList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
      {{titleFuture}}
      <div v-if="showFuture">
        <a-table id="futureTb"
          :columns="fcolumns"
          :data-source="timezoneFutureList"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <a slot="name" slot-scope="text">{{ text }}</a>
        </a-table>
      </div>
      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
      <div>*该页面配置每天学员可以进行练习的时间段。</div>
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
export default {
  name: "driverschoolincomemanagement",
  data() {
    return {
      titleFuture:'', 
      isExistValid:'0',
      timezoneList: [],
      timezoneFutureList: [],
      showFuture:false,
      columns: [
        // {
        //   title: "序号",
        //   dataIndex: "index",
        //   key: "index",
        //   customRender: (text, record, index) => index + 1,
        //   width: 70,
        // },
        // {
        //   title: "编号",
        //   dataIndex: "timeZoneNo",
        //   key: "timeZoneNo",
        //   width: 200,
        // },
        {
          title: "时间区间",
          dataIndex: "trainTimeZone",
          key: "trainTimeZone",
          width: 100,
        },
        // {
        //   title: "顺序号",
        //   dataIndex: "sort",
        //   key: "sort",
        //   width: 200,
        // },
        {
          title: "生效开始日期",
          dataIndex: "enableBeginDate",
          key: "enableBeginDate",
          width: 100,
        },
        {
          title: "生效结束日期",
          dataIndex: "enableEndDate",
          key: "enableEndDate",
          width: 100,
        },
      ],
      fcolumns: [
        // {
        //   title: "序号",
        //   dataIndex: "index",
        //   key: "index",
        //   customRender: (text, record, index) => index + 1,
        //   width: 70,
        // },
        // {
        //   title: "编号",
        //   dataIndex: "timeZoneNo",
        //   key: "timeZoneNo",
        //   width: 200,
        // },
        {
          title: "时间区间",
          dataIndex: "trainTimeZone",
          key: "trainTimeZone",
          width: 200,
        },
        // {
        //   title: "顺序号",
        //   dataIndex: "sort",
        //   key: "sort",
        //   width: 200,
        // },
        {
          title: "生效开始日期",
          dataIndex: "enableBeginDate",
          key: "enableBeginDate",
          width: 150,
        },
        {
          title: "生效结束日期",
          dataIndex: "enableEndDate",
          key: "enableEndDate",
          width: 150,
        },
      ]
    };
  },
  components: {
  },
  mounted() {
    this.getList();
    this.getFutureList();
  },
  created() {},
  methods: {
    reset() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    getList() {
      //获取驾校时间区间列表
      ApiConfig.driverSchoolBookTimeZoneList({
        driverSchoolNo: Helper.get("loginInfo").belongNo,
        //createBy: Helper.get("loginInfo").account,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.timezoneList = list;
            if(list.length>0){
              this.isExistValid="1";//存在有效数据
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getFutureList() {
      //获取驾校时间区间列表
      ApiConfig.driverSchoolBookTimeZoneFutureList({
        driverSchoolNo:Helper.get("loginInfo").belongNo,
        //createBy: Helper.get("loginInfo").account,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            console.log(list);
            if(list!=null&&list.length>0){
              this.showFuture=true;
              var info=list[0].enableBeginDate;
              this.timezoneFutureList = list;
              this.titleFuture=info+'后按照以下规则执行';
            }else{
              //隐藏未来生效的时间区间数据
              this.showFuture=false;
              this.titleFuture="";
            }
            
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    backInit() {
      this.getList();
      this.getFutureList();
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    timeZoneClick(item) {
      this.$router.push({
        path: "/driverschool/timezoneadd",
        query: {
          driverSchoolNo:Helper.get("loginInfo").belongNo,//item.driverSchoolNo
          isExistValid:this.isExistValid
        },
      });
    },
    showmodal(flag) {
      this.$refs["ref_addform"].init(flag); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 120px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>