<!--
 * @Author: your name
 * @Date: 2021-11-15 09:48:41
 * @LastEditTime: 2021-11-15 11:06:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-driver-web\src\views\company\deviceregmanagement\DeviceAdd.vue
-->
<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
   
        <a-form-model-item label="驾校" prop="driverSchoolNo" ref="driverSchoolNo">
          <a-select 
          v-model="formData.driverSchoolNo" placeholder="请选择驾校">
            <a-select-option
              :value="item.driverSchoolNo"
              v-for="(item, index) in optionsSchoolList"
              :key="index"
              >{{ item.driverSchoolName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备编号" prop="deviceNo" ref="deviceNo">
          <a-input
            :maxLength="80"
            placeholder="请输入设备编号"
            v-model="formData.deviceNo"
          />
        </a-form-model-item>
        <a-form-model-item label="设备名称" prop="deviceName" ref="deviceName">
          <a-input
            :maxLength="80"
            placeholder="请输入设备名称"
            v-model="formData.deviceName"
          />
        </a-form-model-item>
        <a-form-model-item label="设备描述" prop="deviceDesc" ref="deviceDesc">
          <a-input
            :maxLength="80"
            placeholder="请输入设备描述"
            v-model="formData.deviceDesc"
          />
        </a-form-model-item>
    </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk"> 确定 </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
//import config from "@/config";
//import Helper from "@/utils/helper";

export default {
  components: {
  },
  data() {
    
    return {
      titleInfo: "",
      visible: false,
      loading: false,
      flag:'',
      rules: {
        deviceNo: [
          { required: true, message: "请输入设备编号", trigger: "change" }
        ],
        deviceName: [
          { required: true, message: "请输入设备名称", trigger: "change" }
        ],
      },
      optionsSchoolList:[],
      formData: {
        driverSchoolNo: "",
        deviceNo:"",
        deviceName:"",
        deviceDesc:""
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v,obj) {
      this.resetForm();
      this.visible = true;
      this.flag=v;
      this.titleInfo="新增驾校设备信息";
      this.getSchoolList();
    },
    getSchoolList(){
        ApiConfig.getSchoolList({
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.optionsSchoolList= res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    resetForm() {
      this.formData = {
        driverSchoolNo:"",
        deviceNo: "",
        deviceName: "",
        deviceDesc:"",
      };
    },
 
    handleSelectChange(value) {
      console.log(value);
    },
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (this.formData.deviceNo ==""
        ||this.formData.driverSchoolNo==""
        ||this.formData.deviceName=="") {
          return;
        }
 
        if (valid) {
           //保存
           ApiConfig.deviceSave({
            driverSchoolNo:this.formData.driverSchoolNo,
            deviceNo:this.formData.deviceNo,
            deviceName: this.formData.deviceName,
            deviceDesc: this.formData.deviceDesc,
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.visible = false;
                this.$emit("backInit");
              } else {
                this.$message.error("提交失败");
              }
            })
            .catch(() => {
              this.$message.error(this.CommonConst.errorMsg);
            });
           
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    

    handleCancel(e) {
      this.visible = false;
    },
  },
  created() {},
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>