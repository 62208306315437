<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        v-if="visible"
      >
        <a-form-model-item label="姓名" prop="name" ref="name">
          <a-input
            :maxLength="20"
            :disabled="flag == 'recharge'"
            placeholder="请输入学员姓名"
            v-model="formData.name"
          />
        </a-form-model-item>

        <a-form-model-item label="手机号" prop="mobile" ref="mobile">
          <a-input
            :maxLength="15"
            :disabled="flag == 'recharge'"
            placeholder="请输入学员手机号"
            v-model="formData.mobile"
            @blur="handleMobile"
          />
        </a-form-model-item>
        <a-form-model-item label="身份证" prop="idCardNo" ref="idCardNo">
          <a-input
            :maxLength="20"
            :disabled="flag == 'recharge'"
            placeholder="请输入学员身份证"
            v-model="formData.idCardNo"
          />
        </a-form-model-item>
        <a-form-model-item label="其他信息" prop="extInfo" ref="extInfo">
          <a-input
            :maxLength="50"
            :disabled="flag == 'recharge'"
            placeholder="请输入学员其他信息"
            v-model="formData.extInfo"
          />
        </a-form-model-item>

        <a-form-model-item
          label="VR练车券"
          prop="couponCount"
          ref="couponCount"
        >
          <a-input-number
            :disabled="isShowQrCode"
            :max="100"
            :min="0"
            :precision="0"
            placeholder="数量"
            v-model="formData.couponCount"
            @blur="handleTotalAmtOK"
          />&nbsp;张
        </a-form-model-item>

        <a-form-model-item label="实付单价" prop="onePrice" ref="onePrice">
          {{ formData.onePrice }}元
        </a-form-model-item>

        <a-form-model-item label="共需支付" prop="totalAmt" ref="totalAmt">
          {{ formData.totalAmt }}元
        </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel" v-if="!isShowQrCode">取消</a-button>
        <a-button type="primary" @click="handleConfirm" v-if="!isShowQrCode">
          确定
        </a-button>
      </div>
    </template>
    <div
      id="qrcode"
      ref="qrcode"
      style="display: flex; justify-content: center"
    ></div>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import QRCode from "qrcodejs2";
//注意当使用身份证读卡器，需要本地启动读卡器服务
//import sdk from "../../../static/idcard/sdk";
import sdk from "@/utils/idcard/sdk-websocket";


export default {
  components: {
    QRCode,
  },
  data() {
    let validateCouponCount = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入练车券"));
      } else {
        if (!/(^[0-9]\d*$)/.test(value)) {
          this.formData.onePrice = 0;
          this.formData.totalAmt = 0;
          callback(new Error("请输入数字类型"));
        } else if (this.flag == "recharge") {
          if (!/(^[1-9]\d*$)/.test(value)) {
            this.formData.onePrice = 0;
            this.formData.totalAmt = 0;
            callback(new Error("请输入数字,练车券必须大于0"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    return {
      timerCount: 0,
      orderNo: "",
      titleInfo: "单独录入学员",
      visible: false,
      loading: false,
      isShowQrCode: false,
      myInterval: {},
      flag: "",
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        // idCardNo: [
        //   { required: true, message: "请输入身份证", trigger: "blur" },
        // ],
        couponCount: [
          { required: true, validator: validateCouponCount, trigger: "blur" },
        ],
      },
      formData: {
        name: "",
        mobile: "",
        couponCount: 1,
        onePrice: 0,
        totalAmt: 0,
        traineeNo: "",
        idCardNo: "",
        extInfo: "",
      },
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(v, obj) {
      this.isShowQrCode = false;
      //清除对应的原先图片
      if (this.$refs.qrcode != undefined) {
        this.$refs.qrcode.innerHTML = "";
      }
      //this.$refs.qrcode.innerText="";
      this.visible = true;
      this.flag = v; //判断是否充值
      if (v != " ") {
        this.titleInfo = "单独录入（未注册）或充值";
      }
      this.resetForm();
      if (obj != null) {
        this.formData.name = obj.name;
        this.formData.mobile = obj.mobile;
        this.formData.traineeNo = obj.traineeNo;
        this.formData.idCardNo = obj.idCardNo;
        this.formData.extInfo = obj.extInfo;
      }
      //计算相关栏位的金额等
      if (this.formData.couponCount != "" && this.formData.couponCount >= 1) {
        this.handleTotalAmtOK();
      }
      //初始载入，自动进行读卡
      if(this.flag != "recharge"){
        this.readCard()
      }
    },
    //读卡
    readCard(){
      sdk.connect(this.connectedCallBack,this.callback);
    },
    //连接成功回到
    connectedCallBack(res){
      if(res==1){
        sdk.ReadIDCardNoJudge(); 
      }
    },
    //获取到数据回调
    callback(res){
      console.log('callback',res)
      if(res!=null&&res.partyName!=null){
        this.formData.name=res.partyName;
        this.formData.idCardNo=res.certNumber;
      }else{
        this.formData.name="";
        this.formData.idCardNo="";
      }
    },
    closeReadCard(){
      sdk.closeConnect()
    },
    resetForm() {
      this.formData = {
        name: "",
        mobile: "",
        idCardNo: "",
        extInfo: "",
        couponCount: 0,
        onePrice: 0,
        totalAmt: 0,
      };
      this.isShowQrCode = false;
    },
    handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (
          this.formData.name == "" ||
          this.formData.mobile == "" ||
          this.formData.couponCount < 0
        ) {
          this.$message.error("请检查输入信息");
          return;
        }
        if (this.formData.couponCount == 0) {
          this.formData.onePrice = 0;
          this.formData.totalAmt = 0;
        }
        if (valid) {
          if (this.flag == "recharge") {
            //充值
            ApiConfig.traineeRechageAdd({
              orderNo: this.orderNo,
              traineeNo: this.formData.traineeNo,
              couponCount: this.formData.couponCount,
              realOneAmt: this.formData.onePrice,
              driverSchoolNo: Helper.get("loginInfo").belongNo,
            })
              .then((res) => {
                if (res.data.code == 200) {
                  //
                  this.orderNo = res.data.data.rechargeNo;
                  if (
                    res.data.data.payStatus != null &&
                    res.data.data.payStatus == "1"
                  ) {
                    //无需支付，由于驾校设定当驾校充值支付金额为0
                    //隐藏当前弹出页面
                    this.visible = false;
                    //刷新父页面
                    this.$emit("backInit");
                  } else {
                    //接口返回图片，或者给一个链接客户端生成二维码
                    if (res.data.data.code_url != null) {
                      this.isShowQrCode = true;
                      this.qrcode = new QRCode(this.$refs.qrcode, {
                        width: 100, // 二维码宽度
                        height: 100, // 二维码高度
                        text: res.data.data.code_url,
                        correctLevel: QRCode.CorrectLevel.H,
                      });
                      this.timerCount = 0;
                      this.startTimer();
                      //调用轮询，请求服务端（暂不用服务端推送）,超时时间2分钟，5s查询一次
                      //res.data.data.rechargeNo
                    } else {
                      console.log(res.data);
                      this.$message.success("请求微信支付失败");
                      this.visible = false;
                    }
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch(() => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          } else {
            if (
              this.formData.idCardNo == null ||
              this.formData.idCardNo == ""
            ) {
              this.$message.error("请检查身份证信息");
              return;
            }
            //预增
            ApiConfig.traineePreAdd({
              traineeName: this.formData.name,
              traineeMobile: this.formData.mobile,
              couponCount: this.formData.couponCount,
              idCardNo: this.formData.idCardNo,
              extInfo: this.formData.extInfo,
              //需要计算处理的栏位
              realOneAmt: this.formData.onePrice,
              driverSchoolNo: Helper.get("loginInfo").belongNo,
            })
              .then((res) => {
                if (res.data.code == 200) {
                  
                  this.$message.success("保存成功");
                  //刷新父页面数据（当练车券为0）
                  if (this.formData.couponCount == 0) {
                    this.$emit("backInit");
                    this.resetForm();
                  } else {
                    this.visible = false;
                    this.closeReadCard();
                    //跳转到预存学员的界面
                    //跳转预导入页面
                    this.$router.push({
                      path: "/trainee/traineepreadd",
                      query: {
                        batchNo: res.data.data.batchNo,
                      },
                    });
                  }
                } else {
                  this.$message.error(
                    "导入失败，已自动下载失败原因表格。请改正后重新上传。"
                  );
                  this.$message.error(res.data.msg);
                }
              })
              .catch(() => {
                this.$message.error(this.CommonConst.errorMsg);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //定时请求服务器，获取信息
    //启动定时器
    startTimer() {
      let timer = setInterval(() => {
        this.execGetStatus(timer);
      }, 3000); //间隔请求时间
    },
    //定时器间隔执行的任务
    execGetStatus(timer) {
      setTimeout(() => {
        this.timerCount = this.timerCount + 1;
        // 这里ajax 请求的代码片段和判断是否停止定时器
        let result = this.getPayResultInfo(this.orderNo);
        console.log("获取支付结果", result, this.timerCount);
        // 如需要停止定时器，只需加入以下：
        if (result || !this.isShowQrCode || this.timerCount >= 60) {    
          //隐藏当前弹出页面
          if(this.flag=="recharge"){
            this.visible = false;
          }
          this.resetForm();
          //刷新父页面
          this.$emit("backInit");
          clearInterval(timer);
          console.log("定时器清除");
          this.timerCount = 0;
          if(!this.isShowQrCode){
            this.$message.success("支付处理结束,请检查练车券");
          }
        } else {
          console.log("定时器执行中");
        }
      }, 0);
    },
    getNewMessage: function () {
      console.log("请求" + this.num++ + "次");
    },
    handleCancel() {
      this.visible = false;
      this.titleInfo = "单独录入学员";
      this.isShowQrCode = false;
      //关闭自动读卡
      this.closeReadCard();
    },
    //处理手机号
    handleMobile() {
      if (this.formData.mobile == "") {
        return;
      }
      this.loading = true;
      ApiConfig.checkTraineeMobile({
        mobile: this.formData.mobile,
      })
        .then((res) => {
          if (res.data.code == 200) {
            //不存在
            if (this.flag == "recharge") {
              this.$message.error("手机号不存在");
              this.formData.mobile = "";
            }
          } else {
            //存在
            if (this.flag != "recharge") {
              this.$message.error(res.data.msg);
              this.formData.mobile = "";
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    // 处理用户输入练车券，计算实付单价以及共需总金额
    handleTotalAmtOK() {
      console.log(this.formData.couponCount);
      if (this.formData.couponCount == "" || this.formData.couponCount <= 0) {
        this.formData.onePrice = 0;
        this.formData.totalAmt = 0;
        return;
      }

      this.loading = true;

      ApiConfig.calDriverSchoolPayByCouponCount({
        driverSchoolNo: Helper.get("loginInfo").belongNo,
        couponCount: this.formData.couponCount,
      })
        .then((res) => {
          if (res.data.code == 200) {
            //赋值
            this.formData.onePrice = res.data.data.onePrice;
            this.formData.totalAmt = res.data.data.totalAmt;
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //获取驾校给学员充值的结果（是否成功支付）
    getPayResultInfo(orderNo) {
      if (orderNo == undefined || orderNo == null) {
        return null;
      }
      ApiConfig.traineePayResultInfo({
        orderNo: orderNo,
      })
        .then((res) => {
          console.log("=====定时请求中=====");
          if (res.data.code == 200) {
            //支付成功
            if (
              res.data.data.payStatus != null &&
              res.data.data.payStatus == "1"
            ) {
              
              this.isShowQrCode = false;
              return true;
            }
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
      return false;
    },
  },

  created() {
    //计算相关栏位的金额等
    if (
      this.formData.couponCount != null &&
      this.formData.couponCount != "" &&
      this.formData.couponCount >= 1
    ) {
      this.handleTotalAmtOK();
    }
  },
  activated() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>