<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
        <p>代理:</p>
      <a-select 
      :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.agentNo">
          <a-select-option :value="item.agentNo"
            v-for="item in agentList"
            :key="item.agentNo">
            {{ item.agentName }}
          </a-select-option>
        </a-select> 
       

      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="agentRemitRecieveList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="showDetail(record)">查看</div>
        </template>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
export default {
  name: "agentProfitRecieve",//给代理打款分成
  data() {
    return {
      queryObj: {},
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        agentNo:"",
      },
      agentList:[],
      agentRemitRecieveList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 50
        },
        {
          title: "代理名称",
          dataIndex: "agentName",
          key: "agentName",
          width: 50
        },
        { title: "已打款金额", dataIndex: "agentRemitTotalAmt", key: "agentRemitTotalAmt", width: 50 },
        {
          title: "操作",
          key: "action",
          width: 10,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
  },
  mounted() {
    this.getAgentList();
    this.getList();
  },
  created() {
  },
  methods: {
    reset() {
      this.searchData.agentNo = "";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.getList();
    },
     //获得代理列表
    getAgentList(){
      ApiConfig.getAgentList({
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.agentList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getList() {
      //给代理汇总打款记录
      ApiConfig.agentRemitReceiveSumaryList({
        agentNo:this.searchData.agentNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].createTimeText = createTimeText;
            // }

            this.agentRemitRecieveList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    showDetail(item){
        this.$router.push({
        path: "/company/agentremitdetail",
        query: {
          agentNo: item.agentNo,
        },
      });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
  
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 30px;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 6px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 10px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>