<template>
  <a-checkbox-group @change="onChange" class="pathen">
    <div class="sd">
      <div class="sd1">
        <div>
          考核次数:<span class="sd1-1">{{ dataList.length }}</span
          >次
        </div>
        <div>
          <a-button type="primary" @click="ExportSavePdf()"> 导出成绩</a-button>
        </div>
      </div>

      <!--各个时间段多笔数据-->
      <div
        class="sd2"
        v-for="(oneTimeZoneItem, index) in dataList"
        :key="index"
      >
        <div class="put_bus" :id="'div--' + index">
          <div class="sd2-1">
            <div>
              {{ oneTimeZoneItem.bookTrainDate }}
              {{ oneTimeZoneItem.trainTimeZone }}
            </div>
            <div class="sd2-3">
              <a-checkbox
                :value="index"
                @change="activeItem(index)"
              ></a-checkbox>
            </div>
          </div>
          <!--一个时间段提交成绩列表-->

          <!-- 遍历成绩列表 -->
          <div v-for="(er, index1) in oneTimeZoneItem.scoreList" :key="index1">
            <!--科目二成绩数据-->
            <div v-if="er.score2DetailList != null">
              <div class="sd2-2">
                <div class="pub">
                  <div class="pub1"></div>
                  <div class="pub2">{{ er.examName }}</div>
                </div>
              </div>
              <div class="sd2-3">
                考试时长：<span class="sd2-4">{{ er.useTime }}分钟</span>
              </div>
              <div class="sd2-3">
                总得分：<span class="sd2-5">{{ er.score }}</span
                >分
              </div>
              <!--科目2下面的各个子项目遍历-->
              <div class="sd3">
                <div
                  v-for="(erson, index2) in er.score2DetailList"
                  :key="index2"
                >
                  <div class="sd3-1">
                    <div class="left1">
                      <div class="left2">{{ erson.deductName }}</div>
                      <div class="left3">{{ erson.realScore }}</div>
                    </div>
                    <div class="rightP">
                      <div
                        class="right1"
                        v-for="(ss, index9) in erson.detailList"
                        :key="index9"
                      >
                        <div>
                          {{ index9 + 1 }}.{{ ss.deductDetail }}扣{{
                            ss.deductScore
                          }}分
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 科目三成绩数据 -->
            <div v-if="er.score3DetailList != null">
              <div class="pub tops">
                <div class="pub1"></div>
                <div class="pub2">科目三</div>
              </div>
              <div class="sd2-3">
                考试时长：<span class="sd2-4">{{ er.useTime }}分钟</span>
              </div>
              <div class="sd2-3">
                总得分：<span class="sd2-5">{{ er.score }}</span
                >分
              </div>
              <div class="sd2-3">扣分详情：</div>
              <div v-for="(san, index8) in er.score3DetailList" :key="index8">
                <div class="sd2-3">
                  <div class="sd2-4">
                    {{ index8 + 1 }}{{ san.deductDetail }}扣{{
                      san.deductScore
                    }}分
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-checkbox-group>
</template>

<script>
import { downloadPDF } from "@/utils/htmlToPdf.js"
import ApiConfig from "@/api/apiConfig.js"
import Helper from "@/utils/helper"
// import config from "@/config"
export default {
  data () {
    return {
      traineeNo: this.$route.query.traineeNo,
      traineeName: this.$route.query.traineeName,
      driverSchoolNo: '',
      dataList: [],
      boardPdf: '',
      boardPdfList: []
    }
  },

  components: {},

  computed: {},
  mounted () {
    this.getscoreDetaill()
  },

  methods: {
    onChange (checkedValues) {
      console.log('checked = ', checkedValues)
    },
    activeItem (index) {

      // if (this.boardPdfList.indexOf("#div--" + index) != 0) {
      //   this.boardPdfList.splice(this.boardPdfList.indexOf("#div--" + index), 1)
      // } else {
      //   this.boardPdfList.push("#div--" + index)
      // }
      this.boardPdfList.push("#div--" + index)

    },
    // 打印的区域
    ExportSavePdf () {
      console.log('sdfsdfsdfsdfsdfdf')
      let ss = [...this.boardPdfList]
      ss.forEach(t => {
        console.log('pdf----', t)
        downloadPDF(document.querySelector(t), this.traineeNo
          + '-' + this.traineeName + '-'
          + (Math.ceil(Math.random() * 1000) + 500) + ".pdf")
      })
    },
    // 获取考试成绩数据
    getscoreDetaill () {
      //有参数传过来
      this.driverSchoolNo = Helper.get("loginInfo").belongNo //归属驾校

      //驾校获取员工练车记录
      ApiConfig.getListExerBySchoolForWeb({
        traineeNo: this.traineeNo,
        driverSchoolNo: this.driverSchoolNo//Helper.get("loginInfo").belongNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            //console.log('成绩', res.data.data)
            var list = res.data.data
            // for (let i = 0; i < list.length; i++) {
            //   var createTimeText = Helper.FormatToDate(list[i].createTime);
            //   list[i].key = createTimeText;
            // }

            this.dataList = list

          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg)
        })
    },





  }
}
</script>
<style lang='scss' scoped>
.pathen {
  width: 100%;
}
.pub {
  display: flex;
  align-items: center;
  .pub1 {
    width: 4px;
    height: 18px;
    background: #0e9a68;
    margin-right: 8px;
  }
  .pub2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
}

.sd {
  padding: 24px;
  box-sizing: border-box;
  .sd1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666666;
    .sd1-1 {
      color: #0e9a68;
    }
  }
  .sd2 {
    margin-top: 16px;
    // height: 749px;
    background: #ffffff;
    border: 1px solid #0e9a68;
    border-radius: 10px;
    padding: 12px 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
    .sd2-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #0e9a68;
    }
    .sd2-2 {
      margin-top: 15px;
    }
  }
  .sd3 {
    display: flex;
    flex-wrap: wrap;
    .sd3-1 {
      width: 420px;
      height: 108px;
      background: #fafafa;
      border: 1px solid #f0f0f0;
      border-radius: 6px;
      margin-top: 19px;
      margin-right: 16px;
      display: flex;
      .left1 {
        width: 105px;
        border-right: 2px solid rgba(0, 0, 0, 0.1);
        text-align: center;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
      }
      .left2 {
        height: 54px;
        padding-top: 15px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      }
      .left3 {
        color: red;
        padding-top: 15px;
      }
      .rightP {
        box-sizing: border-box;
        padding: 12px 24px;
      }
    }
  }
}
.sd2-3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  margin-top: 12px;
}
.sd2-4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
.sd2-5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #ff4040;
}
.tops {
  margin-top: 24px;
}
</style>