<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <!-- <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" /> -->
      <p>日期范围:</p>
      <a-range-picker 
       :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.beginEndDateList" format="YYYY-MM-DD"/>
     

      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="expenseList"
        :pagination="false"
        :rowKey="(record,index) => index+1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="action" slot-scope="text, record">
          <div class="view" @click="moreClick(record)">查看</div>
        </template>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import moment from "moment";
export default {
  name: "expensemanagement",
  data() {
    return {
      pageObject: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {
        expenseList: [],
        name: "",
        mobile: "",
        beginEndDateList:[],
      },
      expenseList: [],
      options: [],
      beginDate:"",
      endDate:"",
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "练车券张数",
          dataIndex: "couponCount",
          key: "couponCount",
          width: 200,
        },
        { title: "课程单价", dataIndex: "realOneAmt", key: "realOneAmt", width: 200 },
        {
          title: "驾校实付",
          dataIndex: "realPayAmt",
          key: "realPayAmt",
          width: 200,
        },
        {
          title: "时间",
          dataIndex: "tradeTimeText",
          key: "tradeTimeText",
          width: 200,
        },
        {
          title: "操作人",
          dataIndex: "createUser",
          key: "createUser",
          width: 200,
        },
        {
          title: "流水号",
          dataIndex: "rechargeNo",
          key: "rechargeNo",
          width: 230,
        },
        {
          title: "明细",
          key: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
  },
  mounted() {
    this.getList();
  },
  created() {},
  methods: {
    reset() {
      this.searchData.mobile = "";
      this.searchData.name = "";
      this.searchData.expenseList = [];
      this.pageObject.pageNum = 1;
      this.searchData.beginEndDateList=[];
      this.beginDate="";
      this.endDate="";
      this.getList();
    },
    search() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
     dateFormat(fmt, date) {
      let ret;
      const opt = {
          "Y+": date.getFullYear().toString(),        // 年
          "m+": (date.getMonth() + 1).toString(),     // 月
          "d+": date.getDate().toString(),            // 日
          "H+": date.getHours().toString(),           // 时
          "M+": date.getMinutes().toString(),         // 分
          "S+": date.getSeconds().toString()          // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
          ret = new RegExp("(" + k + ")").exec(fmt);
          if (ret) {
              fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
          };
      };
      return fmt;
    },
    getList() {
      if(this.searchData.beginEndDateList!=null&&
        this.searchData.beginEndDateList[0]!=null){
        this.beginDate=this.dateFormat("YYYY-mm-dd",this.searchData.beginEndDateList[0]._d);
        this.endDate=this.dateFormat("YYYY-mm-dd",this.searchData.beginEndDateList[1]._d);
      }else{
         //设定一个默认值
        var currentDate = new Date();
        this.endDate = Helper.dateFormat("YYYY-mm-dd", currentDate);
        //
        var currentCopyDate = new Date();
        currentCopyDate.setMonth(currentCopyDate.getMonth() - 1);
        this.beginDate = Helper.dateFormat("YYYY-mm-dd", currentCopyDate);
        //初始化没有日期限制，则设定默认值
        this.searchData.beginEndDateList = [
          moment(currentCopyDate, "YYYY-MM-DD"),
          moment(currentDate, "YYYY-MM-DD"),
        ];
      }
      //获取驾校支出列表
      ApiConfig.driverSchoolExpenseList({
        pageNum: this.pageObject.pageNum,
        pageSize: this.pageObject.pageSize,
        driverSchoolNo:Helper.get("loginInfo").belongNo,
        //createBy: Helper.get("loginInfo").account,
        beginDate:this.beginDate, //this.searchData.beginEndDateList[0],
        endDate:this.endDate,//this.searchData.beginEndDateList[1],
        // name: this.searchData.name,
        // mobile:this.searchData.mobile,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            for (let i = 0; i < list.length; i++) {
              var tradeTimeText = Helper.FormatToDate(list[i].tradeTime,true);
              list[i].tradeTimeText = tradeTimeText;
            }

            this.expenseList = list;
            //this.pageObject.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    //详情
    moreClick(item){
       this.$router.push({
                path: "/driverschool/couponmanagement",
                query: {
                  rechargeNo: item.rechargeNo
                },
      });
    },
    showmodal(flage, item1) {
      this.$refs["ref_addform"].init(flage, item1); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
      width: 30px;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
     .ant-calendar-picker{
      float: left;
      padding: 0px 5px;
    }
    button {
      float: left;
      width: 60px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
       margin-left: 10px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>