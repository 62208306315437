<template>
  <a-modal
    :title="titleInfo"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <div>
      <a-table
        :columns="columns"
        :data-source="bookTodayList"
        :pagination="false"
        class="table-thead-ma"
        :rowKey="record => record.id"
      >
      <template slot="bookTypeText" slot-scope="text, record">
          <div class="viewTwo" v-if="record.bookType == '1'">
            在线预约
          </div>
          <div class="viewTwo" v-if="record.bookType == '2'">
            扫码快速预约
          </div>
        </template>
      </a-table>
    </div>
    <template slot="footer">
      <div class="footerCon">
        <a-button @click="handleCancel">关闭</a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ApiConfig from "@/api/apiConfig.js";
import config from "@/config";
import Helper from "@/utils/helper";
export default {
  components: {},
  data() {
    return {
      titleInfo: "详情",
      visible: false,
      loading: false,
      bookTodayList: [],
      columns: [
        // {
        //   title: "序号",
        //   dataIndex: "index",
        //   key: "index",
        //   customRender: (text, record, index) => index + 1,
        //   width: 70,
        // },
        {
          title: "设备名称",
          dataIndex: "deviceName",
          key: "deviceName",
          width: 100
        },
        {
          title: "预约类型",
          dataIndex: "bookTypeText",
          key: "bookTypeText",
          scopedSlots: { customRender: "bookTypeText" },
          width: 100
        },
        {
          title: "手机号",
          dataIndex: "bookTraineeMobile",
          key: "bookTraineeMobile",
          width: 100
        },
        {
          title: "用户",
          dataIndex: "bookTraineeName",
          key: "bookTraineeName",
          width: 100
        },
        {
          title: "用户练习时间",
          dataIndex: "trainTimeZone",
          key: "trainTimeZone",
          width: 100
        }
      ]
    };
  },
  filters: {},
  methods: {
    /*初始化函数*/
    init(obj) {
      this.visible = true;
      //获取驾校某个设备的当天的预约信息
      this.getTodayBookList(obj.deviceNo, obj.driverSchoolNo);
    },
    getTodayBookList(deviceNo, driverSchoolNo) {
      ApiConfig.driverSchoolOneDeviceTodayBookList({
        driverSchoolNo: driverSchoolNo,
        deviceNo: deviceNo
      })
        .then(res => {
          if (res.data.code == 200) {
            this.bookTodayList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    handleCancel(e) {
      this.visible = false;
    }
  },
  created() {},
  activated() {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footerCon {
  margin: 0px auto;
  width: 100%;
  text-align: center;
}

.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .fengmian .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>
