<template>
  <div class="coursemanagement paddingCon">
    <div class="course-hea">
      <p>审核时间:</p>
      <a-range-picker 
       :getCalendarContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
      v-model="searchData.beginEndAuditDateList" format="YYYY-MM-DD"/>  
      <p>姓名:</p>
      <a-input placeholder="请输入" v-model="searchData.name" />
      <p>手机号:</p>
      <a-input placeholder="请输入" v-model="searchData.mobile" />
       <p>驾校:</p>
      <a-select 
           :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }"
              :dropdownStyle="{  overflow: 'auto' }"
               v-model="searchData.driverSchoolNo">
          <a-select-option :value="item.driverSchoolNo"
            v-for="item in listDriverSchool"
            :key="item.driverSchoolNo"
          >
            {{ item.driverSchoolName }}
          </a-select-option>
        </a-select>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" :ghost="true" class="hgf" @click="reset">
          重置
        </a-button>
      </a-config-provider>
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="search"> 搜索 </a-button>
      </a-config-provider>
    </div>
     <div class="refund">当前筛选条件下，驾校获得退款{{schoolRefundAmt}}元，代理获的退款{{agentRefundAmt}}元。</div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="refundList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>

      <!-- <div class="paginationBox">
        <a-pagination
          :total="pageObject.total"
          @change="onChangePage"
          show-less-items
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import moment from "moment";
export default {
  name: "agentRefundManagement",//代理退款管理
  data() {
    return {
      schoolRefundAmt:0,
      agentRefundAmt:0,
      companyRefundAmt:0,
      queryObj: {},
      searchData: {
        beginEndAuditDateList: [],
        name: "",
        mobile:""
      },
      listDriverSchool:[],
      refundList: [],
      auditBeginTime:"",
      auditEndTime:"",
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        {
          title: "券码",
          dataIndex: "couponNo",
          key: "couponNo",
          width: 100,
        },
        { title: "学员姓名", dataIndex: "traineeName", key: "traineeName", width: 100 },
        {
          title: "学员手机号",
          dataIndex: "traineeMobile",
          key: "traineeMobile",
          width: 110,
        },
        {
          title: "购买时间",
          dataIndex: "couponTimeText",
          key: "couponTimeText",
          width: 100,
        },
         {
          title: "购买渠道",
          dataIndex: "buyChannel",
          key: "buyChannel",
          width: 100,
        },
         {
          title: "练车驾校",
          dataIndex: "driverSchoolName",
          key: "driverSchoolName",
          width: 100,
        },
         
         {
          title: "申请时间",
          dataIndex: "applyTime",
          key: "applyTime",
          width: 100,
        },
         {
          title: "审核时间",
          dataIndex: "auditTime",
          key: "auditTime",
          width: 100,
        },
         {
          title: "充值金额（元）",
          dataIndex: "couponAmt",
          key: "couponAmt",
          width: 120,
        },
        {
          title: "是否已分成",
          dataIndex: "profitStatus",
          key: "profitStatus",
          width: 100,
        },
        
         {
          title: "申请理由",
          dataIndex: "applyReason",
          key: "applyReason",
          width: 100,
        },
         
         {
          title: "审核结果",
          dataIndex: "auditResult",
          key: "auditResult",
          width: 100,
        },
        {
          title: "驾校获得退款",
          dataIndex: "schoolProfitRefundAmt",
          key: "schoolProfitRefundAmt",
          width: 110,
        },
        {
          title: "代理获得退款",
          dataIndex: "agentProfitRefundAmt",
          key: "agentProfitRefundAmt",
          width: 110,
        },
      ],
    };
  },
  components: {
  },
  mounted() {
    this.getList();
    this.getSchoolList();
  },
  created() {
   
  },
  methods: {
    reset() {
      this.searchData.name = "";
      this.searchData.mobile="";
      this.searchData.driverSchoolNo="";
      this.searchData.beginEndAuditDateList = [];
      this.auditBeginTime="";
      this.auditEndTime="";
      this.getList();
    },
    search() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.pageObject.pageNum = 1;
      this.getList();
    },
     //获取该代理下的驾校
    getSchoolList(){
         ApiConfig.getSchoolListByAgent({
            agentNo:Helper.get("loginInfo").belongNo,
         })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            this.listDriverSchool = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    getList() {
       
      if(this.searchData.beginEndAuditDateList!=null
         &&this.searchData.beginEndAuditDateList[0]!=null){
        this.auditBeginTime=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndAuditDateList[0]._d);
        this.auditEndTime=Helper.dateFormat("YYYY-mm-dd",this.searchData.beginEndAuditDateList[1]._d);
      }else{
           //设定一个默认值
        var currentDate = new Date();
        this.auditEndTime = Helper.dateFormat("YYYY-mm-dd", currentDate);
        //
        var currentCopyDate = new Date();
        currentCopyDate.setMonth(currentCopyDate.getMonth() - 1);
        this.auditBeginTime = Helper.dateFormat(
          "YYYY-mm-dd",
          currentCopyDate
        );
        //初始化没有日期限制，则设定默认值
        this.searchData.beginEndAuditDateList = [
          moment(currentCopyDate, "YYYY-MM-DD"),
          moment(currentDate, "YYYY-MM-DD"),
        ];
      }
      
      //获得退款
      ApiConfig.driverSchoolRefundList({
        auditBeginTime:this.auditBeginTime,
        auditEndTime:this.auditEndTime, 
        traineeName: this.searchData.name,
        traineeMobile:this.searchData.mobile,
        driverSchoolNo:this.searchData.driverSchoolNo,
        agentNo:Helper.get("loginInfo").belongNo
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data.list;
            for (let i = 0; i < list.length; i++) {
              var couponTimeText = Helper.FormatToDate(list[i].couponTime,true);
              list[i].couponTimeText = couponTimeText;
            }
            this.refundList = list;
             //显示相关汇总数据
            var totalInfo=res.data.data.sumary;
            if(totalInfo!=null){

              this.schoolRefundAmt=totalInfo.school;
              this.companyRefundAmt=totalInfo.company;
              this.agentRefundAmt=totalInfo.agent;
              
            }

          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    //点击分页操作
    onChangePage(page, pageSize) {
      this.pageObject.pageNum = page;
      this.getList();
    },
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.refund{
  margin-top: 20px;
  font-size: 16px;
  margin-left: 10px;
}
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    position: relative;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-calendar-picker{
        float: left;
        width: 220px;
        margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      margin-left: 10px;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>