<template>
  <div class="coursemanagement paddingCon">
      <div class="course-hea">
      <a-config-provider :auto-insert-space-in-button="false">
        <a-button type="primary" class="fgh" @click="showmodal()"> 新增分成 </a-button>
      </a-config-provider>
    </div>
    <div class="course-content">
      <a-table
        :columns="columns"
        :data-source="profitList"
        :pagination="false"
        :rowKey="(record) => record.id"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
    </div>
     <ProfitAdd
      ref="ref_addform"
      @backInit="backInit"
    >
    </ProfitAdd>
  </div>
</template>

<script>
import bus from "@/api/bus.js";
import ApiConfig from "@/api/apiConfig.js";
import Helper from "@/utils/helper";
import ProfitAdd from "./ProfitAdd";

export default {
  name: "profitmanagement",
  data() {
    return {
      queryObj:{},
      profitList: [],
      options: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (text, record, index) => index + 1,
          width: 70,
        },
        { title: "驾校编号", dataIndex: "driverSchoolNo", key: "driverSchoolNo", width: 200 },
        {
          title: "驾校充值分成金额",
          dataIndex: "profitAmt",
          key: "profitAmt",
          width: 200,
        },
        {
          title: "学员充值分成金额",
          dataIndex: "traineeRecProfitAmt",
          key: "traineeRecProfitAmt",
          width: 200,
        },
        {
          title: "驾校分成比例",
          dataIndex: "schoolProfitRatio",
          key: "schoolProfitRatio",
          width: 200,
        },
        {
          title: "代理分成比例",
          dataIndex: "agentProfitRatio",
          key: "agentProfitRatio",
          width: 200,
        },
        {
          title: "公司分成比例",
          dataIndex: "companyProfitRatio",
          key: "companyProfitRatio",
          width: 200,
        },
         {
          title: "生效起始日期",
          dataIndex: "beginTime",
          key: "beginTime",
          width: 200,
        },
        {
          title: "生效结束日期",
          dataIndex: "endTime",
          key: "endTime",
          width: 200,
        }
      ],
    };
  },
  components: {
    ProfitAdd,
  },
  mounted() {
    this.getList();
  },
  created() {
      this.queryObj=this.$route.query;
  },
  methods: {
    reset() {
      this.getList();
    },
    handleSelectChange(value) {
      console.log(value);
    },
    backInit() {
      this.getList();
    },
    getList() {
      //获取分成列表数据
      ApiConfig.getProfitList({
        driverSchoolNo: this.queryObj.driverSchoolNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            var list = res.data.data;
            console.log(list);
            this.profitList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(this.CommonConst.errorMsg);
        });
    },
    
    showmodal(flag, item1) {
      this.$refs["ref_addform"].init(flag, item1); // this.$refs触发一个组件里的方法
    },
    updateList() {},
  },
};
</script>
<style lang='scss' scoped>
.coursemanagement {
  .applyforclass-popup {
    width: 488px;
    height: 518px;
    background-color: red;
  }
  .course-content {
    margin-top: 18px;
    .view {
      color: #0e9a68;
      cursor: pointer;
    }
  }
  .paginationBox {
    margin-top: 80px;
  }
  .course-hea {
    width: 100%;
    height: 32px;
    overflow: hidden;
    p {
      float: left;
      line-height: 32px;
      padding-left: 10px;
      font-family: Source Han Sans CN;
      font-weight: regular;
    }
    // 输入框
    .ant-input {
      width: 198px;
      height: 32px;
      float: left;
      padding-left: 7px;
      margin-left: 5px;
    }
    .ant-select {
      float: left;
      padding: 0px 5px;
      width: 198px;
    }
    .ant-select-selection--single {
      width: 198px;
      height: 32px;
    }
    button {
      float: left;
      width: 90px;
      height: 31px;
    }
    // 查询重置
    .fgh {
      margin-left: 16px;
      margin-right: 8px;
    }
    .hgf {
      border: 1px solid #ccc;
      color: #000000;
      font-family: Source Han Sans CN;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
    }

    // button:focus{
    //   border-color: #0E9A68;
    //   outline:none;
    // }
    // .qur-1{
    //   color: #FFFFFF;
    //   font-family: Source Han Sans CN;
    //   font-weight: regular;
    //   font-size: 14px;
    //   line-height: normal;
    //   letter-spacing: 0px;
    //   text-align: center;
    //   border-radius: 2px;
    //   background: #0E9A68;
    //   border:none;
    //   margin-left:20px;
    // }

    // .qur-2{
    //   border-radius: 2px;
    //   background: #FFFFFF;
    //   border: 1px solid #D9D9D9;
    // }
    .apply {
      width: 112px;
      height: 32px;
      float: right;
      border-radius: 2px;
      background: #0e9a68;
      color: #ffffff;
      font-family: Source Han Sans CN;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      border: none;
      cursor: pointer;
    }
  }
}
</style>